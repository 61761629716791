import {
  Box,
  Portal,
  Input,
  HStack,
  Avatar,
  Text,
  AvatarProps,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { FaFlag } from "react-icons/fa";

interface Props {
  onSelect?: (status: any) => void;
  trigger: React.ReactNode;
}

export const TaskPriorityPopover = observer(({ onSelect, trigger }: Props) => {
  const initRef = useRef<any>();
  const { configStore } = useStore();

  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>{trigger}</PopoverTrigger>
          <Portal appendToParentPortal={true}>
            <PopoverContent width="200px">
              <PopoverArrow />
              <PopoverBody>
                {configStore.priorities.map((status) => (
                  <HStack
                    cursor="pointer"
                    key={status.key}
                    onClick={() => {
                      onClose();
                      onSelect && onSelect(status);
                    }}
                    my="5px"
                  >
                    <FaFlag fontSize="16px" color={status.color} />
                    <Text>{status.label}</Text>
                  </HStack>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
});
