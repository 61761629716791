import React from "react";
import { BsKanban } from "react-icons/bs";
import { BiGroup } from "react-icons/bi";
import { ImFileEmpty } from "react-icons/im";
export const TEMPLATES = [
  {
    label: "Empty",
    key: "empty",
    icon: ImFileEmpty,
  },
  {
    label: "Kanban",
    key: "kanban",
    icon: BsKanban,
  },
  {
    label: "HR",
    key: "hr",
    icon: BiGroup,
  },
];
