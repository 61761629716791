import React from "react";
import { Input } from "@chakra-ui/input";
import { ICustomField } from "module/sunday/space";
import { useFormContext } from "react-hook-form";

interface Props {
  field: ICustomField;
}

export const TextCreate = ({ field }: Props) => {
  const { register } = useFormContext();
  return (
    <Input fontSize="18px" focusBorderColor="none" {...register(field.key)} />
  );
};
