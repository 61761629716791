import { IUser } from "const/type";
import { makeAutoObservable, reaction } from "mobx";
import { authStore } from "module/auth";
import { history } from "util/history";
import { ITeam } from "./const";
import { TeamService } from "./TeamService";

import { matchPath } from "react-router";

export class TeamStore {
  teams: ITeam[] = [];
  teamsById: {
    [key: string]: ITeam;
  } = {};
  users: IUser[] = [];
  usersById: {
    [key: string]: IUser;
  } = {};
  selectedTeam: ITeam | null = null;

  constructor() {
    makeAutoObservable(this);
    this.init();

    reaction(
      () => authStore.isLoggedIn,
      () => {
        if (authStore.isLoggedIn) {
          this.getTeams();
        }
      }
    );

    reaction(
      () => this.selectedTeam,
      () => {
        if (this.selectedTeam) {
          this.getUsers();
        }
      }
    );
  }
  init = () => {};

  getUsers = async () => {
    const data = await TeamService.getOne(this.selectedTeam?.id);
    this.users = (data?.users || []) as any[];
    this.usersById = (data?.users || []).reduce((acc: any, item: any) => {
      acc[item.id] = item;
      return acc;
    }, {});
  };

  getTeams = async () => {
    this.teams = await TeamService.get({
      select: {
        id: true,
        name: true,
      },
    });
    if (!this.teams[0]) {
      history.push("/dashboard/new-team");
    }
    let teamToSelect = this.teams[0];
    const pathMatch = matchPath(history.location.pathname, {
      path: "/dashboard/team/:teamId",
    });
    if (pathMatch) {
      const teamMatch = this.teams.find(
        (team) => team.id === (pathMatch.params as any).teamId
      );
      if (teamMatch) teamToSelect = teamMatch;
    }
    this.setSelectedTeam(teamToSelect);
  };

  create = async (team: Partial<ITeam>) => {
    await TeamService.create(team);
    this.getTeams();
  };

  setSelectedTeam = async (team: ITeam) => {
    this.selectedTeam = team;
  };
}

export const teamStore = new TeamStore();
