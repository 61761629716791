import { API } from "api";

export class SundayService {
  base: string;

  constructor(url: string) {
    this.base = url;
  }

  get = async (query: any) => {
    const response = await API.get(this.base, {
      params: {
        query,
      },
    });
    return response.data;
  };

  getOne = async (id: any) => {
    const response = await API.get(`${this.base}/${id}`);
    return response.data;
  };

  update = async (id: string, data: any) => {
    const response = await API.patch(`${this.base}/${id}`, data);
    return response.data;
  };

  create = async (data: any) => {
    const response = await API.post(`${this.base}`, data);
    return response.data;
  };

  delete = async (id: string) => {
    const response = await API.delete(`${this.base}/${id}`);
    return response.data;
  };
}
