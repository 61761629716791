import {
  Center,
  Editable,
  EditableInput,
  EditablePreview,
  Spinner,
} from "@chakra-ui/react";
import { useFlowFromId, useFlowsResource } from "graphql/useTeamFlows";
import { toJS } from "mobx";
import { FlowForm } from "module/flow/component/FlowForm";
import React, { useCallback } from "react";
import { useParams } from "react-router";
import { toast } from "util/toast";

type Props = {
  flow: any;
};

const FlowDetailWithData: React.FC<Props> = ({ flow }) => {
  const { update } = useFlowsResource();

  const onChangeName = useCallback(
    async (name) => {
      try {
        await update(flow.id, {
          name,
        });
      } catch (err) {
        console.log(err);
      }
    },
    [update, flow]
  );

  const onChange = useCallback(
    async (values) => {
      try {
        await update(flow.id, {
          triggerParams: values.triggerParams,
          triggerType: values.triggerType,
          steps: [
            {
              actions: values.actions,
              name: "actions",
            },
          ],
        });
      } catch (err) {
        console.log(err);
      }
    },
    [update, flow]
  );

  return (
    <>
      <Editable
        zIndex="10"
        onSubmit={async (name: string) => {
          await onChangeName(name);
          toast({
            title: "标题更新",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }}
        fontSize="20px"
        defaultValue={flow.name}
      >
        <EditablePreview
          borderWidth="1px"
          borderColor="transparent"
          p="2"
          _hover={{
            borderColor: "white",
          }}
        />
        <EditableInput />
      </Editable>
      <FlowForm
        onSubmit={(values) => {
          console.log(values);
          onChange(values);
        }}
        flow={toJS(flow)}
      />
    </>
  );
};

export const FlowDetail = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { data = {}, loading } = useFlowFromId(flowId);
  const { zhuxinFlow = {} } = data;

  if (loading) {
    return (
      <Center>
        <Spinner></Spinner>
      </Center>
    );
  }

  return <FlowDetailWithData key={flowId} flow={zhuxinFlow} />;
};
