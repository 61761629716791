import {
  Box,
  Portal,
  Input,
  HStack,
  Avatar,
  Text,
  AvatarProps,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { FieldModeEnum, ITask, Item, IUser } from "const/type";
import { ICustomField } from "module/sunday/space";
import { FaCalendar } from "react-icons/fa";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";
import { DateField } from "./DateField";

interface Props {
  field: ICustomField;
  item: Item;
  mode?: FieldModeEnum;
}

export const Date = observer(({ field, item }: Props) => {
  const { itemStore } = useStore();
  const dateValue = item.customData?.[field.key];
  return (
    <DateField
      value={dateValue}
      onChange={(date: any) => {
        if (!dateValue || !moment(date).isSame(dateValue, "d")) {
          itemStore.saveItem({
            id: item.id,
            customData: {
              [field.key]: date?.toDate(),
            },
          });
        }
      }}
    />
  );
});
