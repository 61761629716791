import React, { useRef } from "react";
import { Button } from "@chakra-ui/button";
import { Box, HStack, Text } from "@chakra-ui/layout";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { Portal } from "@chakra-ui/portal";
import { Input, Textarea, useDisclosure } from "@chakra-ui/react";
import { AssignToButton } from "component/AssignToButton";
import { FormProvider, useForm } from "react-hook-form";
import { useStore } from "store/RootStore";
import { TaskStatusPopover } from "component/TaskStatusPopover/TaskStatusPopover";
import { FormattedMessage, useIntl } from "react-intl";
import { CreateForm } from "./CreateForm";
import { useIsMobile } from "hooks/useDevice";

interface Props {}

export const CreateItem = (props: Props) => {
  // const initRef = useRef<any>();
  const isMobile = useIsMobile();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { itemStore, configStore, spaceStore } = useStore();
  const { formatMessage } = useIntl();
  const form = useForm({
    defaultValues: {
      status: "todo",
      subject: "",
      description: "",
      assignedTo: "",
    },
  });
  const { handleSubmit, setValue, watch, register, formState } = form;
  const onSubmit = async (data: any) => {
    await itemStore.createItem({
      customData: data,
      spaceId: spaceStore.viewingSpace!.id,
    });
    onClose();
  };

  const boxValues = isMobile ? { top: "10px" } : { bottom: "10px" };

  return (
    <Box position="fixed" right="10px" {...boxValues}>
      <Popover isLazy isOpen={isOpen} onClose={onClose} closeOnBlur={false}>
        <PopoverTrigger>
          <Button onClick={onOpen}>
            <FormattedMessage
              id="task.create.task"
              defaultMessage="Create Task"
            />
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width="500px">
            <PopoverArrow />
            {/* <PopoverHeader>Header</PopoverHeader> */}
            <PopoverCloseButton cursor="pointer" top="10px" zIndex="10" />
            <PopoverBody>
              <Box paddingTop="40px">
                <FormProvider {...form}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <CreateForm space={spaceStore.viewingSpace!} />

                    <Button
                      disabled={formState.isSubmitting}
                      type="submit"
                      mt="10px"
                      colorScheme="blue"
                    >
                      <FormattedMessage
                        id="task.create.task"
                        defaultMessage="Create Task"
                      />
                    </Button>
                  </form>
                </FormProvider>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};
