import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react'
import SimpleBar from 'simplebar-react';


export const ScrollBox: React.FC<BoxProps> = ({children, ...props}) => {
  return (
    <Box {...props}>
        <SimpleBar style={{height: '100%', width: '100%'}}>
            {children}
        </SimpleBar>
    </Box>
  )
}