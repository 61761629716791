import {
  Box,
  Flex,
  FlexProps,
  HStack,
  useMenuButton,
  Avatar,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { HiSelector } from "react-icons/hi";
import { useStore } from "store/RootStore";

export const AccountSwitcherButton = observer((props: FlexProps) => {
  const buttonProps = useMenuButton(props);
  const { authStore } = useStore();
  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg="gray.700"
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: "gray.600" }}
      _focus={{ shadow: "outline" }}
    >
      <HStack flex="1" spacing="3">
        <Avatar w={8} h={8} name={authStore.user?.username} />
        <Box textAlign="start">
          <Box isTruncated fontWeight="semibold">
            {authStore.user?.username}
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  );
});
