import React, { useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Center,
  Avatar,
  Text,
  Flex,
  Input,
} from "@chakra-ui/react";
import { BLOCKS } from "../const";

type Props = {
  onClose: any;
  handleSelectBlock: any;
};

export const BlocksModal = ({ onClose, handleSelectBlock }: Props) => {
  const [searchInput, setSearchInput] = useState("");

  const toolbarItems = useMemo(() => {
    return BLOCKS.filter((item) =>
      item.label?.toLowerCase()?.includes(searchInput.toLowerCase())
    );
  }, [searchInput]);

  return (
    <Modal isOpen onClose={onClose}>
      <ModalContent>
        <ModalBody>
          <Input
            placeholder="搜索"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />

          <Flex
            paddingBottom={"20px"}
            overflowY={"auto"}
            flex={1}
            minH="0px"
            width={"100%"}
            flexDir={"row"}
            flexWrap="wrap"
          >
            {toolbarItems.map((item) => (
              <Center
                onClick={() => {
                  handleSelectBlock && handleSelectBlock(item);
                }}
                cursor={"pointer"}
                marginY={"20px"}
                flexDirection={"column"}
                key={item.type}
                width={"50%"}
                h={50}
                draggable={true}
                unselectable="on"
              >
                {item.icon || <Avatar name={item.label} size={"xs"} />}
                <Text marginTop={"5px"} fontSize={12}>
                  {item.label}
                </Text>
              </Center>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
