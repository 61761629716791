import { IGridItem } from "../components/GridView";
import { TOOLBAR, TOOLBAR_MAP } from "../components/GridView/const";
import { BLOCKS, BLOCKS_MAP } from "../components/ListView/const";

interface ISundayConfig extends Omit<IGridItem, "component"> {}

export const registerComponent = (components: any[]) => {
  components.forEach((component) => {
    const config = component.config;
    const item = {
      component,
      type: component.type,
      label: component.name,
      ...config,
      schema:
        config?.schema?.type === "object"
          ? config.schema
          : {
              type: "object",
              properties: config?.schema || {},
            },
    };
    if (!TOOLBAR_MAP[item.type]) {
      TOOLBAR.push(item);
      TOOLBAR_MAP[item.type] = item;
    }

    if (!BLOCKS_MAP[item.type]) {
      BLOCKS.push(item);
      BLOCKS_MAP[item.type] = item;
    }
  });
};

export const getPromptBlocks = () => {
  const blocks = BLOCKS.map((item) => ({
    label: item.label,
    type: item.type,
    fields: Object.keys(item.schema.properties)
      .map((fieldKey) => ({
        key: fieldKey,
        type: item.schema.properties[fieldKey].type,
      }))
      .filter((item) => item.type === 'string'),
  }));
  const BASE_PROMPT = `You are a website generator bot for a "IT Consulting" industry company named "Buildmind".  I have following blocks in csv format. ${JSON.stringify(blocks)}
  Please generate a json with the blocks and generate text in the fields with type of strings. A sample block is { key }`
  return BASE_PROMPT;
};

(window as any).getPromptBlocks = getPromptBlocks;