import React from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

export const SortableItem = SortableElement(({ children }: any) => (
  <div>{children}</div>
));

export const SortableList = SortableContainer(({ children }: any) => {
  return <div>{children}</div>;
});

export const SortableDrag = SortableHandle(({ children }: any) => {
    return <div>{children}</div>;
  });
