import { Box } from "@chakra-ui/layout";
import { ISpaceView } from "module/sunday/space";
import React from "react";
import { AutoSizer } from "react-virtualized";

interface Props {
  view: ISpaceView;
}

export const EmbedView = ({ view }: Props) => {
  return (
    <Box w="full" h="full" flex={1}>
      <AutoSizer>
        {({ width, height }) => (
          <iframe
            width={width}
            height={height}
            title="embed"
            frameBorder="0"
            src={view.data?.url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </AutoSizer>
    </Box>
  );
};
