import { Center } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BsTable } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";

interface Props {}

export const Board = (props: Props) => {
  const { control } = useFormContext();

  return (
    <Center py="30px" flexDir="column" w="full" h="full">
      <MdDashboard fontSize="40px" />
      <Text mt="20px">看板</Text>
      <Text color="gray.200" fontSize="12px">
        看板是最流行的任务浏览方式之一，任意拖动你的任务
      </Text>
    </Center>
  );
};
