import { API } from "api";
import cuid from "cuid";
import localforage from "localforage";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { itemStore } from "module/sunday/item";
import { teamStore } from "module/sunday/team";
import { queryClient } from "util/query";
import { FileService } from "../service/FileService";

export interface IFile {
  id: string;
  size: number;
  name: string;
  url?: string;
  contentType: string;
  provider?: string;

  uploading?: boolean;
  progress?: number;
  failed?: boolean;
  rawFile?: File;
  extraParams?: any;

  item?: {
    id: string;
  };
}

export class FileStore {
  filesById: { [key: string]: IFile } = {};
  filesByIdLoading: { [key: string]: boolean } = {};

  filesToLoad: string[] = [];
  filesToLoadDebounceTimeout: any;

  confirmChooseFileResolve: any = undefined;

  constructor() {
    makeAutoObservable(this);

    makePersistable(
      this,
      {
        name: "FileStore",
        properties: ["filesById"],
        storage: localforage, // localForage, window.localStorage, AsyncStorage all have the same interface
        expireIn: 86400000, // One day in millsesconds
        removeOnExpiration: true,
        stringify: false,
      },
      { delay: 200, fireImmediately: false }
    );
  }

  addFileToLoader = async (id: string) => {
    if (this.filesToLoad.includes(id) || !!this.filesByIdLoading[id]) {
      return;
    }
    this.filesToLoad.push(id);
    if (this.filesToLoadDebounceTimeout) {
      clearTimeout(this.filesToLoadDebounceTimeout);
    }
    this.filesToLoadDebounceTimeout = setTimeout(() => {
      this.loadFiles(this.filesToLoad);
      this.filesToLoad = [];
    }, 100);
  };

  loadFiles = async (ids: string[]) => {
    const loadingIds = ids.filter((id) => !this.filesByIdLoading[id]);

    loadingIds.forEach((id) => {
      this.filesByIdLoading = { ...this.filesByIdLoading, [id]: true };
    });

    const files: IFile[] = await FileService.get({
      where: {
        id: {
          in: loadingIds,
        },
      },
      include: {
        item: {
          select: {
            id: true,
          },
        },
      },
    });

    loadingIds.forEach((id) => {
      this.filesByIdLoading = { ...this.filesByIdLoading, [id]: false };
    });
    const newHash: any = {};
    files.forEach((file: IFile) => {
      newHash[file.id] = file;
    });
    this.filesById = {
      ...this.filesById,
      ...newHash,
    };
  };

  reUpload = async (id: string) => {
    if (!this.filesById[id]) {
      return;
    }
    const rawFile = this.filesById[id].rawFile!;
    this.filesById[id].uploading = true;
    this.filesById[id].progress = 0;
    const extraParams = this.filesById[id].extraParams;
    FileService.upload(
      teamStore.selectedTeam!.id,
      rawFile,
      this.handleUploadProgress(id),
      extraParams
    )
      .then((url: string) => {
        this.filesById[id].url = url;
        this.filesById[id].uploading = false;
        this.filesById[id].failed = false;
        queryClient.setQueryData("teamFileList", (originalValue: any = []) => {
          return [...originalValue, this.filesById[id]];
        });
      })
      .catch(() => {
        this.filesById[id].uploading = false;
        this.filesById[id].failed = true;
      });
  };

  upload = async (rawFile: File, extraParams: any = {}, onFileUrl?: any) => {
    const id = cuid();
    this.filesById[id] = {
      id,
      name: rawFile.name,
      size: rawFile.size,
      contentType: rawFile.type,
      progress: 0,
      uploading: true,
      rawFile,
      extraParams,
    };
    FileService.upload(
      teamStore.selectedTeam!.id,
      rawFile,
      this.handleUploadProgress(id),
      extraParams
    )
      .then((url: string) => {
        this.filesById[id].url = url;
        this.filesById[id].uploading = false;
        this.filesById[id].failed = false;
        onFileUrl && onFileUrl(url);
      })
      .catch((err) => {
        console.log(err);
        this.filesById[id].uploading = false;
        this.filesById[id].failed = true;
      });
    return id;
  };

  remove = async (file: IFile) => {
    await FileService.delete(file.id);
    delete this.filesById[file.id];
    if (file.item?.id) {
      itemStore.itemsHash[file.item?.id].attachments = itemStore.itemsHash[
        file.item?.id
      ].attachments?.filter((attachment) => attachment.id !== file.id);
    }
  };

  handleUploadProgress = (id: string) => (progressEvent: any) => {
    this.filesById[id].progress = progressEvent.loaded;
  };

  chooseFile = async (): Promise<IFile> => {
    return new Promise((resolve, reject) => {
      this.confirmChooseFileResolve = resolve;
    });
  };

  confirmChooseFile = (file: IFile | null) => {
    this.confirmChooseFileResolve(file);
    this.confirmChooseFileResolve = null;
  };

  get isChoosingFile() {
    return !!this.confirmChooseFileResolve;
  }
}

export const fileStore = new FileStore();
