export const WebhookAction = {
  key: "webhook",
  label: "Webhook Send",
  fields: [
    {
      key: "method",
      label: "Method",
      type: "selector",
      options: [
        {
          label: "GET",
          value: "GET",
        },
        {
          label: "PUT",
          value: "PUT",
        },
        {
          label: "POST",
          value: "POST",
        },
        {
          label: "DELETE",
          value: "DELETE",
        },
        {
          label: "PATCH",
          value: "PATCH",
        },
      ],
    },
    {
      key: "url",
      label: "Request URL",
      type: "input",
    },
    {
      key: "headers",
      label: "Headers",
      type: "json",
      // type: "input",
    },
    {
      key: "body",
      label: "Body",
      type: "json",
      // type: "input",
    },
  ],
};
