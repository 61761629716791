import { Box, HStack } from "@chakra-ui/react";
import * as React from "react";
import { BsCaretRightFill } from "react-icons/bs";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

interface NavItemProps {
  href?: string;
  label: string;
  subtle?: boolean;
  icon: React.ReactElement;
  endElement?: React.ReactElement;
  children?: React.ReactNode;
}

export const NavItem = (props: NavItemProps) => {
  const { subtle, icon, children, label, endElement, href = "" } = props;
  const match = useRouteMatch(href);
  const history = useHistory();
  const active = !!href && match;
  const handleClick = React.useCallback(() => {
    history.push(href);
  }, [history, href]);
  return (
    <Box onClick={handleClick} width="full">
      <HStack
        w="full"
        px="3"
        py="2"
        cursor="pointer"
        userSelect="none"
        rounded="md"
        transition="all 0.2s"
        bg={active ? "gray.700" : undefined}
        _hover={{ bg: "gray.700" }}
        _active={{ bg: "gray.600" }}
      >
        <Box fontSize="lg" color={active ? "currentcolor" : "gray.400"}>
          {icon}
        </Box>
        <Box
          flex="1"
          fontWeight="inherit"
          color={subtle ? "gray.400" : undefined}
        >
          {label}
        </Box>
        {endElement && !children && <Box>{endElement}</Box>}
        {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
      </HStack>
    </Box>
  );
};
