import { API } from "api";
import { SundayService } from "../service";

class ItemServiceClass extends SundayService {
  query = async (query: any) => {
    const response = await API.get(`${this.base}/query`, {
      params: {
        query,
      },
    });
    return response.data;
  };

  comment = async (id: string, action: any) => {
    const response = await API.post(`${this.base}/${id}/action`, action);
    return response.data;
  };

  updatePosition = async (newPosition: {
    prevItemId?: string;
    nextItemId?: string;
    itemId: string;
    field: string;
  }) => {
    const response = await API.put(`${this.base}/positions`, newPosition);
    return response.data;
  };
}

export const ItemService = new ItemServiceClass("sunday-items");

class ItemActionServiceClass extends SundayService {}

export const ItemActionService = new ItemActionServiceClass(
  "sunday-item-actions"
);
