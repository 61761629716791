import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useFlows, useFlowsResource } from "graphql/useTeamFlows";
import { IFlow } from "module/flow/store";
import React from "react";
import { BiNetworkChart, BiPlus } from "react-icons/bi";
import { FaClock } from "react-icons/fa";
import { GrAction } from "react-icons/gr";
import { IoLogoJavascript } from "react-icons/io5";
import { MdMail } from "react-icons/md";
import { useIntl } from "react-intl";
import { useHistory, useRouteMatch } from "react-router";

interface Props {}

const ACTION_ICON_MAP: any = {
  smtp: <MdMail />,
  javascript: <IoLogoJavascript />,
};

export const FlowList = ({}: Props) => {
  const match = useRouteMatch();
  const intl = useIntl();
  const history = useHistory();
  const { data = {}, loading: isLoading } = useFlows();
  const { zhuxinFlows: flows = [] } = data;
  const { create } = useFlowsResource();

  return (
    <Box w="200px" h="100%">
      <Button
        width="100%"
        fontSize={"12px"}
        my={"10px"}
        onClick={() => {
          create({
            name: "新的工作流",
            steps: [{
              actions: [],
              name: "action"
            }],
            triggerType: "sunday.space.item.create",
            triggerParams: {}
          });
        }}
      >
        <BiPlus />
        添加
      </Button>

      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isLoading && flows.length === 0 && (
        <Center w="full" h="full" flexDir={"column"}>
          <Text>你还没有任何工作流</Text>
          {/* <Button onClick={onClickCreate}>Create A Flow</Button> */}
        </Center>
      )}
      {!isLoading && (
        <Box>
          {/* <Flex w="100%" justifyContent={"flex-end"}>
            <Button onClick={onClickCreate}>Create</Button>
          </Flex> */}

          {flows.map((flow: IFlow) => (
            <Box
              key={flow.id}
              onClick={() => {
                // onSelectFlow && onSelectFlow(flow);
                history.push(`${match?.url}/${flow.id}`);
              }}
              cursor={"pointer"}
              w="100%"
              p="10px"
              my="10px"
              bg="gray.800"
            >
              <Text>{flow.name}</Text>
              <Flex gap="10px" flexDir={"row"} alignItems={"center"}>
                <Box as={"b"}>
                  {flow.triggerType === "cron" ? (
                    <FaClock />
                  ) : (
                    <BiNetworkChart />
                  )}
                </Box>
                <span>{" - "}</span>
                {flow.steps.map((step, stepIndex: number) =>
                  step.actions.map((action: any, index: number) => (
                    <React.Fragment key={`${stepIndex}-${index}`}>
                      {ACTION_ICON_MAP[action.type] || (
                        <BiNetworkChart color="white" />
                      )}
                      {index < step.actions.length - 1 && <span>{" - "}</span>}
                    </React.Fragment>
                  ))
                )}
              </Flex>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
