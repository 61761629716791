import { IconButton } from "@chakra-ui/button";
import { Editable, EditableInput, EditablePreview } from "@chakra-ui/editable";
import { AssignToButton } from "component/AssignToButton";
import { ITask, Item, FieldModeEnum } from "const/type";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "store/RootStore";
import { FaEdit, FaFlag } from "react-icons/fa";
import { TaskStatusPopover } from "component/TaskStatusPopover";
import { Box, Center, HStack } from "@chakra-ui/layout";
import { Text, Tooltip } from "@chakra-ui/react";
import { TaskPriorityPopover } from "component/TaskPriorityPopover";
import { FormattedMessage, useIntl } from "react-intl";
import { TextArea } from "field/TextArea";
import { Text as TextCell } from "field/Text";
import { ICustomField } from "module/sunday/space";
import { SundayItemCustomCell } from "./SundayItemCustomCell";
interface Props {
  item: Item;
  field: ICustomField;
  mode?: FieldModeEnum;
}

export const SundayItemCell = observer(
  ({ field, item, mode = FieldModeEnum.TABLE }: Props) => {
    const { itemStore, configStore } = useStore();
    const { formatMessage } = useIntl();

    if (!field) {
      return null;
    }

    const columnKey = field.key;

    if (columnKey === "assignedTo") {
      return (
        <AssignToButton
          avatarProps={{
            size: "sm",
          }}
          assignedTo={item.customData?.assignedTo}
          onSelect={(user) =>
            itemStore.saveItem({
              id: item.id,
              customData: {
                assignedTo: user.id,
              },
            })
          }
        />
      );
    }

    if (columnKey === "description") {
      return (
        <TextArea
          field={
            {
              key: "description",
            } as any
          }
          item={item}
        />
      );
    }

    if (columnKey === "subject") {
      if (mode === FieldModeEnum.TABLE) {
        return (
          <Text
            cursor="pointer"
            onClick={() => itemStore.setViewingItemId(item.id)}
          >
            {item.customData.subject}
          </Text>
        );
      } else if (mode === FieldModeEnum.MODAL) {
        return (
          <TextCell
            field={
              {
                key: "subject",
              } as any
            }
            item={item}
            mode={mode}
          />
        );
      }
    }

    if (columnKey === "priority") {
      return (
        <TaskPriorityPopover
          trigger={
            <Center>
              <Tooltip
                label={
                  null
                  // item.customData.priority
                  //   ? formatMessage({
                  //       id: `item.priorities.${
                  //         configStore.prioritiesByKey[item.customData.priority!]
                  //           .key
                  //       }`,
                  //     })
                  //   : formatMessage({ id: "item.priorities.set" })
                }
              >
                <Box cursor="pointer">
                  <FaFlag
                    fontSize="16px"
                    color={
                      item.customData?.priority
                        ? configStore.prioritiesByKey[item.customData.priority!]
                            .color
                        : "white"
                    }
                  />
                </Box>
              </Tooltip>
            </Center>
          }
          onSelect={(priority) => {
            itemStore.saveItem({
              id: item.id,
              customData: {
                priority: priority.key,
              },
            });
          }}
        />
      );
    }

    if (columnKey === "status" && field.type === "status") {
      return (
        <TaskStatusPopover
          trigger={
            <Box
              cursor="pointer"
              display="flex"
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              fontSize="16px"
              whiteSpace="nowrap"
              px={mode === FieldModeEnum.MODAL ? "10px" : 0}
              background={
                configStore.statusesByKey[item.customData?.status!]?.color
              }
            >
              <FormattedMessage
                id={`item.status.${
                  configStore.statusesByKey[item.customData?.status!]?.key
                }`}
                defaultMessage={
                  configStore.statusesByKey[item.customData?.status!]?.label
                }
              />
            </Box>
          }
          onSelect={(status) => {
            itemStore.saveItem({
              id: item.id,
              customData: {
                status: status.key,
              },
            });
          }}
        />
      );
    }

    return (
      <SundayItemCustomCell
        key={`${item.id}-${field.key}`}
        field={field}
        item={item}
        mode={mode}
      />
    );
  }
);
