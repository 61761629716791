import { Box, HStack, Text } from "@chakra-ui/layout";
import { ItemLoader } from "component/ItemLoader";
import { UserAvatar } from "component/UserAvatar";
import { observer } from "mobx-react-lite";
import { useSpaceContext } from "module/sunday/space/context";
import React, { useMemo } from "react";
import { useStore } from "store/RootStore";

interface Props {
  id: string;
  updatedAt: string;
}

export const ItemBoardCard = observer(({ id, updatedAt }: Props) => {
  const { itemStore } = useStore();
  const item = itemStore.itemsHash[id];
  const space = useSpaceContext();

  const cardLabel = useMemo(() => {
    if (item) {
      return item.customData?.subject || item.customData[space.ui.body[0]];
    } else {
      return null;
    }
  }, [item, space]);

  return (
    <ItemLoader id={id} updatedAt={updatedAt}>
      {item && (
        <Box onClick={() => itemStore.setViewingItemId(id)} minH="50px" p="5px">
          <HStack justifyContent="space-between" alignItems="flex-start">
            <Text fontSize="12px">{cardLabel}</Text>
            <UserAvatar
              avatarProps={{ size: "xs" }}
              id={item.customData?.assignedTo}
            />
          </HStack>
        </Box>
      )}
    </ItemLoader>
  );
});
