import { Box, Flex, Stack } from "@chakra-ui/layout";
import { MobileHeader } from "component/MobileHeader";
import { useIsMobile } from "hooks/useDevice";
import React, { useEffect } from "react";
import { Route, RouteChildrenProps, useParams } from "react-router";
import TablesSidebar from "screen/Team/Sidebar/TablesSidebar/TablesSidebar";
import { useStore } from "store/RootStore";
import { CreateItem } from "./CreateItem";
import { SpaceDetail } from "./SpaceDetail";

interface Props {}

export const Spaces: React.FunctionComponent<Props & RouteChildrenProps> = ({
  match,
  history,
  location,
}) => {
  const { spaceStore } = useStore();
  const { spaceId } = useParams<{ spaceId: string }>();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (location.pathname === match?.url && spaceStore.spaces?.[0]) {
      history.push(`${match?.url}/${spaceStore.spaces[0].id}`);
    }
  }, [spaceStore.viewingSpace, spaceStore.spaces, history, match, location]);

  return (
    <Flex h="full" w="full" flexDir={"row"}>
      <Box
        h="full"
        w={{ base: "72", xs: "100%" }}
        bg="gray.900"
        color="white"
        fontSize="sm"
        borderRight={"1px solid"}
        borderRightColor="gray.700"
      >
        <Flex h="full" direction="column" px="4" py="4">
          <Stack spacing="8" flex="1" overflow="auto" pt="8">
            <TablesSidebar />
          </Stack>
        </Flex>
      </Box>

      <Box
        flexDirection="column"
        display="flex"
        height="100%"
        overflowY={"auto"}
        flex={1}
      >
        {isMobile && <MobileHeader />}
        <Route exact path={`${match!.path}/:spaceId`}>
          <SpaceDetail key={spaceId} />
        </Route>
        <CreateItem />
      </Box>
    </Flex>
  );
};
