import React from "react";
import { FlowControllerProps } from "./const";
import { useCurrentSpace, useSpaceInfo } from "module/sunday/space/hooks";
import { DateField } from "field/Date/DateField";
import { AssignField } from "field/Assign/AssignField";
import { FileField } from "field/File/FileField";
import { useController, useFormContext } from "react-hook-form";

const ComponentMap = {
  date: DateField,
  assign: AssignField,
  file: FileField,
};

export const FieldValue: React.FunctionComponent<FlowControllerProps> = ({
  field,
  fieldName,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
  });
  const { space } = useCurrentSpace();
  const { fieldsByKey } = useSpaceInfo(space);
  const customField = fieldsByKey?.[value];
  const component = (ComponentMap as any)[customField?.type];

  return component
    ? React.createElement(component, {
        onChange,
        value,
      })
    : null;
};
