import React from "react";
import { Portal, Box, HStack, VStack } from "@chakra-ui/react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { ICustomFieldOption, ICustomField } from "module/sunday/space";
import { FILTER_DATE_OPERATIONS } from "util/filter";
import { HiOutlineFilter } from "react-icons/hi";
import { useIntl } from "react-intl";

interface QuickFilterColumnProps {
  type: string;
  optionKey: string;
  header: string;
  options: Array<ICustomFieldOption>;
  handleQuickFilterOptionClick: (
    type: string,
    key: string,
    option: string
  ) => void;
  selectedOptions: Array<string>;
}

const QuickFilterColumn: React.FC<QuickFilterColumnProps> = ({
  type,
  optionKey,
  header,
  options,
  handleQuickFilterOptionClick,
  selectedOptions,
}) => {
  const isDateType = type === "date";
  const filterOptions = isDateType ? FILTER_DATE_OPERATIONS : options;
  return (
    <VStack marginRight="8px" height="100%">
      <Box
        display="flex"
        justifyContent="center"
        width="120px"
        fontWeight="bold"
      >
        {header}
      </Box>
      <Box overflow="scroll" flex={1} minHeight={0}>
        {filterOptions.map((option) => {
          const isSelected = selectedOptions?.includes(option.key);
          return (
            <HStack
              borderRadius="5px"
              width="120px"
              bg={isSelected ? "gray.500" : ""}
              _hover={{ bg: "gray.400" }}
              _active={{ bg: "gray.500" }}
              justifyContent="center"
              my="4px"
              onClick={() => {
                handleQuickFilterOptionClick(type, optionKey, option.key);
              }}
            >
              <Box py="4px">{option.label}</Box>
            </HStack>
          );
        })}
      </Box>
    </VStack>
  );
};

interface QuickFilterProps {
  filtersCount: number;
  popoverHeight: number;
  validGroupByFields: Array<ICustomField>;
  setFilters: (value: any) => void;
  filters: Array<any>;
}

const QuickFilter: React.FC<QuickFilterProps> = (props) => {
  const {
    filtersCount,
    popoverHeight,
    validGroupByFields,
    setFilters,
    filters,
  } = props;
  const { formatMessage } = useIntl();

  // [{key:'status', value:[], op:"in"}]

  const handleQuickFilterOptionClick = (
    type: string,
    key: string,
    option: string
  ) => {
    const isOptionInFilter = filters
      .find((_filter) => _filter.key === key)
      ?.value?.includes(option);
    if (type === "date") {
      if (isOptionInFilter) {
        setFilters([...filters.filter((_filter) => _filter.key !== key)]);
      } else {
        setFilters([
          ...filters.filter((_filter) => _filter.key !== key),
          {
            key,
            value: [option],
            op: "in",
            conditionConnector: "and",
            isSaved: true,
          },
        ]);
      }
    } else {
      if (isOptionInFilter) {
        setFilters([
          ...filters.filter((_filter) => _filter.key !== key),
          {
            key,
            value: filters
              .find((_filter) => _filter.key === key)
              ?.value?.filter((_option: string) => _option !== option),
            op: "in",
            conditionConnector: "and",
            isSaved: true,
          },
        ]);
      } else {
        setFilters([
          ...filters.filter((_filter) => _filter.key !== key),
          {
            key,
            value: [
              ...(filters.find((_filter) => _filter.key === key)?.value || []),
              option,
            ],
            op: "in",
            conditionConnector: "and",
            isSaved: true,
          },
        ]);
      }
    }
  };

  return (
    <Popover isLazy closeOnBlur>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <HStack
              cursor="pointer"
              _hover={{ bg: "gray.400" }}
              _active={{ bg: "gray.500" }}
              px={2}
              borderRadius="5px 0px 0px 5px"
              height="24px"
            >
              <HiOutlineFilter color="gray.300" />
              <Box>
                {formatMessage({ id: "table.label.filter" })}
                {filtersCount > 0 ? ` / ${filtersCount}` : ""}
              </Box>
            </HStack>
          </PopoverTrigger>
          <Portal appendToParentPortal={true}>
            <PopoverContent
              width="auto"
              height={`${popoverHeight}px`}
              overflow="hidden"
            >
              <PopoverArrow />
              <PopoverBody padding="16px" height="100%">
                <Box marginBottom="16px">
                  {formatMessage({ id: "table.filter.quick" })}
                </Box>
                <HStack
                  display="flex"
                  alignItems="flex-start"
                  height={`${popoverHeight - 74}px`}
                >
                  {validGroupByFields.length > 0 ? (
                    validGroupByFields.map((field) => (
                      <QuickFilterColumn
                        key={field.key}
                        type={field.type}
                        header={field.label}
                        optionKey={field.key}
                        options={(field.options || [])}
                        handleQuickFilterOptionClick={
                          handleQuickFilterOptionClick
                        }
                        selectedOptions={
                          filters.find((_filter) => _filter.key === field.key)
                            ?.value
                        }
                      />
                    ))
                  ) : (
                    <Box>{formatMessage({ id: "table.filter.noFilter" })}</Box>
                  )}
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default QuickFilter;
