import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
} from "@chakra-ui/react";
import { NavItem } from "component/NavItem";
import { StopPropagation } from "component/StopPropagation";
import { usePageResource, useTeamPages } from "graphql/useTeamPages";
import * as React from "react";
import { BiCreditCard, BiPlus } from "react-icons/bi";
import { MdMoreHoriz } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import cuid from "cuid";
import { useRouteMatch } from "react-router";

interface PagesSidebarProps {
  match?: any;
}

const PagesSidebar: React.FC<PagesSidebarProps> = () => {
  const match = useRouteMatch();
  const { data: teamPagesData = {} } = useTeamPages();
  const { create, remove } = usePageResource();
  const { sundayPages: pages = [] } = teamPagesData;
  return (
    <VStack>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        mb="20px"
      >
        <Button
          width="100%"
          fontSize={"12px"}
          my={"10px"}
          onClick={() => {
            create({
              title: "New Page",
              path: cuid(),
            });
          }}
        >
          <BiPlus />
          添加
        </Button>
      </HStack>
      {pages.map((page: any) => (
        <NavItem
          key={page.id}
          href={`${match!.url}/${page.id}`}
          icon={<BiCreditCard />}
          label={page.title}
          endElement={
            <StopPropagation>
              <Menu isLazy>
                <MenuButton as={Box}>
                  <MdMoreHoriz />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      remove(page.id);
                    }}
                  >
                    <FormattedMessage
                      id="label.remove"
                      defaultMessage="删除"
                    />
                  </MenuItem>
                </MenuList>
              </Menu>
            </StopPropagation>
          }
        />
      ))}
    </VStack>
  );
};

export default PagesSidebar;
