import { API } from "api";
import axios from "axios";
import { SundayService } from "module/sunday/service";

class FileServiceClass extends SundayService {
  upload = async (
    teamId: string,
    file: File,
    onUploadProgress: any,
    extraParams: any
  ) => {
    const {
      data: { url: uploadSignedUrl, id, key },
    } = await API.post(`/sunday-teams/${teamId}/file-signed-url`, {
      contentType: file.type,
    });

    await axios.put(uploadSignedUrl, file, {
      onUploadProgress,
      headers: {
        "Content-Type": file.type,
      },
      transformRequest: (data, headers: any) => {
        delete headers.common["Authorization"];
        return data;
      },
    });

    const { data } = await API.post(`/sunday-teams/${teamId}/file`, {
      id,
      size: file.size,
      contentType: file.type,
      teamId,
      key,
      name: file.name,
      ...extraParams,
    });
    return data.url;
  };
}

export const FileService = new FileServiceClass("zhuxin-file");
