import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { SELECTOR_FIELD_TYPES } from "field";
import React from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

interface Props {
  field: string;
}

export const Selector = ({ field: fieldPrefix }: Props) => {
  const { control, getValues } = useFormContext();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: `${fieldPrefix}.options`, // unique name for your Field Array
      // keyName: "id", default to "id", you can change the key name
    }
  );

  const subtype = useWatch({
    control,
    name: `${fieldPrefix}.subtype`,
    defaultValue: getValues(`${fieldPrefix}.subtype`),
  });

  return (
    <>
      <HStack my={2}>
        <Text width="100px">子类型</Text>
        <Select {...control.register(`${fieldPrefix}.subtype`)}>
          <option>选一个子类型</option>
          {SELECTOR_FIELD_TYPES.map((fieldType: any) => (
            <option value={fieldType.key}>{fieldType.label}</option>
          ))}
        </Select>
      </HStack>

      <HStack justifyContent="space-between" w="100%">
        <Text>选项</Text>
        <HStack>
          <Button onClick={() => append({})}>+</Button>
        </HStack>
      </HStack>

      <VStack w="100%">
        {fields.map((item, optionIndex: number) => (
          <HStack alignItems="flex-end" w="100%" key={item.id} my={2}>
            {subtype === "status-button" && (
              <Flex flexDir="column" flex={0.5}>
                <Text mb="2">颜色</Text>
                <Controller
                  render={({ field }) => (
                    <Input border="none" type="color" {...field} />
                  )}
                  name={`${fieldPrefix}.options.${optionIndex}.color`}
                  control={control}
                />
              </Flex>
            )}
            <Flex flexDir="column" flex={1}>
              <Text mb="2">Key</Text>
              <Controller
                render={({ field }) => <Input {...field} />}
                name={`${fieldPrefix}.options.${optionIndex}.key`}
                control={control}
              />
            </Flex>
            <Flex flexDir="column" flex={1}>
              <Text mb="2">标签</Text>
              <Controller
                render={({ field }) => <Input {...field} />}
                name={`${fieldPrefix}.options.${optionIndex}.label`}
                control={control}
              />
            </Flex>
            <Button onClick={() => remove(optionIndex)}>-</Button>
          </HStack>
        ))}
      </VStack>
    </>
  );
};
