import { Box, HStack, Text, VStack, Center } from "@chakra-ui/layout";
import React, { useMemo, useState } from "react";
import { useStore } from "store/RootStore";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { Skeleton } from "@chakra-ui/skeleton";
import { ItemService } from "module/sunday/item";
import { SundayItemList } from "component/SundayItemList";
import { useDisclosure } from "@chakra-ui/hooks";
import { FaUser } from "react-icons/fa";
import { getTimeByDateOption } from "util/filter";
import {
  useCurrentSpace,
  useMeColumnKey,
  useSpaceInfo,
} from "module/sunday/space/hooks";
import { SundayTable } from "component/SundayTable";
import { ISpaceView } from "module/sunday/space";

interface Props {
  columns: any[];
  query: any;
  groupBy?: string;
  sortBy?: any;
  update: any;
  view: ISpaceView;
}

export const SundaySheet = observer(
  ({ groupBy, sortBy, query, columns, update }: Props) => {
    const { authStore } = useStore();
    const [filters, setFilters] = useState<Array<any>>([]);
    const { space } = useCurrentSpace();
    const { fieldsByKey } = useSpaceInfo(space);
    const meColumn = useMeColumnKey(space);
    const { isOpen: isViewingMe, onToggle: toggleIsViewingMe } =
      useDisclosure();

    const filterQuery = useMemo(() => {
      return filters
        .filter((f) => f.isSaved)
        .reduce((acc, item) => {
          if (item.value && item.value.length > 0) {
            let itemValue = item.value;
            if (fieldsByKey[item.key].type === "date") {
              const range = getTimeByDateOption(item.value[0]);
              acc[`customData.${item.key}`] = {
                op: item.op === "isNot" ? "not between" : "between",
                value: [range.start, range.end],
              };
            } else {
              acc[`customData.${item.key}`] = {
                op: item.op,
                value: itemValue,
              };
            }
          }
          return acc;
        }, {});
    }, [filters]);

    const {
      data = [],
      isLoading,
      dataUpdatedAt,
    } = useQuery(
      [
        "sundayTable",
        groupBy,
        query,
        isViewingMe,
        sortBy,
        filterQuery,
        meColumn,
      ],
      () => {
        const orderBy = [];
        const select: any = [['item."updatedAt"', "updatedAt"]];
        if (groupBy) {
          orderBy.push([`customData.${groupBy}`, "DESC"]);
          select.push([`customData.${groupBy}`, "order_field"]);
        }
        if (sortBy) {
          orderBy.push([
            `customData.${sortBy.key}`,
            sortBy.direction || "DESC",
          ]);
        }

        return ItemService.query({
          ...query,
          select,
          where: {
            ...query.where,
            ...(isViewingMe
              ? {
                  [`customData.${meColumn}`]: authStore.user.id,
                }
              : {}),
            ...filterQuery,
          },
          groupBy,
          orderBy,
        });
      },
      {
        refetchOnWindowFocus: false,
      }
    );

    const sectionIndexes = useMemo(() => {
      const indexes: number[] = [0];
      for (let i = 0; i < data.length; i++) {
        if (i > 0 && data[i - 1].order_field !== data[i].order_field) {
          indexes.push(i);
        }
      }
      return indexes;
    }, [data]);

    const sectionLengths = useMemo(() => {
      const lengths: number[] = [];
      sectionIndexes.forEach((count, index) => {
        if (index !== sectionIndexes.length - 1) {
          lengths.push(sectionIndexes[index + 1] - count);
        } else {
          lengths.push(data.length - count);
        }
      });
      return lengths;
    }, [sectionIndexes, data]);

    return (
      <VStack height="100%" width="100%" flex={1}>
        <Box w="100%" flex={1} overflowY="auto">
          {isLoading ? (
            <>
              {[0, 0, 0, 0, 0, 0, 0].map(() => (
                <Skeleton my="10px" height="65px" />
              ))}
            </>
          ) : (
            <SundayTable data={data} columns={columns} />
          )}
        </Box>
      </VStack>
    );
  }
);
