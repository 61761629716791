import { Box, VStack } from "@chakra-ui/layout";
import { SundayTable, SundayTableOrganized } from "component/SundayTable";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { useStore } from "store/RootStore";
import { ISpaceView } from "module/sunday/space";
import { SundayItemCell } from "component/SundayItemList/SundayItemCell";
import { useSpaceView } from "module/sunday/space/hooks";
import { SundaySheet } from "component/SundaySheet";

interface Props {
  view: ISpaceView;
}

export const SheetView = observer(({ view }: Props) => {
  const { spaceStore, itemStore } = useStore();
  const { customFieldsByKey } = spaceStore;
  const { updateView } = useSpaceView(view);

  const getColumnWidth = useCallback((column) => {
    return column.width ? `${column.width}px` : "100px";
  }, []);

  const renderCell = useCallback(
    ({ key, custom }: { key: string; custom?: boolean }) =>
      (item: any) => {
        const task = itemStore.itemsHash?.[item.row.original?.id];
        if (!task) {
          return null;
        }
        return (
          <Box {...item.cell.getCellProps()}>
            <SundayItemCell field={customFieldsByKey[key]} item={task} />
          </Box>
        );
      },
    [customFieldsByKey, itemStore.itemsHash]
  );

  const tableColumns = useMemo(() => {
    return (view.columns || []).map((column: any) => {
      const Header = customFieldsByKey[column.key]?.label;
      return {
        key: column.key,
        Header,
        width: getColumnWidth(column),
        Cell: renderCell(column),
      };
    });
  }, [view, renderCell, customFieldsByKey, getColumnWidth]);

  return (
    <Box w="100%" h="100%" padding={{ base: "0px", md: "20px" }}>
      <SundaySheet
        query={{
          where: {
            "space.key": spaceStore.viewingSpace!.key,
          },
        }}
        view={view}
        columns={tableColumns}
        update={updateView}
      />
    </Box>
  );
});
