import { Box, HStack, Tab, TabList, Tabs } from "@chakra-ui/react";
import { AccountSwitcher } from "component/AccountSwitcher";
import React, { useMemo } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";

interface HeaderProps {
  match: any;
}

const TABS = ["spaces", "pages", "workflows"];

const Header: React.FC<HeaderProps> = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const handleTabClick = (url: string) => {
    history.push(`${match!.url}/${url}`);
  };

  const activeTab = useMemo(() => {
    return TABS.findIndex((item) => location.pathname.includes(item));
  }, [location]);

  return (
    <HStack borderBottom={'1px solid'} borderBottomColor="gray.700" width="100%" height="100%" alignItems="center" color="#FFFFFF">
      <Box width="20%" />
      <Box width="60%" justifyContent="center" px="32">
        <Tabs index={activeTab} isFitted variant="soft-rounded">
          <TabList>
            <Tab
              onClick={() => {
                handleTabClick("spaces");
              }}
            >
              数据空间
            </Tab>
            <Tab
              onClick={() => {
                handleTabClick("pages");
              }}
            >
              页面
            </Tab>
            <Tab
              onClick={() => {
                handleTabClick("workflows");
              }}
            >
              工作流
            </Tab>
          </TabList>
        </Tabs>
      </Box>
      <Box width="20%" justifyContent="flex-end" px="4">
        <AccountSwitcher />
      </Box>
    </HStack>
  );
};

export default Header;
