import React from "react";
import { Box } from "@chakra-ui/layout";
import { HStack, Text } from "@chakra-ui/react";
import { UserAvatar } from "component/UserAvatar";
import { ICustomField } from "module/sunday/space";
import { useCurrentSpaceInfo } from "module/sunday/space/hooks";
import { useStore } from "store/RootStore";

interface Props {
  groupBy: string;
  groupKey: string;
}

export const GroupByCell = ({ groupBy, groupKey }: Props) => {
  const { teamStore } = useStore();
  const { fieldsByKey } = useCurrentSpaceInfo();
  const field: ICustomField = fieldsByKey[groupBy];

  if (field.type === "selector") {
    return (
      <Box
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
        padding="5px 10px"
        cursor="pointer"
        backgroundColor={
          (field.options || []).find((option) => option.key === groupKey)?.color
        }
      >
        {(field.options || []).find((option) => option.key === groupKey)?.label}
      </Box>
    );
  }

  if (field.type === "assign") {
    return (
      <HStack>
        <UserAvatar
          avatarProps={{
            size: "xs",
          }}
          id={groupKey}
        />
        <Text>
          {teamStore.usersById[groupKey]?.username ||
            teamStore.usersById[groupKey]?.email}
        </Text>
      </HStack>
    );
  }

  return <div>{groupKey}</div>;
};
