export const WechatWorkAction = {
  key: "wechat-work",
  label: "Wechat Work",
  fields: [
    {
      key: "corpid",
      label: "Corp ID",
      type: "input",
    },
    {
      key: "corpsecret",
      label: "Corp Secret",
      type: "input",
    },
    {
      key: "agentid",
      label: "Agent ID",
      type: "input",
    },
    {
      key: "type",
      label: "type",
      type: "selector",
      options: [
        {
          label: "Send",
          value: "send",
        },
        {
          label: "Receive",
          value: "receive",
        },
      ],
    },
    {
      key: "data",
      label: "Data",
      type: "json",
    },
  ],
};
