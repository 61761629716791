import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import React from "react";
import { FaChevronDown } from "react-icons/fa";

interface Props {
  selected: React.ReactNode;
  options: { value: string; label: string }[];
  onSelect?: any;
}

export const FlowSelect = ({ selected, options, onSelect }: Props) => {
  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Flex
              flexDir="row"
              borderRadius={"5px"}
              borderWidth={"1px"}
              borderColor="white"
              color="white"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="10px"
              cursor={"pointer"}
            >
              {selected}
              <FaChevronDown />
            </Flex>
          </PopoverTrigger>
          <Portal>
            <PopoverContent bg="gray.800" w="400px" p="10px">
              {options.map((option, index) => (
                <Box
                  cursor={"pointer"}
                  onClick={() => {
                    onClose();
                    onSelect && onSelect(option);
                  }}
                  key={index}
                >
                  {option.label}
                </Box>
              ))}
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
