export interface IBlock {
  type: string;
  component: any;
  label: string;
  icon?: React.ReactNode;
  schema?: any;
  uiSchema?: any;
  default?: any;
}

export interface IBlockItem {
  type: string;
  props: any;
  id: string;
}

export const BLOCKS: IBlock[] = [];

export const BLOCKS_MAP: {
  [key: string]: IBlock;
} = BLOCKS.reduce(
  (acc, item) => ({
    ...acc,
    [item.type]: item,
  }),
  {}
);

if (typeof window !== "undefined") {
  (window as any).BLOCKS = BLOCKS;
}
