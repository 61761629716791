import React, { useEffect, useCallback, useRef } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { Checkbox, Flex, Input, Text, Button, Box } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { GrDrag } from "react-icons/gr";
interface Props {
  value: any[];
  onChange: any;
}

const ChecklistDrag = SortableHandle(() => {
  return (
    <Box mr="10px">
      <GrDrag color="white" />
    </Box>
  );
});

const ChecklistItem = SortableElement(
  ({ register, field, remove, itemIndex }: any) => {
    return (
      <Flex my="10px" key={field.id} flexDir="row" alignItems="center">
        <ChecklistDrag />
        <Checkbox {...register(`checklist.${itemIndex}.checked`)} />
        <Input
          mx="20px"
          key={field.id}
          {...register(`checklist.${itemIndex}.value`)}
        />
        <Box
          cursor="pointer"
          onClick={() => {
            remove(itemIndex);
          }}
        >
          <FaTrash />
        </Box>
      </Flex>
    );
  }
);

const ChecklistWrapper = SortableContainer(
  ({ items, register, remove }: any) => {
    return (
      <Box>
        {items.map((field: any, index: number) => (
          <ChecklistItem
            index={index}
            register={register}
            field={field}
            itemIndex={index}
            remove={remove}
          />
        ))}
      </Box>
    );
  }
);

export const ChecklistField = ({ value = [], onChange }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { control, register } = useForm<{
    checklist: any[];
  }>({
    defaultValues: {
      checklist: value,
    },
  });
  const {
    fields = [],
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
  } = useFieldArray<{
    checklist: any[];
  }>({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "checklist", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const checklistValue = useWatch({
    control,
    name: "checklist",
    defaultValue: [],
  });

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: any) => {
      swap(oldIndex, newIndex);
    },
    [swap]
  );

  useEffect(() => {
    onChange(checklistValue);
  }, [checklistValue, onChange]);

  return (
    <Flex ref={containerRef} w="100%" flexDir="column" alignItems="flex-start">
      <ChecklistWrapper
        helperContainer={containerRef.current}
        useDragHandle
        lockAxis="y"
        axis="y"
        register={register}
        remove={remove}
        items={fields}
        onSortEnd={onSortEnd}
      ></ChecklistWrapper>
      <Button
        onClick={() => {
          append({
            checked: false,
            value: "",
          });
        }}
      >
        Add
      </Button>
    </Flex>
  );
};
