import { Boolean } from "./Boolean";
import { FieldSelector } from "./FieldSelector";
import { FieldValue } from "./FieldValue";
import { Input } from "./Input";
import { Javascript } from "./Javascript";
import { Json } from "./Json";
import { Password } from "./Password";
import { Selector } from "./Selector";
import { SpaceSelector } from "./SpaceSelector";

export interface FlowControllerProps {
  fieldName: string;
  field: any;
}

export const FlowController = {
  input: Input,
  boolean: Boolean,
  password: Password,
  "field-selector": FieldSelector,
  "field-value": FieldValue,
  selector: Selector,
  json: Json,
  javascript: Javascript,
  'space-selector': SpaceSelector
};
