import { Box, Flex, HStack } from "@chakra-ui/react";
import React from "react";
import { IoMenu } from "react-icons/io5";
import { useStore } from "store/RootStore";

type Props = {
  rightIcons?: React.ReactNode;
};

export const MobileHeader = ({ rightIcons }: Props) => {
  const { uiStore } = useStore();
  return (
    <HStack
      padding={"10px"}
      position="sticky"
      top="0"
      h="60px"
      minH="60px"
      borderBottom={"1px solid"}
      borderBottomColor="gray.400"
      backgroundColor="gray.800"
      zIndex={1000}
    >
      <Box
        onClick={() => {
          uiStore.setIsMobileDrawerOpen(true);
        }}
      >
        <IoMenu size={24} />
      </Box>
      <Flex flex={1}></Flex>
      {rightIcons}
    </HStack>
  );
};
