export const SundaySpaceItemUpdate = {
  key: "sunday.space.item.update",
  fields: [
    {
      key: "key",
      // type: "field-selector",
      type: "input",
    },
    {
      key: "spaceId",
      type: "space-selector",
    },
    {
      key: "from",
      fieldKey: "key",
      // type: "field-value",
      type: "input",
      showCondition: (params: any) => !!params.key,
    },
    {
      key: "to",
      fieldKey: "key",
      // type: "field-value",
      type: "input",
      showCondition: (params: any) => !!params.key,
    },
  ],
};

export const SundaySpaceItemCreate = {
  key: "sunday.space.item.create",
  fields: [
    {
      key: "spaceId",
      type: "space-selector",
    },
  ],
};
