import { languages } from "i18n";
import { makeAutoObservable } from "mobx";

interface IStatus {
  key: string;
  label: string;
  color: string;
}

interface IPriority {
  key: string;
  label: string;
  color: string;
}

const STATUSES: IStatus[] = [
  {
    key: "todo",
    label: "待开始",
    color: "rgb(181, 188, 194)",
  },
  {
    key: "in-progress",
    label: "进行中",
    color: "rgb(249, 217, 0)",
  },
  {
    key: "complete",
    label: "已完成",
    color: "rgb(107, 201, 80)",
  },
];

const PRIORITIES: IPriority[] = [
  {
    key: "low",
    label: "低",
    color: "rgb(181, 188, 194)",
  },
  {
    key: "medium",
    label: "中",
    color: "rgb(249, 217, 0)",
  },
  {
    key: "high",
    label: "高",
    color: "red",
  },
];

export class ConfigStore {
  statuses: IStatus[] = [];
  statusesByKey: {
    [key: string]: IStatus;
  } = {};

  priorities: IPriority[] = [];
  prioritiesByKey: {
    [key: string]: IPriority;
  } = {};

  language: "en-US" | "zh-CN" ='zh-CN';

  constructor() {
    makeAutoObservable(this);
    this.init();
  }
  init = () => {
    this.getStatuses();
    this.getPriorities();
    this.initLanguage();
  };

  getStatuses = () => {
    this.statuses = STATUSES;
    this.statusesByKey = STATUSES.reduce(
      (acc, item) => ({
        ...acc,
        [item.key]: item,
      }),
      {}
    );
  };

  getPriorities = () => {
    this.priorities = PRIORITIES;
    this.prioritiesByKey = PRIORITIES.reduce(
      (acc, item) => ({
        ...acc,
        [item.key]: item,
      }),
      {}
    );
  };

  initLanguage = () => {
   const languageFromStorage = (localStorage.getItem("language") as any);
   const languageFromBrowser = !!(languages as any)[navigator.language] ? navigator.language : 'en-US';
   this.language = languageFromStorage || languageFromBrowser;
  }

  setLanguage = (language: any) => {
    this.language = language;
    localStorage.setItem("language", language);
  };
}

export const configStore = new ConfigStore();
