import { Center } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BsTable } from "react-icons/bs";
import { MdFileDownload } from "react-icons/md";

interface Props {}

export const File = (props: Props) => {
  const { control } = useFormContext();

  return (
    <Center py="30px" flexDir="column" w="full" h="full">
      <MdFileDownload fontSize="40px" />
      <Text mt="20px">文件</Text>
      <Text color="gray.200" fontSize="12px">
        统一的浏览当前空间中所有文件，并进行管理
      </Text>
    </Center>
  );
};
