import { imStore, ImStore } from "module/im/store";
import { createContext, useContext } from "react";
import { authStore, AuthStore } from "module/auth";
import { configStore, ConfigStore } from "./ConfigStore";
import { uiStore, UiStore } from "./UiStore";
import { spaceStore, SpaceStore } from "module/sunday/space";
import { teamStore, TeamStore } from "module/sunday/team";
import { itemStore, ItemStore } from "module/sunday/item";
import { fileStore, FileStore } from "module/file/store";
import { documentStore, DocumentStore } from "module/sunday/document";
import { flowStore, FlowStore } from "module/flow/store";

export interface RootStore {
  authStore: AuthStore;
  teamStore: TeamStore;
  configStore: ConfigStore;
  spaceStore: SpaceStore;
  imStore: ImStore;
  itemStore: ItemStore;
  fileStore: FileStore;
  documentStore: DocumentStore;
  flowStore: FlowStore;
  uiStore: UiStore;
}

export const rootStore: RootStore = {
  authStore,
  teamStore,
  configStore,
  spaceStore,
  imStore,
  itemStore,
  fileStore,
  documentStore,
  flowStore,
  uiStore
};

export const RootContext = createContext<RootStore>(rootStore);

export const useStore = (): RootStore => {
  const store = useContext(RootContext);
  return store;
};

(window as any).rootStore = rootStore;
