import { useMemo } from "react";
import { useLocation } from "react-router";

export function useLocationQuery() {
  const location = useLocation().search;
  const query = useMemo(() => {
    return new URLSearchParams(location);
  }, [location]);
  return query;
}
