import React from "react";
import { Center } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { useStore } from "store/RootStore";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router";

interface Props {}

export const Loading = observer((props: Props) => {
  const { authStore } = useStore();

  return (
    <Center width="100vw" height="100vh">
      <Spinner size="xl" />

      {authStore.authLoaded && !authStore.isLoggedIn && <Redirect to="/auth" />}
      {authStore.authLoaded && !!authStore.isLoggedIn && (
        <Redirect to="/dashboard" />
      )}
    </Center>
  );
});
