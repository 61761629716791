import { makeAutoObservable } from "mobx";

export class UiStore {

    isMobileDrawerOpen = false;

    constructor() {
        makeAutoObservable(this);
    }

    setIsMobileDrawerOpen = (bool: boolean) => {
        this.isMobileDrawerOpen = bool
    }
}


export const uiStore = new UiStore();