import { Box, Code, Flex, Switch, Text } from "@chakra-ui/react";
import { API_BASE } from "api";
import { ISpace } from "module/sunday/space";
import React, { ChangeEvent } from "react";
import { useStore } from "store/RootStore";

type Props = {
  space: ISpace;
};

export const SpaceSettings = ({ space }: Props) => {
  const { spaceStore } = useStore();

  const onChangeAllow = (event: ChangeEvent<HTMLInputElement>) => {
    spaceStore.updateSpace({
      id: space.id,
      allowExternalForm: !!event.target.checked,
    });
  };

  return (
    <Box width={"100%"}>
      <Flex justifyContent={"space-between"} width={"100%"}>
        <Text>允许外部表单</Text>

        <Switch onChange={onChangeAllow} isChecked={space.allowExternalForm} />
      </Flex>

      {space.allowExternalForm && (
        <Flex flexDir={'column'} w="full">
          <Text my="10px">表单发送地址</Text>
          <Code padding="10px" children={`${API_BASE}/sunday-spaces/${space.id}/form`}></Code>
        </Flex>
      )}
    </Box>
  );
};
