import { Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { FlowSelect } from "../../FlowSelect";
import { ACTIONS } from "./actions";

interface Props {
  fieldName: string;
}

export const ActionType = ({ fieldName }: Props) => {
  const intl = useIntl();

  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name: `${fieldName}.type`,
    control,
  });

  const options = useMemo(() => {
    return ACTIONS.map((action) => ({
      value: action.key,
      label: intl.formatMessage({
        id: `flow.action.label.${action.key}`,
        defaultMessage: action.label,
      }),
    }));
  }, [intl]);

  return (
    <FlowSelect
      options={options}
      selected={
        value ? (
          <Text>
            {intl.formatMessage({
              id: `flow.action.label.${value}`,
            })}
          </Text>
        ) : (
          <Text>Select an action</Text>
        )
      }
      onSelect={(option: any) => {
        onChange(option.value);
      }}
    />
  );
};
