export const SundayAction = {
  key: "sunday",
  label: "Sunday",
  fields: [
    {
      key: "type",
      type: "selector",
      defaultValue: "update",
      options: [
        {
          label: "Update",
          value: "update",
        },
        {
          label: "Create",
          value: "create",
        },
        {
          label: "Delete",
          value: "delete",
        },
        {
          label: "Archive",
          value: "archive",
        },
        {
          label: "Duplicate",
          value: "duplicate",
        },
      ],
    },
    {
      key: "key",
      // type: "field-selector",
      type: "input",
      defaultValue: "_ANY",
      showCondition: (params: any) => params.type === "update",
    },
    {
      key: "value",
      fieldKey: "key",
      // type: "field-value",
      type: "input",
      defaultValue: "_ANY",
      showCondition: (params: any) => params.type === "update" && !!params.key,
    },
  ],
};

export const SundayQueryAction = {
  key: "sundayQuery",
  label: "Sunday Query",
  fields: [
    {
      key: "query",
      type: "json",
      defaultValue: {
        where: {
          customData: {
            path: [],
            equals: "",
          },
        },
      },
    },
  ],
};
