import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import Image from "@tiptap/extension-image";
import StarterKit from "@tiptap/starter-kit";
import { EditorOptions } from "@tiptap/core";
import { SundayMention } from "./SundayEditorMentionSuggestion";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";

export const useSundayEditor = (
  options: Partial<EditorOptions> = {},
  extraOptions: any = {}
) => {
  const editor = useEditor({
    content: "<p>Hello World! 🌎️</p>",
    ...options,
    extensions: [
      StarterKit,
      Underline,
      SundayMention,
      Image,
      Link.configure({
        openOnClick: false,
        linkOnPaste: true,
        HTMLAttributes: {
          target: "_blank",
        },
      }),
      Placeholder.configure({
        placeholder: extraOptions.placeholder || "任务详情",
      }),
      ...(options.extensions || []),
    ],
  });
  return editor;
};
