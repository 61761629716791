import { VStack } from "@chakra-ui/layout";
import { observer } from "mobx-react-lite";
import { ISpace, ISpaceView } from "module/sunday/space";
import { SpaceViewContext } from "module/sunday/space/context";
import { BoardView } from "./BoardView";
import { EmbedView } from "./EmbedView";
import { FileView } from "./FileView";
import { SheetView } from "./SheetView";
import { TableView } from "./TableView";

interface Props {
  view: ISpaceView;
}


export const SpaceView = observer(({ view }: Props) => {
  return (
    <SpaceViewContext.Provider
      value={view}
    >
      <VStack
        minH="0"
        h="100%"
        w="100%"
        flex={1}
        padding={{ base: "0px", md: "20px" }}
      >
        {view.type === "table" && <TableView view={view} />}
        {view.type === "board" && <BoardView view={view} />}
        {view.type === "file" && <FileView view={view} />}
        {view.type === "embed" && <EmbedView view={view} />}
        {view.type === "sheet" && <SheetView view={view} />}
      </VStack>
    </SpaceViewContext.Provider>
  );
});
