import { API } from "api";
import { SundayService } from "../service";

class SpaceServiceClass extends SundayService {
  async resetPositions(id: string) {
    const response = await API.post(`${this.base}/${id}/reset-positions`);
    return response.data;
  }

  async createFlow(id: string, flow: any) {
    const response = await API.post(`${this.base}/${id}/flows`, flow);
    return response.data;
  }

  async updateFlow(id: string, flow: any) {
    const response = await API.patch(`/zhuxin-flow/${id}`, flow);
    return response.data;
  }

  async triggerFlow(id: string) {
    const response = await API.post(`/zhuxin-flow/trigger/${id}`, {});
    return response.data;
  }
}

export const SpaceService = new SpaceServiceClass("sunday-spaces");
