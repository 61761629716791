import { makeAutoObservable, reaction } from "mobx";
import { ItemService } from "./ItemService";
import { spaceStore } from "../space";
import { Item } from "const/type";
import { toast } from "util/toast";
import { queryClient } from "util/query";
import { makePersistable } from "mobx-persist-store";
import localForage from "localforage";

export class ItemStore {
  itemsHash: {
    [key: string]: Item;
  } = {};
  itemsHashLoading: {
    [key: string]: boolean;
  } = {};

  itemsToLoad: string[] = [];
  itemsToLoadDebounceTimeout: any;

  viewingItemId: string | null = null;

  constructor() {
    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: "ItemStore",
        properties: ["itemsHash"],
        storage: localForage, // localForage, window.localStorage, AsyncStorage all have the same interface
        expireIn: 86400000, // One day in millsesconds
        removeOnExpiration: true,
        stringify: false,
      },
      { delay: 200, fireImmediately: false }
    );

    reaction(
      () => spaceStore.viewingSpace,
      () => {
        if (spaceStore.viewingSpace) {
          this.init();
        }
      }
    );
  }

  init = () => {};

  loadItem = async (id: string) => {
    if (!this.itemsHashLoading[id]) {
      this.itemsHashLoading = { ...this.itemsHashLoading, [id]: true };
      const item: any = await ItemService.getOne(id);
      delete this.itemsHashLoading[id];
      if (item) {
        this.itemsHash = {
          ...this.itemsHash,
          [id]: item,
        };
      }
    }
  };

  addItemToLoader = (id: string) => {
    if (this.itemsToLoad.includes(id) || !!this.itemsHashLoading[id]) {
      return;
    }
    this.itemsToLoad.push(id);
    if (this.itemsToLoadDebounceTimeout) {
      clearTimeout(this.itemsToLoadDebounceTimeout);
    }
    this.itemsToLoadDebounceTimeout = setTimeout(() => {
      this.loadItems(this.itemsToLoad);
      this.itemsToLoad = [];
    }, 100);
  };

  loadItems = async (ids: string[]) => {
    const loadingIds = ids.filter((id) => !this.itemsHashLoading[id]);

    loadingIds.forEach((id) => {
      this.itemsHashLoading = { ...this.itemsHashLoading, [id]: true };
    });
    const items: Item[] = await ItemService.get({
      where: {
        id: {
          in: loadingIds,
        },
      },
      include: {
        actions: {
          include: {
            user: true,
          },
        },
        attachments: {
          select: {
            id: true,
            key: true,
          },
        },
      },
    });
    loadingIds.forEach((id) => {
      this.itemsHashLoading = { ...this.itemsHashLoading, [id]: false };
    });

    const newHash: any = {};
    items.forEach((item: Item) => {
      newHash[item.id] = item;
    });
    this.itemsHash = {
      ...this.itemsHash,
      ...newHash,
    };
  };

  createItem = async (item: Partial<Item>) => {
    const savedItem = await ItemService.create(item);
    toast({
      title: "创建成功",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    this.itemsHash = {
      ...this.itemsHash,
      [item.id!]: savedItem,
    };
    queryClient.invalidateQueries("sundayTable");
  };

  saveItem = async (item: Partial<Item>, shouldInvalidate: boolean = true) => {
    const { id, ...payload } = item;
    const originalItem = this.itemsHash[id!];
    const updateBody = {
      ...payload,
      customData: {
        ...originalItem.customData,
        ...payload.customData,
      },
    };
    this.itemsHash = {
      ...this.itemsHash,
      [item.id!]: {
        ...originalItem,
        ...updateBody,
      },
    };

    ItemService.update(item.id!, updateBody).catch(() => {
      this.itemsHash[item.id!] = originalItem;
      toast({
        title: "We spot internet issue",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    });
    // toast({
    //   title: "Item Saved",
    //   status: "success",
    //   duration: 2000,
    //   isClosable: true,
    // });
    // shouldInvalidate && queryClient.invalidateQueries("sundayTable");
  };

  setViewingItemId = async (id: string | null) => {
    this.viewingItemId = id;
  };

  comment = async (item: Item, comment: string) => {
    const data = await ItemService.comment(item.id, {
      type: "comment",
      data: { comment },
    });

    this.itemsHash[item.id].actions.push(data);
  };

  get viewingItem(): Item | null {
    if (this.viewingItemId) {
      return this.itemsHash[this.viewingItemId];
    } else {
      return null;
    }
  }

  addAttachment = (itemId: string, fileId: string) => {
    if (!this.itemsHash[itemId].attachments) {
      this.itemsHash[itemId].attachments = [];
    }
    this.itemsHash[itemId].attachments!.push({
      id: fileId,
    });
  };
}

export const itemStore = new ItemStore();
