import { Box, Flex } from "@chakra-ui/layout";
import { useCurrentSpace } from "module/sunday/space/hooks";
import React from "react";
import {
  Editable,
  EditableInput,
  EditablePreview,
  Button,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { SpaceToolbarAdd } from "../SpaceToolbar/SpaceToolbarAdd";

interface Props {}

export const SpaceHeader = observer((props: Props) => {
  const { spaceStore } = useStore();
  const { space, updateSpace } = useCurrentSpace();

  if (!space) {
    return null;
  }

  return (
    <Flex key={space.id} w="100%" flexDir="row" alignItems="center">
      <Editable
        onSubmit={(value) => {
          updateSpace({
            name: value,
          });
        }}
        fontSize="20px"
        defaultValue={space!.name}
      >
        <EditablePreview
          borderWidth="1px"
          borderColor="transparent"
          p="2"
          _hover={{
            borderColor: "white",
          }}
        />
        <EditableInput />
      </Editable>

      <Box flex={1} />

      <SpaceToolbarAdd />
    </Flex>
  );
});
