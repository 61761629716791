import { Box, Center, Flex } from "@chakra-ui/layout";
import { ItemLoader } from "component/ItemLoader";
import React, { useCallback } from "react";
import { FaGripVertical } from "react-icons/fa";
import { MdMoreHoriz } from "react-icons/md";
import { SortableHandle } from "react-sortable-hoc";
import { useStore } from "store/RootStore";
import { SundayItemCell, SundayItemCustomCell } from "../SundayItemCell";
import { SundayItemMore } from "./SundayItemMore";

interface Props {
  data: any;
  columns: any[];
}

const SortHandle = SortableHandle(() => {
  return (
    <Box
      cursor="grab"
      display="none"
      _groupHover={{ display: "initial" }}
      left="0"
      top="50%"
      transform="auto"
      translateY="-50%"
      position="absolute"
    >
      <FaGripVertical />
    </Box>
  );
});

export const SundayItemRow = ({ columns, data }: Props) => {
  const { spaceStore, itemStore } = useStore();
  const { customFieldsByKey } = spaceStore;
  const renderCell = useCallback(
    (id: string, { key, custom }: { key: string; custom?: boolean }) => {
      const item = itemStore.itemsHash?.[id];
      if (!item) {
        return null;
      }
      const field = customFieldsByKey[key];
      return <SundayItemCell field={field} item={item} />;
    },
    [customFieldsByKey, itemStore.itemsHash]
  );

  return (
    <Flex
      role="group"
      position="relative"
      h="65px"
      borderBottom="1px"
      borderColor="gray.700"
    >
      <ItemLoader updatedAt={data.updatedAt} id={data.id}>
        {columns.map((column) => (
          <Center
            h="100%"
            key={column.key}
            flexGrow={1}
            minW={column.width || 100}
            w={column.width || 100}
          >
            {renderCell(data.id, column)}
          </Center>
        ))}
        <Center
          cursor="pointer"
          _hover={{
            background: "gray.700",
          }}
          w={50}
        >
          <Box display="none" _groupHover={{ display: "initial" }}>
            <SundayItemMore item={data} />
          </Box>
        </Center>
        {/* <SortHandle /> */}
      </ItemLoader>
    </Flex>
  );
};
