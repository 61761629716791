import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { SELECTOR_FIELD_TYPES } from "field";
import React from "react";
import { Control, Controller, useFieldArray, useWatch } from "react-hook-form";
import { Selector } from "./CustomType/Selector";

interface Props {
  control: Control<any>;
  index: number;
}

export const SpaceCustomTypeFields = ({ control, index }: Props) => {
  const type = useWatch({
    control,
    name: `fields.${index}.type`,
    defaultValue: "",
  });

  if (!type) {
    return null;
  }

  return <>{type === "selector" && <Selector field={`fields.${index}`} />}</>;
};
