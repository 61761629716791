import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { useStore } from "store/RootStore";

export const useTeamItems = (itemsQuery: any, options: any = {}) => {
  const { teamStore } = useStore();
  return useQuery(itemsQuery, {
    variables: {
      where: {
        teamId: {
          equals: teamStore.selectedTeam?.id,
        },
      },
    },
    skip: !teamStore.selectedTeam?.id,
    ...options
  });
};

export const useTeamSingleItem = (itemQuery: any, id: string) => {
  return useQuery(itemQuery, {
    variables: {
      where: {
        id,
      },
    },
  });
};

export const useItemResource = ({
  CREATE_QUERY,
  UPDATE_QUERY,
  REMOVE_QUERY,
  REFTECH_QUERY,
}: {
  CREATE_QUERY: any;
  UPDATE_QUERY: any;
  REMOVE_QUERY: any;
  REFTECH_QUERY: any;
}) => {
  const { teamStore } = useStore();
  const [mutateCreate] = useMutation(CREATE_QUERY, {
    refetchQueries: [
      {
        query: REFTECH_QUERY,
        variables: {
          where: {
            teamId: {
              equals: teamStore.selectedTeam?.id,
            },
          },
        },
      },
    ],
  });
  const [mutateUpdate] = useMutation(UPDATE_QUERY);
  const [mutateRemove] = useMutation(REMOVE_QUERY, {
    refetchQueries: [
      {
        query: REFTECH_QUERY,
        variables: {
          where: {
            teamId: {
              equals: teamStore.selectedTeam?.id,
            },
          },
        },
      },
    ],
  });

  const create = useCallback(
    async (values) => {
      const data: any = {
        ...values,
      };
      data.team = {
        connect: {
          id: teamStore.selectedTeam?.id,
        },
      };
      return mutateCreate({
        variables: {
          data,
        },
      });
    },
    [mutateCreate, teamStore.selectedTeam]
  );

  const update = useCallback(
    async (id, data) => {
      const enhancedData: any = {};
      Object.keys(data).forEach(dataKey => {
        if (typeof data[dataKey] === 'string') {
          enhancedData[dataKey] = {
            set: data[dataKey]
          }
        }
        else {
          enhancedData[dataKey] = data[dataKey]
        }
      });
      
      return mutateUpdate({
        variables: {
          data: enhancedData,
          where: {
            id,
          },
        },
      });
    },
    [mutateUpdate]
  );

  const remove = useCallback(
    async (id) => {
      return mutateRemove({
        variables: {
          where: {
            id,
          },
        },
      });
    },
    [mutateRemove]
  );

  return {
    create,
    update,
    remove,
  };
};
