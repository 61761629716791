import { Image } from "@chakra-ui/image";
import { Box, Flex, HStack, Text } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import React from "react";
import { FaFilePdf, FaTrash } from "react-icons/fa";
import { useStore } from "store/RootStore";
import { humanFileSize } from "util/file";

interface Props {
  id: string;
  onDelete: any;
}

export const FileView = ({ id, onDelete }: Props) => {
  const { fileStore } = useStore();
  const file = fileStore.filesById[id];

  return (
    <Flex
      p="10px"
      flexDir="column"
      h="180px"
      position="relative"
      role="group"
      alignItems="center"
      cursor="pointer"
    >
      {file.contentType.includes("image") && (
        <Image objectFit="contain" w={"100px"} h={"100px"} src={file.url} />
      )}
      {file.contentType.includes("pdf") && <FaFilePdf fontSize="100px" />}
      <Tooltip label={file.name}>
        <Text
          overflow="hidden"
          w="60%"
          maxW="150px"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {file.name}
        </Text>
      </Tooltip>

      <Box flex={1}></Box>

      <Text
        _groupHover={{ display: "none" }}
        textAlign="center"
        color="gray.300"
      >
        {humanFileSize(+file.size)}
      </Text>

      <HStack
        alignItems="center"
        w="100%"
        justifyContent="center"
        display="none"
        _groupHover={{ display: "flex" }}
      >
        <Box onClick={onDelete} color="red.200" cursor="pointer">
          <FaTrash />
        </Box>
      </HStack>
    </Flex>
  );
};
