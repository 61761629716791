import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Center, Text, Flex } from "@chakra-ui/layout";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useStore } from "store/RootStore";
import { SpaceService } from "../../SpaceService";
import { TEMPLATES } from "./const";

interface Props {}

export const CreateSpace = observer((props: Props) => {
  const { spaceStore, teamStore } = useStore();

  const methods = useForm({
    defaultValues: {
      name: "",
      template: "Kanban",
      key: "",
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      await SpaceService.create({
        name: values.name,
        template: values.template,
        key: values.key,
        team: {
          connect: {
            id: teamStore.selectedTeam!.id,
          },
        },
      });
      await spaceStore.getSpaces();
      spaceStore.setShouldShowCreateSpaceModal(false);
    },
    [spaceStore, teamStore.selectedTeam]
  );

  return (
    <Modal
      isOpen={spaceStore.shouldShowCreateSpaceModal}
      onClose={() => {
        spaceStore.setShouldShowCreateSpaceModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent minH="300px" bg="gray.700" w="600px" maxW="100%">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Center p="20px" flexDir="column" flex={1} w="100%" h="100%">
              <Text mb="20px" fontSize="30px">
                创建新的空间
              </Text>
              <Flex flexDirection="column" w="100%" alignItems="flex-start">
                <Text mb="8px">1. 空间名</Text>
                <Controller
                  rules={{ required: true }}
                  control={methods.control}
                  name="name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        mb="20px"
                        variant="flushed"
                        placeholder="空间名"
                        size="sm"
                      />
                    );
                  }}
                />

                <Text mb="8px">2. 空间标识/Key</Text>
                <Controller
                  rules={{ required: true }}
                  control={methods.control}
                  name="key"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        mb="20px"
                        variant="flushed"
                        placeholder={`空间Key, 比如 NEWSPACE`}
                        size="sm"
                      />
                    );
                  }}
                />

                <Text mb="8px">3. 选择模板</Text>

                <Flex
                  w="100%"
                  overflowX="scroll"
                  flexDir="row"
                  flexWrap="nowrap"
                >
                  {TEMPLATES.map((template) => (
                    <Center mx="20px" w="50%" my="20px">
                      <Center
                        borderWidth="2px"
                        borderColor={
                          methods.watch("template") === template.key
                            ? "#3498db"
                            : "transparent"
                        }
                        onClick={() =>
                          methods.setValue("template", template.key)
                        }
                        flexDir="column"
                        h="200px"
                        width="200px"
                        bg="gray.800"
                        cursor="pointer"
                      >
                        <template.icon color="white" fontSize="50px" />
                        <Text mt="8px">{template.label}</Text>
                      </Center>
                    </Center>
                  ))}
                </Flex>

                <Button type="submit" w="100%">
                  创建
                </Button>
              </Flex>
            </Center>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
