import { Box, HStack, Text, VStack, Center } from "@chakra-ui/layout";
import React, { useCallback, useMemo, useState } from "react";
import { GroupByFilter } from "./GroupByFilter";
import { useStore } from "store/RootStore";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { Skeleton } from "@chakra-ui/skeleton";
import { ItemService } from "module/sunday/item";
import { SundayItemList } from "component/SundayItemList";
import { Filter } from "./Filter";
import { useDisclosure } from "@chakra-ui/hooks";
import { FaUser } from "react-icons/fa";
import { getTimeByDateOption } from "util/filter";
import {
  useCurrentSpace,
  useMeColumnKey,
  useSpaceInfo,
} from "module/sunday/space/hooks";
import SimpleBar from "simplebar-react";
import { ScrollBox } from "component/ScrollBox/ScrollBox";
import { useSpaceContext } from "module/sunday/space/context";
import { IconButton } from "@chakra-ui/react";
import { BiCopy } from "react-icons/bi";
import copy from "copy-to-clipboard";
import { API_BASE } from "api";
import { toast } from "util/toast";

interface Props {
  columns: any[];
  query: any;
  groupBy?: string;
  sortBy?: any;
  update: any;
}

export const SundayTableOrganized = observer(
  ({ groupBy, sortBy, query, columns, update }: Props) => {
    const { authStore } = useStore();
    const [filters, setFilters] = useState<Array<any>>([]);
    const space = useSpaceContext();
    const { fieldsByKey } = useSpaceInfo(space);
    const meColumn = useMeColumnKey(space);
    const { isOpen: isViewingMe, onToggle: toggleIsViewingMe } =
      useDisclosure();

    const filterQuery = useMemo(() => {
      return filters
        .filter((f) => f.isSaved)
        .reduce((acc, item) => {
          if (item.value && item.value.length > 0) {
            let itemValue = item.value;
            if (fieldsByKey[item.key].type === "date") {
              const range = getTimeByDateOption(item.value[0]);
              acc[`customData.${item.key}`] = {
                op: item.op === "isNot" ? "not between" : "between",
                value: [range.start, range.end],
              };
            } else {
              acc[`customData.${item.key}`] = {
                op: item.op,
                value: itemValue,
              };
            }
          }
          return acc;
        }, {});
    }, [filters]);

    const {
      data = [],
      isLoading,
      dataUpdatedAt,
    } = useQuery(
      [
        "sundayTable",
        groupBy,
        query,
        isViewingMe,
        sortBy,
        filterQuery,
        meColumn,
      ],
      () => {
        const orderBy = [];
        const select: any = [['item."updatedAt"', "updatedAt"]];
        if (groupBy) {
          orderBy.push([`customData.${groupBy}`, "DESC"]);
          select.push([`customData.${groupBy}`, "order_field"]);
        }
        if (sortBy) {
          orderBy.push([
            `customData.${sortBy.key}`,
            sortBy.direction || "DESC",
          ]);
        }

        return ItemService.query({
          ...query,
          select,
          where: {
            ...query.where,
            ...(isViewingMe
              ? {
                  [`customData.${meColumn}`]: authStore.user.id,
                }
              : {}),
            ...filterQuery,
          },
          groupBy,
          orderBy,
        });
      },
      {
        refetchOnWindowFocus: false,
      }
    );

    const sectionIndexes = useMemo(() => {
      const indexes: number[] = [0];
      for (let i = 0; i < data.length; i++) {
        if (i > 0 && data[i - 1].order_field !== data[i].order_field) {
          indexes.push(i);
        }
      }
      return indexes;
    }, [data]);

    const sectionLengths = useMemo(() => {
      const lengths: number[] = [];
      sectionIndexes.forEach((count, index) => {
        if (index !== sectionIndexes.length - 1) {
          lengths.push(sectionIndexes[index + 1] - count);
        } else {
          lengths.push(data.length - count);
        }
      });
      return lengths;
    }, [sectionIndexes, data]);

    const handleCopy = useCallback(() => {
      copy(
        `${API_BASE}/sunday-items/stat?query=${encodeURIComponent(
          JSON.stringify({
            where: {
              "space.id": space.id,
              ...filterQuery,
            },
          })
        )}`
      );
      toast({
        title: "复制成功",
        status: "success",
        duration: 500,
      })
    }, [filterQuery, space]);

    return (
      <VStack height="100%" width="100%" flex={1}>
        <HStack w="100%">
          <Box flex={1} />
          <IconButton
            onClick={handleCopy}
            aria-label="copy"
            icon={<BiCopy size={16} />}
          ></IconButton>
          <Filter setFilters={setFilters} filters={filters} />
          <Box width="8px" />
          <HStack
            onClick={toggleIsViewingMe}
            bg={isViewingMe ? "#3498db" : "transparent"}
            borderRadius="10px"
            mx="20px"
            p="2"
            _hover={{
              bg: "gray.700",
            }}
            cursor="pointer"
            alignItems="center"
          >
            <FaUser />
            <Text ml="5px">只看我</Text>
          </HStack>
          <Box width="8px" />
          <GroupByFilter
            groupBy={groupBy}
            onSelect={(groupBy) =>
              update({
                groupBy,
              })
            }
          />
        </HStack>
        <ScrollBox w="100%" flex={1}>
          {isLoading ? (
            <>
              {[0, 0, 0, 0, 0, 0, 0].map(() => (
                <Skeleton my="10px" height="65px" />
              ))}
            </>
          ) : !groupBy ? (
            // <SundayTable data={data} columns={columns} />
            <SundayItemList
              // key={dataUpdatedAt}
              data={data}
              columns={columns}
              useDragHandle
            />
          ) : (
            <SundayItemList
              useDragHandle
              updateKey={`${groupBy}-${dataUpdatedAt}`}
              section={{
                indexes: sectionIndexes,
                groupByField: groupBy,
                lengths: sectionLengths,
              }}
              data={data}
              columns={columns}
            />
          )}
        </ScrollBox>
      </VStack>
    );
  }
);
