import { useCallback } from "react";
import { useStore } from "store/RootStore";
import { useQuery } from "react-query";
import { DocumentService } from "./DocumentService";

export const useDocumentById = (id: string) => {
  const { documentStore } = useStore();
  const document = documentStore.byId[id];

  const updateDocument = useCallback(
    (payload) => {
      documentStore.update({
        id,
        ...payload,
      });
    },
    [documentStore, id]
  );

  return {
    document,
    updateDocument,
  };
};

export const useDocuments = () => {
  const { teamStore, documentStore } = useStore();

  const teamId = teamStore.selectedTeam!.id;
  const { data: documents = [], isLoading } = useQuery(
    ["docs", teamId],
    () => {
      return DocumentService.get({
        select: {
          id: true,
          title: true,
          createdAt: true,
          updatedAt: true,
        },
        where: {
          team: {
            id: teamId,
          },
        },
      });
    },
    {
      onSuccess: documentStore.checkForShouldUpdate,
    }
  );

  return { documents, isLoading };
};
