import { gql } from "@apollo/client";
import {
  useItemResource,
  useTeamItems,
  useTeamSingleItem,
} from "./useTeamQuery";

const GET_TEAM_FLOWS = gql`
  query TeamFlowsQuery($where: ZhuxinFlowWhereInput) {
    zhuxinFlows(where: $where) {
      id
      name
      triggerType
      triggerParams
      steps
      spaceId
      isActive
    }
  }
`;

const GET_TEAM_FLOW = gql`
  query GetTeamQuery($where: ZhuxinFlowWhereUniqueInput!) {
    zhuxinFlow(where: $where) {
      id
      name
      triggerType
      triggerParams
      steps
      spaceId
      isActive
    }
  }
`;

const CREATE_TEAM_FLOW = gql`
  mutation CreateOneZhuxinFlow($data: ZhuxinFlowCreateInput!) {
    createOneZhuxinFlow(data: $data) {
      id
      name
      triggerType
      triggerParams
      steps
      isActive
      spaceId
    }
  }
`;

const UPDATE_TEAM_FLOW = gql`
  mutation UpdateOneZhuxinFlow(
    $data: ZhuxinFlowUpdateInput!
    $where: ZhuxinFlowWhereUniqueInput!
  ) {
    updateOneZhuxinFlow(data: $data, where: $where) {
      id
      name
      triggerType
      triggerParams
      steps
      spaceId
      isActive
    }
  }
`;

const REMOVE_TEAM_FLOW = gql`
  mutation RemoveOneZhuxinFlow($where: ZhuxinFlowWhereUniqueInput!) {
    deleteOneZhuxinFlow(where: $where) {
      id
    }
  }
`;

export const useFlows = () => {
  return useTeamItems(GET_TEAM_FLOWS);
};

export const useFlowFromId = (id: string) => {
  return useTeamSingleItem(GET_TEAM_FLOW, id);
};

export const useFlowsResource = () => {
  return useItemResource({
    CREATE_QUERY: CREATE_TEAM_FLOW,
    UPDATE_QUERY: UPDATE_TEAM_FLOW,
    REMOVE_QUERY: REMOVE_TEAM_FLOW,
    REFTECH_QUERY: GET_TEAM_FLOWS,
  });
};
