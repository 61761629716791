import {
  Box,
  Center,
  Flex,
  IconButton,
  Spinner,
  Switch,
  Text,
} from "@chakra-ui/react";
import {
  GridView,
  StaticGridView,
  IGridViewData,
} from "@zhuxinai/frontend-core/components/GridView";
import { ListView } from "@zhuxinai/frontend-core/components/ListView";
import { IBlock, IBlockItem } from "@zhuxinai/frontend-core/components/ListView/const";
import { usePageFromId, usePageResource } from "graphql/useTeamPages";
import React, { ChangeEvent, useCallback, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useParams } from "react-router";
import { useDebounce } from "react-use";
import * as GPT from "util/gpt";

type Props = {
  page: {
    id: string;
    data: {
      blocks: IBlockItem[];
    };
    title: string;
    isPublic?: boolean;
  };
};

const SitePageDetailWithData: React.FC<Props> = ({ page }) => {
  const { update } = usePageResource();
  const [blocks, setBlocks] = useState(page.data.blocks || []);

  useDebounce(
    async () => {
      try {
        await update(page.id, {
          data: {
            blocks,
            pageType: "list",
          },
        });
      } catch {}
    },
    1000,
    [blocks]
  );

  const onGenerate = (block: IBlock) => {
    return GPT.fillTheBlock(block)
  }

  return (
    <Box w="full" h="full" position={"relative"}>
      <ListView onChange={setBlocks} blocks={blocks} onGenerate={onGenerate}/>
    </Box>
  );
};

export const SitePageDetail = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const { data = {}, loading } = usePageFromId(pageId);

  const { sundayPage = {} } = data;

  if (loading || !sundayPage) {
    return (
      <Center>
        <Spinner></Spinner>
      </Center>
    );
  }

  return <SitePageDetailWithData key={pageId} page={sundayPage} />;
};
