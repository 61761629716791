import { Box, Center, Flex, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { FileLoader } from "module/file/component/FileLoader";
import { FilePreview } from "module/file/component/FilePreview";
import { FileService } from "module/file/service";
import { IFile } from "module/file/store";
import { ISpaceView } from "module/sunday/space";
import { useCurrentSpace } from "module/sunday/space/hooks";
import React from "react";
import { useQuery } from "react-query";
import { FcEmptyTrash } from "react-icons/fc";
import { useCurrentTeam } from "module/sunday/team/hooks";
interface Props {
  onSelect: any;
}

export const FileListView = ({ onSelect }: Props) => {
  const { team } = useCurrentTeam();
  const { data: files, isLoading } = useQuery(["teamFileList"], () => {
    return FileService.get({
      select: {
        id: true,
        key: true,
      },
      where: {
        team: {
          id: team!.id,
        },
      },
    });
  });

  return (
    <Flex flexDir="column" w="100%" flex={1}>
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isLoading && (
        <>
          <Text>总共{files.length}个文件</Text>
          <Flex flexWrap="nowrap">
            {files.length > 0 &&
              files.map((file: IFile) => (
                <Box w="200px" onClick={() => onSelect(file)}>
                  <FileLoader id={file.id}>
                    <FilePreview id={file.id} />
                  </FileLoader>
                </Box>
              ))}
          </Flex>

          {files.length === 0 && (
            <Center flex={1} flexDir="column">
              <FcEmptyTrash fontSize="100px" />
              <Text mt="20px">当前面板没有文件</Text>
            </Center>
          )}
        </>
      )}
    </Flex>
  );
};
