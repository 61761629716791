import { Box } from "@chakra-ui/layout";
import { UserAvatar } from "component/UserAvatar";
import React, { useEffect, useState } from "react";
import { Stream, Client } from "urtc-sdk";

interface Props {
  stream: Stream;
  client: Client;
}

export const UrtcUser = ({ stream, client }: Props) => {
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    function handlePublished(stream: Stream): void {
      console.log("stream-published ", stream);
      client.play(
        {
          container: stream.sid,
          streamId: stream.sid,
          mute: true,
        },
        (err) => {
          if (err) {
            console.error("play publish stream ", err);
          }
        }
      );
    }

    function handleSubscribed(stream: Stream): void {
      console.log("stream-subscribed ", stream);
      client.play(
        {
          container: stream.sid,
          streamId: stream.sid,
          mute: false,
        },
        (err) => {
          if (err) {
            console.error("play subscribe stream ", err);
          }
        }
      );
    }

    function handleVolumnIndicator(indications: any[]) {
      const streamIndication = indications.find(
        (indication) => indication.sid === stream.sid
      );
      console.log(indications, streamIndication);
      if (streamIndication) {
        setVolume(streamIndication.volume);
      }
    }

    client.on("stream-subscribed", handleSubscribed);
    client.on("stream-published", handlePublished);
    client.on("volume-indicator", handleVolumnIndicator);

    return function () {
      client.off("stream-subscribed", handleSubscribed);
      client.off("stream-published", handlePublished);
      client.off("volume-indicator", handleVolumnIndicator);
    };
  }, [client, stream]);

  return (
    <Box width="48px" height="48px" margin="20px" id={stream.sid}>
      <Box className={volume > 5 ? "activeUrtcUser" : ""}>
        <UserAvatar id={stream.uid} />
      </Box>
    </Box>
  );
};
