import { Box, HStack, Center } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import { GroupByCell } from "component/SundayTable/GroupByCell";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { AutoSizer } from "react-virtualized";
import { SundayItemHeader } from "./SundayItemHeader";
import { SundayItemRow as SundayItemRowOriginal } from "./SundayItemRow";
import { VariableSizeList as List } from "react-window";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { FaGripVertical } from "react-icons/fa";

const SundayItemRow = SortableElement(SundayItemRowOriginal);

interface Props {
  data: any[];
  columns: any[];
  section?: any;
  updateKey?: string;
}

const ROW_HEIGHT = 65;
const HEADER_HEIGHT = 41;
const SECTION_GAP = 70;

export const SundayItemList = SortableContainer(
  ({ data, section = {}, columns, updateKey }: Props) => {
    const listRef = useRef<any>();
    const {
      indexes: sectionIndexes = [],
      groupByField,
      lengths: sectionLengths = [],
    } = section;
    const shouldRenderSection = !!section.groupByField;
    const rowRenderer = useCallback(
      ({ index, key, style }) => {
        if (!shouldRenderSection && index === 0) {
          return (
            <Box style={style} key={key} h={ROW_HEIGHT + HEADER_HEIGHT}>
              <SundayItemHeader columns={columns} />
              <SundayItemRow
                index={index}
                data={data[index]}
                columns={columns}
              />
            </Box>
          );
        } else if (shouldRenderSection && sectionIndexes.includes(index)) {
          const sectionDataLength =
            sectionLengths[sectionIndexes.indexOf(index)];
          return (
            <Box
              style={style}
              key={key}
              h={ROW_HEIGHT + HEADER_HEIGHT + SECTION_GAP}
              position="relative"
            >
              <HStack h={SECTION_GAP} py={"5px"} alignItems="center">
                <Box>
                  <GroupByCell
                    groupBy={groupByField!}
                    groupKey={data[index].order_field}
                  />
                </Box>
                <Box ml="10px">{sectionDataLength || 0} 任务</Box>
              </HStack>
              <SundayItemHeader columns={columns} />
              <SundayItemRow
                index={index}
                data={data[index]}
                columns={columns}
              />
            </Box>
          );
        } else {
          return (
            <Box style={style} key={key}>
              <SundayItemRow
                index={index}
                data={data[index]}
                columns={columns}
              />
            </Box>
          );
        }
      },
      [
        shouldRenderSection,
        sectionIndexes,
        columns,
        data,
        groupByField,
        sectionLengths,
      ]
    );

    const getRowHeight = useCallback(
      (index) => {
        if (!shouldRenderSection && index === 0) {
          return ROW_HEIGHT + HEADER_HEIGHT;
        } else if (shouldRenderSection && sectionIndexes.includes(index)) {
          return ROW_HEIGHT + HEADER_HEIGHT + SECTION_GAP;
        }
        if (index === data.length - 1) {
          return ROW_HEIGHT;
        } else {
          return ROW_HEIGHT;
        }
      },
      [shouldRenderSection, sectionIndexes, data.length]
    );

    const estimatedRowSize = useMemo(() => {
      return (
        data.length * ROW_HEIGHT +
        sectionIndexes.length * (HEADER_HEIGHT + SECTION_GAP)
      );
    }, [data.length, sectionIndexes.length]);

    useEffect(() => {
      listRef.current.resetAfterIndex(0);
    }, [updateKey]);

    return (
      <Box h="100%" minW="600px">
        {data.length === 0 && (
          <Center p="50px">
            <Text>没有找到相关数据</Text>
          </Center>
        )}
        <AutoSizer>
          {({ height, width }) => (
            <List
              overscanCount={20}
              ref={listRef}
              estimatedItemSize={estimatedRowSize}
              width={width}
              height={height}
              itemCount={data.length}
              itemSize={getRowHeight}
              itemData={data}
              itemKey={(index, data) => {
                return data[index].id;
              }}
            >
              {rowRenderer}
            </List>
          )}
        </AutoSizer>
      </Box>
    );
  }
);
