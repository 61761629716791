import React from "react";
import { FlowService } from "module/flow/service";
import { useQuery } from "react-query";
import {
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import { FlowExecutionItem } from "./FlowExecutionItem";

interface Props {
  flowId: string;
}

export const FlowExecution = ({ flowId }: Props) => {
  const { data = [] } = useQuery(["flowExcetuion", flowId], () => {
    return FlowService.getFlowsExecutions(flowId);
  });

  return (
    <Table flex={1} width="100%" variant="simple">
      <Thead>
        <Tr>
          <Th>ID</Th>
          <Th>Time</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((execution: any) => (
          <FlowExecutionItem execution={execution} key={execution.id} />
        ))}
      </Tbody>
    </Table>
  );
};
