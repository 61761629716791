import { IUser } from "const/type";
import { makeAutoObservable } from "mobx";
import { AuthService } from "../service/AuthService";
import { toast } from "util/toast";
import { API } from "api";
import { isWechatBrowser } from "util/wechat";

export class AuthStore {
  user: any | null = null;
  userInfo: IUser | null = null;
  token: any;
  authLoaded: boolean = false;
  constructor() {
    makeAutoObservable(this);
    this.loadToken();
    this.init();
  }

  init = async () => {
    try {
      let token: string = this.token;
      const hash = window.location.hash;
      if (hash) {
        const hashParams = new URLSearchParams(hash.slice(1));
        if (hashParams.get("token")) {
          token = hashParams.get("token") || "";
        }
      }
      if (token) {
        this.setToken(token);
        const { data } = await AuthService.getUser();
        this.user = data;
      }
    } catch {
      this.user = null;
    } finally {
      this.authLoaded = true;
    }
  };

  login = async ({ email, password }: { email: string; password: string }) => {
    const user = await AuthService.signIn({
      email,
      password,
    });
    if (user?.access_token) {
      this.setToken(user.access_token);
    }
    this.user = user;
    console.log(this.user);
  };

  googleLogin = async () => {
    await AuthService.signIn(
      {
        provider: "google",
      },
      {
        redirectUrl: window.location.origin,
      }
    );
  };

  wechatLogin = async (state: any = {}) => {
    await AuthService.signIn(
      {
        provider: isWechatBrowser() ? "wechat-client" : "wechat",
      },
      {
        state: JSON.stringify({
          ...state,
          redirectUrl: window.location.origin,
        }),
      }
    );
  };

  register = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    await AuthService.signUp({
      email,
      password,
    });
  };

  logout = async () => {
    await AuthService.signOut();
    this.user = null;
    this.removeToken();
  };

  loadToken = async () => {
    if (window.localStorage.getItem("sunday_token")) {
      this.token = window.localStorage.getItem("sunday_token");
    }
  };

  setToken = async (token: string) => {
    API.defaults.headers.common.Authorization = `Bearer ${token}`;
    window.localStorage.setItem("sunday_token", token);
    this.token = token;
  };

  removeToken = () => {
    window.localStorage.removeItem("sunday_token");
    this.token = null;
  };

  updateUserInfo = async (values: any) => {
    this.userInfo = {
      ...this.userInfo,
      ...values,
    };
    toast({
      title: "Profile Saved",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  get isLoggedIn(): boolean {
    return !!this.user;
  }
}

export const authStore = new AuthStore();
