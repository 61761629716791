import { makeAutoObservable } from "mobx";

export class UrtcStore {
  // enterSound = new Audio(`${process.env.PUBLIC_URL}/enter.mp3`);

  constructor() {
    makeAutoObservable(this);
  }

  playEnterSound = () => {
    // this.enterSound.play();
  };
}

export const urtcStore = new UrtcStore();
