import { Box } from "@chakra-ui/layout";
import { SundayEditor } from "component/SundayEditor";
import { Item } from "const/type";
import { debounce } from "lodash";
import { itemStore } from "module/sunday/item";
import { ICustomField } from "module/sunday/space";
import React, { useCallback } from "react";

interface Props {
  field: ICustomField;
  item: Item;
}

export const TextArea = ({ item, field }: Props) => {
  const save = useCallback(
    debounce((description) => {
      itemStore.saveItem({
        id: item.id,
        customData: {
          [field.key]: description,
        },
      });
    }, 2000),
    [item]
  );

  return (
    <Box width="100%">
      <SundayEditor
        value={item?.customData?.[field.key]}
        onChange={(value: any) => {
          save(value);
        }}
      />
    </Box>
  );
};
