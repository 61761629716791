import {
  Box,
  Portal,
  Input,
  HStack,
  Avatar,
  Text,
  AvatarProps,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { FieldModeEnum, ITask, Item, IUser } from "const/type";
import { ICustomField } from "module/sunday/space";
import { FaCalendar } from "react-icons/fa";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";

interface Props {
  value: any;
  onChange: any;
}

export const DateField = observer(({ value, onChange }: Props) => {
  const initRef = useRef<any>();
  const [focused, setFocused] = useState(false);

  return (
    <Popover isLazy closeOnBlur={false} initialFocusRef={initRef}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            {value ? (
              <Center cursor="pointer">
                <HStack>
                  <FaCalendar />
                  <Text>{moment(value).format("YYYY-MM-DD")}</Text>
                </HStack>
              </Center>
            ) : (
              <Center>
                <Center
                  cursor="pointer"
                  border="1px solid white"
                  borderStyle="dashed"
                  width="40px"
                  height="40px"
                  borderRadius="20px"
                >
                  <FaCalendar />
                </Center>
              </Center>
            )}
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody padding="0" h="300px">
                <DayPickerSingleDateController
                  hideKeyboardShortcutsPanel
                  date={moment(value)}
                  focused={focused}
                  onFocusChange={({ focused }) => setFocused(focused)}
                  onDateChange={(date) => {
                    onClose();
                    onChange(date);
                  }}
                  initialVisibleMonth={() => moment(value)}
                  numberOfMonths={1}
                />

                <Portal>
                  <Box
                    onClick={onClose}
                    position="fixed"
                    left="0"
                    top="0"
                    right="0"
                    bottom="0"
                  ></Box>
                </Portal>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
});
