import { API } from "api";
import { makeAutoObservable } from "mobx";

export class ImStore {
  isReady: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = async ({ uid, name }: { uid: string; name: string }) => {
    const { data } = await API.post("/im/token", {
      uid,
      name,
    });
    console.log(data);

    try {
      // await openIM.login({
      //   uid,
      //   token: data?.data.token,
      //   url: process.env.REACT_APP_IM_ENDPOINT || "",
      //   platformID: 5,
      // });
      console.log("IM Login");
    } catch (err) {
      console.log(err);
    }
  };
}

export const imStore = new ImStore();
