import { Box, Center, Flex } from "@chakra-ui/layout";
import React, { useCallback, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useStore } from "store/RootStore";
import arrayMove from "array-move";
import { SundayItemHeaderAddButton } from "./SundayItemHeaderAddButton";
import {
  useCurrentSpaceInfo,
  useCurrentSpaceView,
} from "module/sunday/space/hooks";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
interface Props {
  columns: any[];
}

const SortableColumn = SortableElement(({ column }: any) => {
  const { view, updateView } = useCurrentSpaceView();
  const { fieldsByKey } = useCurrentSpaceInfo();

  const updateSort = useCallback(() => {
    const newSortBy: any = {};
    if (["text", "textarea"].includes(fieldsByKey[column.key].type)) {
    } else {
      if (view?.sortBy?.key === column.key) {
        newSortBy.key = column.key;
        newSortBy.direction =
          view?.sortBy?.direction === "DESC" ? "ASC" : "DESC";
      } else {
        newSortBy.key = column.key;
        newSortBy.direction = "DESC";
      }
      updateView({
        sortBy: newSortBy,
      });
    }
  }, [view, column, updateView]);

  return (
    <Center
      cursor="grab"
      _hover={{
        background: "gray.700",
      }}
      flexGrow={1}
      minW={column.width || 100}
      w={column.width || 100}
      onClick={updateSort}
    >
      {column.Header}

      {view?.sortBy?.key === column.key ? (
        view?.sortBy?.direction === "DESC" ? (
          <BiDownArrow aria-label="sorted descending" />
        ) : (
          <BiUpArrow aria-label="sorted ascending" />
        )
      ) : null}
    </Center>
  );
});

export const SortableColumns = SortableContainer(({ items }: any) => {
  const existingFields = useMemo(() => {
    return items.map((item: any) => item.key);
  }, [items]);

  return (
    <Flex height={41} borderBottom="1px" borderColor="gray.700">
      {items.map((column: any, index: number) => (
        <SortableColumn key={`item-${index}`} index={index} column={column} />
      ))}
      <SundayItemHeaderAddButton existingFields={existingFields} />
    </Flex>
  );
});

export const SundayItemHeader = ({ columns }: Props) => {
  const { spaceStore } = useStore();
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const space = spaceStore.viewingSpace!;
      const view = spaceStore.viewingSpaceView!;
      const updatedColumns = [...view.columns];
      let tempCol: any = updatedColumns[oldIndex];
      updatedColumns[oldIndex] = updatedColumns[newIndex];
      updatedColumns[newIndex] = tempCol;
      tempCol = null;
      spaceStore.updateSpace({
        id: space.id,
        views: space.views.map((_view) => {
          if (view === _view) {
            return {
              ...view,
              columns: updatedColumns,
            };
          }
          return _view;
        }),
      });
    }
  };

  return (
    <SortableColumns
      lockAxis="x"
      axis="x"
      items={columns}
      onSortEnd={onSortEnd}
      pressDelay={100}
    />
  );
};
