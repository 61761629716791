import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import {
  Avatar,
  Box,
  Text,
  HStack,
  VStack,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { SundayTable } from "component/SundayTable";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { InviteService } from "module/sunday/invite/TeamService";
import { useIsMobile } from "hooks/useDevice";
import QRCode from "qrcode.react";
import { SundayTableBasic } from "component/SundayTable/SundayTableBasic";

interface Props {}

export const Members = observer((props: Props) => {
  const { teamStore } = useStore();
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  const columns: any[] = React.useMemo(
    () => [
      {
        Header: "",
        id: "avatar",
        Cell: (item: any) => {
          const member = item.row.original;
          return <Avatar name={member.username || member.email} />;
        },
      },
      {
        Header: formatMessage({ id: "label.name" }),
        Cell: (item: any) => {
          const member = item.row.original;
          return <Text>{member.username}</Text>;
        },
      },
      {
        Header: formatMessage({ id: "label.email" }),
        Cell: (item: any) => {
          const member = item.row.original;
          return member.email;
        },
      },
    ],
    [formatMessage]
  );

  const { data: inviteToken, isLoading } = useQuery(
    "inviteToken-query",
    () => {
      return InviteService.getInviteToken(teamStore.selectedTeam?.id!);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const link = `https://app.sundayteamwork.com/invite?inviteToken=${inviteToken}`;

  return (
    <Box padding={10}>
      <Text fontSize="24px">团队成员</Text>
      <SundayTableBasic data={teamStore.users} columns={columns} />
      <Box mt={"50px"}>
        <Text fontSize="24px">邀请好友</Text>
        {!isLoading && (
          <VStack w="100%">
            <HStack mt="20px" w="100%" alignItems="flex-start">
              <VStack alignItems="flex-start" flex={1}>
                <Text>1. 将此链接分享给伙伴</Text>
                <InputGroup size="sm">
                  <Input pr="4.5rem" value={link} />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm">
                      复制链接
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </VStack>
              {!isMobile && (
                <VStack flex={1}>
                  <Text>2. 将此二维码分享给伙伴扫描</Text>
                  <QRCode renderAs="canvas" size={150} value={link} />
                </VStack>
              )}
            </HStack>
          </VStack>
        )}
      </Box>
    </Box>
  );
});
