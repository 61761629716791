import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { BLOCKS, BLOCKS_MAP, IBlock, IBlockItem } from "../const";

type Props = {
  block: IBlockItem;
};

export const BlockItem = ({ block }: Props) => {
  const element = useMemo(() => {
    if (!!BLOCKS_MAP[block.type]?.component) {
      return React.createElement(
        BLOCKS_MAP[block.type]?.component,
        block.props
      );
    } else {
      return null;
    }
  }, [block]);

  return <Box>{element}</Box>;
};
