import { Box, Center, Flex, IconButton, Spinner, Switch, Text } from "@chakra-ui/react";
import {
  GridView,
  StaticGridView,
  IGridViewData,
} from "@zhuxinai/frontend-core/components/GridView";
import { usePageFromId, usePageResource } from "graphql/useTeamPages";
import React, { ChangeEvent, useCallback, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useParams } from "react-router";

type Props = {
  page: {
    id: string;
    data: IGridViewData;
    title: string;
    isPublic?: boolean;
  };
};

const PageDetailWithData: React.FC<Props> = ({ page }) => {
  const { update } = usePageResource();

  const [reloading, setReloading] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);

  const onChange = useCallback(
    async (values) => {
      try {
        await update(page.id, {
          data: {
            ...values,
            pageType: "grid"
          },
        });
      } catch {}
    },
    [update, page]
  );

  const onChangeTitle = useCallback(
    async (title) => {
      await update(page.id, {
        title: {
          set: title,
        },
      });
    },
    [page, update]
  );

  const onChangePublic = async (event: ChangeEvent<HTMLInputElement>) => {
    await update(page.id, {
      isPublic: {
        set: !!event.target.checked
      },
    });
  };

  const triggerReload = () => {
    setReloading(true);
    setTimeout(() => {
      setReloading(false)
    }, 200)
  }

  if (reloading) {
    return <Center h={'full'}>
      <Spinner></Spinner>
    </Center>
  }

  return (
    <Box w="full" h="full" position={'relative'}>
      {/* <Flex alignItems={'center'} position={'absolute'} left='20px' top="20px">
        <Flex alignItems={'ceneter'} >
          <Text marginRight={'10px'}>是否公开页面</Text>
          <Switch onChange={onChangePublic} isChecked={!!page.isPublic} />
        </Flex>

        {page.isPublic && <IconButton marginLeft={'20px'} aria-label="preview" onClick={() => {
          window.open(`${process.env.REACT_APP_SUNDAY_PAGE_PREVIEW_BASE}/${page.id}`)
        }} icon={<FaEye size={24}/>}/>}
      </Flex> */}
      {!isEditMode ? (
        <StaticGridView
          onEdit={() => {
            setIsEditMode(true);
          }}
          data={page.data || {}}
        />
      ) : (
        <GridView
          // title={page.title}
          data={page.data || {}}
          // onChangeTitle={onChangeTitle}
          onChange={onChange}
          onExit={() => {
            setIsEditMode(false);
            triggerReload();
          }}
        />
      )}
    </Box>
  );
};

export const PageDetail = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const { data = {}, loading } = usePageFromId(pageId);

  const { sundayPage = {} } = data;

  if (loading || !sundayPage) {
    return (
      <Center>
        <Spinner></Spinner>
      </Center>
    );
  }

  return <PageDetailWithData key={pageId} page={sundayPage} />;
};
