import { Button } from "@chakra-ui/button";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  LightMode,
  useColorModeValue as mode,
  chakra,
  Spinner,
} from "@chakra-ui/react";
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/layout";
import { Logo } from "component/Logo";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaWeixin } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useStore } from "store/RootStore";
import { UnderlineLink } from "component/UnderlineLink";
import { useLocationQuery } from "hooks/useLocationQuery";
import { InviteService } from "module/sunday/invite/TeamService";
import { useHistory } from "react-router";

interface Props {}

export const Invite = (props: Props) => {
  const { authStore } = useStore();
  const { formatMessage } = useIntl();
  const query: any = useLocationQuery();
  const inviteToken = query.get("inviteToken");
  const history = useHistory();

  console.log(inviteToken);

  const [error, setError] = useState<string | undefined>(undefined);

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async ({ email, password }: any) => {
    setError(undefined);
    try {
    } catch (err: any) {
      setError(err?.message || "Unkown Error");
    }
  };

  const onWechatLogin = async () => {
    await authStore.wechatLogin({
      inviteToken,
    });
  };

  const { data: invitation, isLoading } = useQuery(
    "invitation",
    () => {
      return InviteService.getInviteInfoFromToken(inviteToken);
    },
    {
      enabled: !!inviteToken,
    }
  );

  //   useEffect(() => {
  //     if (!inviteToken) {
  //       history.push("/");
  //     }
  //   }, [inviteToken, history]);

  return (
    <Center flexDir="column" h="100vh" w="100%">
      <Box position="absolute" top="20px" left="20px">
        <Logo w="50px" />
      </Box>
      <Center padding="20px" flexDir="column" maxW="100%" width="500px">
        <Heading
          mb="10"
          as="h1"
          size="xl"
          fontWeight="extrabold"
          letterSpacing="tight"
        >
          <FormattedMessage
            id="invite.title"
            defaultMessage="You got an invitation"
          />
        </Heading>

        {isLoading && (
          <Center>
            <Spinner />
          </Center>
        )}

        {!isLoading && invitation && (
          <Box>
            <Box mb="10" textAlign="center">
              <FormattedMessage
                id="invite.detail"
                defaultMessage="{from}正在邀请你加入他们在Sunday的{team}团队 "
                values={{
                  from: <b>{invitation.user.username}</b>,
                  team: (
                    <b style={{ textDecoration: "underline" }}>
                      {invitation.team.name}
                    </b>
                  ),
                }}
              />
            </Box>
          </Box>
        )}

        <Button
          onClick={onWechatLogin}
          fontSize="sm"
          fontWeight="bold"
          size="lg"
          leftIcon={<FaWeixin fontSize="18px" />}
          iconSpacing="3"
          colorScheme="green"
          width="full"
        >
          <FormattedMessage
            id="invite.wechat"
            defaultMessage="Use wechat to login"
          />
        </Button>
      </Center>

      <HStack my="8">
        <Divider />
        <Text
          px="3"
          textTransform="uppercase"
          fontSize="sm"
          fontWeight="semibold"
          color={mode("gray.600", "gray.200")}
        >
          <FormattedMessage id="label.or" defaultMessage="or" />
        </Text>
        <Divider />
      </HStack>

      <chakra.form
        width="100%"
        padding="20px"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing="-px">
          <FormControl id="email-address">
            <FormLabel srOnly>
              <FormattedMessage
                id="label.email.address"
                defaultMessage="Email address"
              />
            </FormLabel>
            <Input
              size="lg"
              type="email"
              autoComplete="email"
              required
              placeholder={formatMessage({ id: "label.email.address" })}
              bg={mode("white", "gray.700")}
              fontSize="md"
              roundedBottom="0"
              {...register("email", {
                required: "This is required",
              })}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel srOnly>
              <FormattedMessage id="label.password" defaultMessage="Password" />
            </FormLabel>
            <Input
              type="password"
              autoComplete="current-password"
              required
              size="lg"
              bg={mode("white", "gray.700")}
              fontSize="md"
              roundedTop="0"
              placeholder={formatMessage({ id: "label.password" })}
              {...register("password", {
                required: "This is required",
              })}
            />
          </FormControl>
        </Stack>

        {error && (
          <Text textAlign="center" mt={5} color="red.600">
            {error}
          </Text>
        )}

        <LightMode>
          <Button
            disabled={isSubmitting}
            size="lg"
            type="submit"
            mt="8"
            w="full"
            colorScheme="blue"
            fontSize="md"
            fontWeight="bold"
          >
            <FormattedMessage id="label.signup" defaultMessage="Sign up" />
          </Button>
        </LightMode>
      </chakra.form>
    </Center>
  );
};
