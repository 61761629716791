import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { BiChevronRight, BiChevronDown, BiCreditCard } from "react-icons/bi";
import { useHistory, useRouteMatch } from "react-router";
import { ISpace } from "module/sunday/space";
import { StopPropagation } from "component/StopPropagation";
import { MdMoreHoriz } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useStore } from "store/RootStore";

interface TablesSidebarItemProps {
  href?: string;
  space: ISpace;
}

const TablesSidebarItem = observer((props: TablesSidebarItemProps) => {
  const { href = "", space } = props;
  const { spaceStore } = useStore();
  const match = useRouteMatch(href);
  const history = useHistory();

  const [selectedViewKey, setSelctedViewKey] = React.useState<string>();

  const { views = [] } = space;
  const active = !!href && match;

  const handleClick = React.useCallback(() => {
    history.push(href);
    if (!selectedViewKey) {
      setSelctedViewKey(space?.views?.[0]?.key);
    }
  }, [history, href, selectedViewKey, space?.views]);

  React.useEffect(() => {
    setSelctedViewKey(space?.views?.[0]?.key);
  }, [spaceStore.viewingSpace, space?.views]);

  return (
    <Box onClick={handleClick} width="full">
      <HStack
        w="full"
        px="3"
        py="2"
        cursor="pointer"
        userSelect="none"
        rounded="md"
        transition="all 0.2s"
        bg={active ? "gray.700" : undefined}
        _hover={{ bg: "gray.700" }}
        _active={{ bg: "gray.600" }}
      >
        <Box fontSize="lg" color={active ? "currentcolor" : "gray.400"}>
          {active ? <BiChevronDown /> : <BiChevronRight />}
        </Box>
        <Box flex="1" fontWeight="inherit" color={undefined}>
          {space.name}
        </Box>
        <Box>
          <StopPropagation>
            <Menu isLazy>
              <MenuButton as={Box}>
                <MdMoreHoriz />
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    spaceStore.setEditingSpace(space);
                  }}
                >
                  <FormattedMessage id="label.edit" defaultMessage="Edit" />
                </MenuItem>
              </MenuList>
            </Menu>
          </StopPropagation>
        </Box>
      </HStack>
      {active && (
        <VStack py="2">
          {views.length > 0 ? (
            <VStack w="full" paddingLeft="4">
              {views.map((view, index) => (
                <HStack
                  key={view.key}
                  w="full"
                  py="2"
                  px="4"
                  cursor="pointer"
                  userSelect="none"
                  rounded="md"
                  transition="all 0.2s"
                  bg={selectedViewKey === view.key ? "gray.700" : undefined}
                  _hover={{ bg: "gray.800" }}
                  _active={{ bg: "gray.600" }}
                  onClick={() => {
                    setSelctedViewKey(view.key);
                    spaceStore.viewSpaceView(index);
                  }}
                >
                  <Box>
                    <BiCreditCard />
                  </Box>
                  <Text fontSize="8px">{view.label}</Text>
                </HStack>
              ))}
            </VStack>
          ) : (
            <Text>No table views found.</Text>
          )}
        </VStack>
      )}
    </Box>
  );
});

export default TablesSidebarItem;
