import * as React from "react";
import {
  Box,
  Flex,
  Stack,
  Center,
  // Menu,
  // MenuList,
  // MenuItem,
  // MenuButton,
  // Button,
  // HStack,
  // Text,
} from "@chakra-ui/react";
import {
  BiCog,
  // BiCreditCard,
  // BiHome,
  // BiPlus,
  // BiChalkboard,
  // BiFile,
  // BiClipboard,
} from "react-icons/bi";
// import { RiFileList3Line } from "react-icons/ri";
// import { MdMoreHoriz } from "react-icons/md";
// import { AccountSwitcher } from "component/AccountSwitcher";
// import { NavGroup } from "component/NavGroup";
import { NavItem } from "component/NavItem";
import { useStore } from "store/RootStore";
import { observer } from "mobx-react-lite";
import { UrtcChannel } from "module/urtc";
// import { StopPropagation } from "component/StopPropagation";
import { useIntl } from "react-intl";
// import { PagesList } from "component/PagesList";
// import { useDocuments } from "module/sunday/document";
import { useHistory } from "react-router";
// import { SidebarList } from "component/SidebarList";
// import { useTeamBoards } from "graphql/useTeamBoards";
import TablesSidebar from "./TablesSidebar/TablesSidebar";
import PagesSidebar from "./PagesSidebar/PagesSidebar";

interface Props {
  match: any;
}

export const Sidebar: React.FunctionComponent<Props> = observer(({ match }) => {
  const history = useHistory();
  const { authStore } = useStore();
  const { formatMessage } = useIntl();
  // const { documents, isLoading: isLoadingDocuments } = useDocuments();
  // const { data: boardsData, loading: isLoadingBoards } = useTeamBoards();

  // const [isDocsMenuOpen, setIsDocsMenuOpen] = React.useState(false);
  // const [selectedDocId, setSelectedDocId] = React.useState<string | null>(null);

  // const [isBoardMenuOpen, setIsBoardMenuOpen] = React.useState(false);
  // const [selectedBoardId, setSelectedBoardId] = React.useState<string | null>(
  //   null
  // );

  // const handleToggleDocsMenu = () => {
  //   setIsDocsMenuOpen(!isDocsMenuOpen);
  // };

  // const handleToggleBoardMenu = () => {
  //   setIsBoardMenuOpen(!isBoardMenuOpen);
  // };

  // const handleDocumentSelect = React.useCallback(
  //   (id: string) => {
  //     setSelectedDocId(id);
  //     setSelectedBoardId(null);
  //     history.push(`${match!.url}/docs/${id}`);
  //   },
  //   [history, match]
  // );

  // const handleBoardSelect = React.useCallback(
  //   (id: string) => {
  //     setSelectedBoardId(id);
  //     setSelectedDocId(null);
  //     history.push(`${match!.url}/boards/${id}`);
  //   },
  //   [history, match]
  // );

  const isTablesTabSelected = React.useMemo(() => {
    return history.location.pathname.includes("spaces");
  }, [history.location.pathname]);

  const isPagesTabSelected = React.useMemo(() => {
    return history.location.pathname.includes("pages");
  }, [history.location.pathname]);

  const isWorkflowsTabSelected = React.useMemo(() => {
    return history.location.pathname.includes("workflows");
  }, [history.location.pathname]);

  return (
    <Box
      h="full"
      w={{ base: "72", xs: "100%" }}
      bg="gray.900"
      color="white"
      fontSize="sm"
    >
      <Flex h="full" direction="column" px="4" py="4">
        <Stack spacing="8" flex="1" overflow="auto" pt="8">
          {isTablesTabSelected && <TablesSidebar match={match} />}
          {isPagesTabSelected && <PagesSidebar match={match} />}
        </Stack>
        <Box>
          {authStore.user?.id && (
            <Center my="15px">
              <UrtcChannel
                roomId={"zhuxin"}
                userId={authStore.user?.id!.toString()}
              />
            </Center>
          )}
          <Stack spacing="1">
            <NavItem
              href={`${match!.url}/settings`}
              subtle
              icon={<BiCog />}
              label={formatMessage({ id: "dashboard.label.settings" })}
            />
            {/* <NavItem
              href="/dashboard/help"
              subtle
              icon={<BiBuoy />}
              label={formatMessage({
                id: "dashboard.label.help.support",
              })}
              endElement={<Circle size="2" bg="blue.400" />}
            /> */}
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
});
