import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useStore } from "store/RootStore";
import { AccountSwitcherButton } from "./AccountSwitcherButton";

export const AccountSwitcher = () => {
  const { authStore, teamStore } = useStore();
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <Menu>
      <AccountSwitcherButton />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue("gray.600", "gray.200")}
        px="3"
      >
        <Text
          maxWidth="230px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          fontWeight="medium"
          mb="2"
        >
          {authStore.user?.email}
        </Text>
        <MenuDivider />
        <MenuOptionGroup value={`${teamStore.selectedTeam?.id}`}>
          {teamStore.teams.map((team) => (
            <MenuItemOption
              key={team.id}
              onClick={() => {
                teamStore.setSelectedTeam(team);
                history.push(`/dashboard/team/${team.id}`);
              }}
              value={`${team.id}`}
              fontWeight="semibold"
              rounded="md"
            >
              {team.name}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        <MenuDivider />
        {/* <MenuItem rounded="md">Workspace settings</MenuItem>
        <MenuItem rounded="md">Add an account</MenuItem>
        <MenuDivider /> */}

        <MenuItem onClick={() => {
          history.push(`${match!.url}/settings`)
        }} rounded="md">
          设置
        </MenuItem>

        <MenuDivider />
        <MenuItem onClick={() => {
          authStore.logout();
          setTimeout(() => {
            history.push("/auth/signin");
          }, 100)
        }} rounded="md">
          登出
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
