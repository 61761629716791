import React from "react";
import { FlowControllerProps } from "./const";
import { useController, useFormContext } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import { JsonEditor as Editor } from "jsoneditor-react";
// import AceEditor from "react-ace";
// import "ace-builds/src-noconflict/mode-json";
// import "ace-builds/src-noconflict/theme-github";

export const Json: React.FunctionComponent<FlowControllerProps> = ({
  fieldName,
  field,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
  });

  return (
    <Box h="200px">
      <Editor
        value={value || field.defaultValue || {}}
        onChange={(data: any) => {
          onChange(data);
        }}
        mode="code"
      />
      {/* <AceEditor
        mode="json"
        theme="github"
        onChange={(data: any) => {
          onChange(data);
        }}
        value={value}
        name={fieldName}
        editorProps={{ $blockScrolling: true }}
      /> */}
    </Box>
  );
};
