import {
  Box,
  Portal,
  Input,
  HStack,
  Avatar,
  Text,
  AvatarProps,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { FieldModeEnum, ITask, Item, IUser } from "const/type";
import { ICustomField } from "module/sunday/space";
import { AssignField } from "./AssignField";

interface Props {
  field: ICustomField;
  item: Item;
  mode?: FieldModeEnum;
}

export const Assign = observer(({ field, item }: Props) => {
  const { teamStore, itemStore } = useStore();
  const assignValue = item.customData?.[field.key];

  return (
    <AssignField
      value={assignValue}
      onChange={(value: string) => {
        itemStore.saveItem({
          id: item.id,
          customData: {
            [field.key]: value,
          },
        });
      }}
    />
  );
});
