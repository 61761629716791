import React, { forwardRef, useImperativeHandle } from "react";
import StarterKit from "@tiptap/starter-kit";
import {
  useEditor,
  EditorContent,
  BubbleMenu,
  FloatingMenu,
  EditorContentProps,
} from "@tiptap/react";
import { EditorOptions } from "@tiptap/core";
import { Box, BoxProps } from "@chakra-ui/react";
import Underline from "@tiptap/extension-underline";
import { useSundayEditor } from "./hooks";
import { SundayEditorMenu } from "./index";
import "./SundayEditor.css";
interface Props {
  value: string;
  onChange: any;
  onFocus?: any;
  onBlur?: any;
  boxProps?: BoxProps;
  editorContentProps?: Partial<EditorContentProps>;
  extensions?: EditorOptions["extensions"];
  placeholder?: string;
}

export const SundayEditor = forwardRef(
  (
    {
      value,
      onChange,
      onFocus,
      onBlur,
      boxProps = {},
      editorContentProps = {},
      extensions = [],
      placeholder,
    }: Props,
    ref
  ) => {
    const editor = useSundayEditor(
      {
        content: value,
        onUpdate: ({ editor }) => {
          onChange && onChange(editor.getHTML());
        },
        extensions,
      },
      {
        placeholder,
      }
    );

    useImperativeHandle(ref, () => ({
      addImage: (url: string) => {
        editor?.chain().focus().setImage({ src: url }).run();
      },
    }));

    return (
      <>
        <SundayEditorMenu editor={editor} />
        <Box
          flex={1}
          boderColor="transparent"
          borderWidth="1px"
          borderRadius="10px"
          padding="20px"
          {...boxProps}
        >
          <EditorContent
            {...(editorContentProps as any)}
            onFocus={onFocus}
            onBlur={onBlur}
            editor={editor}
          />
        </Box>
      </>
    );
  }
);
