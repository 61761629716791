import { ISpace } from "module/sunday/space";

export interface IKanbanColumn {
  name: string;
  tasks: string[];
}

export interface IKanban {
  id: string;
  board: IKanbanColumn[];
}

export enum ITaskStatus {
  OPEN = "OPEN",
  STARTED = "STARTED",
  COMPLETE = "COMPLETE",
  OVERDUE = "OVERDUE",
}

export const TaskStatusArray = [
  { text: "Complete", value: "COMPLETE" },
  { text: "Open", value: "OPEN" },
  { text: "Overdue", value: "OVERDUE" },
  { text: "Started", value: "STARTED" },
];

export enum ITaskActionType {
  CREATE = "CREATE",
  VALUE_CHANGE = "VALUE_CHANGE",
}

export interface ITaskAction {
  type?: string;
  data?: any;
  user?: IUser;
  created_at?: Date;
}

export interface ITaskHash {
  [key: string]: ITask;
}

export interface ITask {
  id: string;
  subject?: string;
  description?: string;
  room?: string;
  assignedTo?: string;
  createdBy?: string;
  department?: string;
  status?: ITaskStatus;
  hotelId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  task_action?: Array<ITaskAction>;
  priority?: string;
  customData?: any;
  spaceKey?: string;
}

export interface Item {
  id: string;
  customData?: any;
  createdAt: Date;
  updatedAt: Date;
  actions: any[];
  space?: ISpace;
  spaceId?: string;
  attachments?: { id: string }[];
}

export interface IUser {
  id: string;
  auth_id: string;
  email: string;
  username: string;
}

export enum FieldModeEnum {
  TABLE = "TABLE",
  MODAL = "MODAL",
  CREATE = "CREATE",
}
