import { Box, Center, HStack, Text, VStack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router";
import { useStore } from "store/RootStore";
import { SpaceView } from "./SpaceView";
import { FormattedMessage } from "react-intl";
import { SpaceHeader } from "./SpaceHeader";
import { SpaceToolbar } from "./SpaceToolbar";
import { SpaceAutomation } from "./SpaceAutomation";
import { SpaceContext } from "module/sunday/space/context";

interface Props {
  spaceId?: string;
}

export const SpaceDetail = observer(({ spaceId: spaceIdFromProps }: Props) => {
  const { spaceStore } = useStore();
  const { spaceId } = useParams<{ spaceId: string }>();
  const space = spaceStore.spacesById[spaceIdFromProps || spaceId]!;

  useEffect(() => {
    if (space) {
      spaceStore.viewSpace(space.id);
    }
  }, [space, spaceStore]);

  if (!space) {
    return (
      <Center flexDirection="column" height="100vh">
        <Spinner />
        <Text>
          <FormattedMessage id="label.loading" defaultMessage="Loading..." />
        </Text>
      </Center>
    );
  }

  return (
    <SpaceContext.Provider value={space}>
      <VStack
        h="100%"
        key={spaceId}
        alignItems="flex-start"
        flex={1}
        padding="20px"
      >
        <SpaceHeader />
        {/* <SpaceToolbar /> */}
        {spaceStore.viewingSpaceView && (
          <SpaceView
            key={spaceStore.viewingSpaceView!.key}
            view={spaceStore.viewingSpaceView!}
          />
        )}

        <SpaceAutomation />
      </VStack>
    </SpaceContext.Provider>
  );
});
