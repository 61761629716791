import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Center, Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { chakra } from "@chakra-ui/react";
import { Logo } from "component/Logo";
import { TeamService } from "module/sunday/team";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useStore } from "store/RootStore";

interface Props {}

export const NewTeam = (props: Props) => {
  const { handleSubmit, control, formState } = useForm();
  const { teamStore } = useStore();

  const onSubmit = async (form: any) => {
    await teamStore.create({
      name: form.name,
    });
  };

  return (
    <Center flexDir="column" w="100%" h="100%">
      <Box
        fontSize={{ base: "20px", md: "30px" }}
        textAlign="center"
        mb={{ base: "30px", md: "50px" }}
      >
        <Text>欢迎使用Sunday</Text>
        <Text>还差最后几步</Text>
      </Box>

      <Flex
        width="100%"
        flexDir={{ base: "column", md: "row" }}
        justifyContent="center"
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Logo
          mb={{ base: "20px", md: "0" }}
          width="128px"
          mr={{ base: "0px", md: "20px" }}
        />
        <chakra.form marginInlineStart="0" onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <Controller
              rules={{ required: true }}
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <FormControl>
                  <FormLabel
                    textAlign={{ base: "center", md: "left" }}
                    fontSize="x-large"
                  >
                    输入您的团队名字
                  </FormLabel>
                  <Input
                    size="lg"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                </FormControl>
              )}
            />
          </VStack>

          <Button w={{ base: "100%", md: "auto" }} type="submit" mt="50px">
            开始！
          </Button>
        </chakra.form>
      </Flex>
      {formState.isSubmitting && (
        <Center
          position="fixed"
          w="100%"
          h="100%"
          top="0"
          left="0"
          bg="rgba(0,0,0,0.9)"
          flexDir="column"
        >
          <Spinner size="lg" mb="20px" />
          <Text>正在创建您的团队</Text>
        </Center>
      )}
    </Center>
  );
};
