export const Cron = {
  key: "cron",
  label: "Cron",
  fields: [
    // {
    //   key: "interval",
    //   type: "selector",
    //   label: "Cron Interval",
    //   options: [
    //     {
    //       label: "Every 30 mins",
    //       value: 30 * 60 * 1000,
    //     },
    //     {
    //       label: "Every Day",
    //       value: 24 * 60 * 60 * 1000,
    //     },
    //   ],
    // },
    {
      key: "hours",
      type: "json",
      label: "Hours To Run",
      defaultValue: [23],
    },
  ],
};
