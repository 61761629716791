import { Avatar, AvatarProps } from "@chakra-ui/avatar";
import { Box, Center } from "@chakra-ui/layout";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "store/RootStore";

interface Props {
  id: string;
  avatarProps?: AvatarProps;
}

export const UserAvatar = observer(({ id, avatarProps }: Props) => {
  const { teamStore } = useStore();
  const { usersById } = teamStore;
  return (
    <>
      {usersById[id] ? (
        <Center>
          <Avatar
            {...avatarProps}
            cursor="pointer"
            name={usersById[id].username || usersById[id].email}
          />
        </Center>
      ) : (
        <Box
          cursor="pointer"
          border="1px solid black"
          width="40px"
          height="40px"
          borderRadius="20px"
        ></Box>
      )}
    </>
  );
});
