import { API } from "api";
import { SundayService } from "module/sunday/service";

export class FlowServiceClass extends SundayService {
  getFlowsInSpace = async (id: string) => {
    const { data } = await API.get(`/sunday-spaces/${id}/flows`);
    return data;
  };

  getFlowsExecutions = async (id: string) => {
    const { data } = await API.get(`/zhuxin-flow/${id}/executions`);
    return data;
  };
}

export const FlowService = new FlowServiceClass("zhuxin-flow");
