import { Box } from "@chakra-ui/layout";
import { BubbleMenu, Editor } from "@tiptap/react";
import React from "react";
import { IconButton } from "@chakra-ui/react";
import {
  FaBold,
  FaCode,
  FaItalic,
  FaStrikethrough,
  FaUnderline,
} from "react-icons/fa";

interface Props {
  editor: Editor | null;
}

export const SundayEditorMenu = ({ editor: editorFromProps }: Props) => {
  if (!editorFromProps) {
    return null;
  }

  const editor = editorFromProps!;

  return (
    <BubbleMenu tippyOptions={{ duration: 100 }} editor={editor}>
      <Box
        display="flex"
        background="#30353c"
        padding="5px 8px"
        borderRadius="5px"
        borderWidth="1px"
      >
        <IconButton
          variant="solid"
          aria-label="bold"
          icon={<FaBold />}
          background="transparent"
          onClick={() => editor.chain().focus().toggleBold().run()}
          isActive={editor.isActive("bold")}
        />

        <IconButton
          aria-label="italic"
          icon={<FaItalic />}
          background="transparent"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          isActive={editor.isActive("italic")}
        />

        <IconButton
          aria-label="strike"
          icon={<FaStrikethrough />}
          background="transparent"
          isActive={editor.isActive("strike")}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        />

        <IconButton
          aria-label="strike"
          icon={<FaUnderline />}
          background="transparent"
          isActive={editor.isActive("underline")}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
        />

        <IconButton
          aria-label="code"
          icon={<FaCode />}
          background="transparent"
          isActive={editor.isActive("code")}
          onClick={() => editor.chain().focus().toggleCode().run()}
        />
      </Box>
    </BubbleMenu>
  );
};
