import { Editable, EditableInput, EditablePreview } from "@chakra-ui/editable";
import { FieldModeEnum, Item } from "const/type";
import React, { useCallback, useState } from "react";
import { useStore } from "store/RootStore";
import { ICustomField } from "module/sunday/space";
import { debounce } from "lodash";
import { Input } from "@chakra-ui/input";
import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import { Text as ChakraText } from "@chakra-ui/react";
import { useDebounce } from "react-use";

interface Props {
  field: ICustomField;
  item: Item;
  mode?: FieldModeEnum;
}

export const Text = ({ field, item, mode = FieldModeEnum.TABLE }: Props) => {
  const { itemStore } = useStore();
  const value = item.customData?.[field.key];
  const [tempValue, setTempValue] = useState(value);

  useDebounce(
    () => {
      if (tempValue !== value) {
        itemStore.saveItem({
          id: item.id,
          customData: {
            [field.key]: tempValue,
          },
        });
      }
    },
    1000,
    [tempValue]
  );

  if (mode === FieldModeEnum.TABLE) {
    return (
      <Box h="full" overflowY="auto">
        <Editable
          w="100%"
          h="full"
          placeholder="Enter value"
          onSubmit={(nextValue) => {
            if (nextValue !== value) {
              itemStore.saveItem({
                id: item.id,
                customData: {
                  [field.key]: nextValue,
                },
              });
            }
          }}
          justifyContent="center"
          display="flex"
          flexDir="column"
          defaultValue={value || ""}
        >
          <EditablePreview
            w="100%"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            alignSelf="center"
          />
          <EditableInput />
        </Editable>
      </Box>
    );
  } else if (mode === FieldModeEnum.MODAL) {
    return (
      <VStack w="100%" alignItems="flex-start">
        <ChakraText>{field.label}</ChakraText>
        <Textarea
          resize="none"
          fontSize="18px"
          focusBorderColor="none"
          value={tempValue}
          onChange={(event) => {
            setTempValue(event.target.value);
          }}
        />
      </VStack>
    );
  } else {
    return null;
  }
};
