import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { HiCloudUpload } from "react-icons/hi";
import { FaGithub, FaGoogle } from "react-icons/fa";
import { FieldGroup } from "component/FieldGroup";
import { useStore } from "store/RootStore";
import { useForm } from "react-hook-form";

export const AccountSettings = () => {
  const { authStore } = useStore();

  const {
    handleSubmit,
    register,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      username: authStore.userInfo?.username,
      email: authStore.userInfo?.email,
    },
  });
  const textColor = useColorModeValue("gray.500", "whiteAlpha.600");
  const onSubmit = (values: any) => {
    const updatingValues = Object.keys(values).reduce((acc: any, key: any) => {
      if ((dirtyFields as any)[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    authStore.updateUserInfo(updatingValues);
  };

  return (
    <Box px={{ base: "4", md: "10" }} py="16" maxWidth="3xl" mx="auto">
      <form id="settings-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="4" divider={<StackDivider />}>
          <Heading size="lg" as="h1" paddingBottom="4">
            账户设置
          </Heading>
          <FieldGroup title="用户信息">
            <VStack width="full" spacing="6">
              <FormControl id="username">
                <FormLabel>用户名</FormLabel>
                <Input {...register("username")} type="text" maxLength={255} />
              </FormControl>

              <FormControl id="email">
                <FormLabel>邮箱地址</FormLabel>
                <Input type="email" {...register("email")} isReadOnly />
              </FormControl>

              {/* <FormControl id="bio">
                <FormLabel>Bio</FormLabel>
                <Textarea rows={5} />
                <FormHelperText>
                  Brief description for your profile. URLs are hyperlinked.
                </FormHelperText>
              </FormControl> */}
            </VStack>
          </FieldGroup>
          {false && (
            <FieldGroup title="Profile Photo">
              <Stack direction="row" spacing="6" align="center" width="full">
                <Avatar
                  size="xl"
                  name="Alyssa Mall"
                  src="https://images.unsplash.com/photo-1488282396544-0212eea56a21?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                />
                <Box>
                  <HStack spacing="5">
                    <Button leftIcon={<HiCloudUpload />}>更新头像</Button>
                    <Button variant="ghost" colorScheme="red">
                      删除
                    </Button>
                  </HStack>
                  <Text fontSize="sm" mt="3" color={textColor}>
                    .jpg, .gif, or .png. Max file size 700K.
                  </Text>
                </Box>
              </Stack>
            </FieldGroup>
          )}
          {/* <FieldGroup title="Language">
          <VStack width="full" spacing="6">
            <LanguageSelect />
            <CurrencySelect />
          </VStack>
        </FieldGroup> */}
          {false && (
            <FieldGroup title="Notifications">
              <Stack width="full" spacing="4">
                <Checkbox>Get updates about the latest meetups.</Checkbox>
                <Checkbox>
                  Get notifications about your account activities
                </Checkbox>
              </Stack>
            </FieldGroup>
          )}
          {false && (
            <FieldGroup title="Connect accounts">
              <HStack width="full">
                <Button variant="outline" leftIcon={<FaGithub />}>
                  Connect Github
                </Button>
                <Button
                  variant="outline"
                  leftIcon={<Box as={FaGoogle} color="red.400" />}
                >
                  Connect Google
                </Button>
              </HStack>
            </FieldGroup>
          )}
        </Stack>
        <FieldGroup mt="8">
          <HStack width="full">
            <Button disabled={!isDirty} type="submit" colorScheme="blue">
              保存
            </Button>
            {/* <Button variant="outline">Cancel</Button> */}
          </HStack>
        </FieldGroup>
      </form>
    </Box>
  );
};
