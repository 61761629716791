import {
  Box,
  Center,
  CenterProps,
  Flex,
  HStack,
  Text,
} from "@chakra-ui/layout";
import { observer } from "mobx-react-lite";
import { ISpace } from "module/sunday/space";
import { useSpaceInfo } from "module/sunday/space/hooks";
import React, { useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useStore } from "store/RootStore";
import { useFieldArray, useForm } from "react-hook-form";

interface Props {
  space: ISpace;
}

const FieldLabel = ({
  item,
  label,
  index,
  containerProps = {},
}: {
  item: any;
  index: number;
  label: string;
  containerProps?: CenterProps;
}) => {
  const children = (provided: any, snapshot: any) => {
    const content = (
      <Box
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={provided.draggableProps.style}
        position="relative"
      >
        <Center {...containerProps}>{label}</Center>
      </Box>
    );
    return snapshot.isDragging
      ? ReactDOM.createPortal(content, document.body)
      : content;
  };

  return (
    <Draggable key={item} draggableId={`${item}`} index={index}>
      {children}
    </Draggable>
  );
};

export const SpaceUI = observer(({ space }: Props) => {
  const { spaceStore } = useStore();

  const { fieldsByKey } = useSpaceInfo(space);

  const ui = useMemo(
    () => ({
      body: space.ui?.body.filter((item) => !!fieldsByKey[item]),
      head: space.ui?.head.filter((item) => !!fieldsByKey[item]),
    }),
    [space.ui, fieldsByKey]
  );

  const unusedFields = useMemo(() => {
    return space.fields
      .filter(
        (field) => !ui.head.includes(field.key) && !ui.body.includes(field.key)
      )
      .map((item) => item.key);
  }, [ui, space.fields]);

  const onDragEnd = useCallback(
    async ({ source, destination }) => {
      try {
        if (destination && source) {
          if (
            destination.droppableId === "unused" &&
            source.droppableId !== "unused"
          ) {
            spaceStore.updateSpace({
              id: space.id,
              ui: {
                ...space.ui,
                [source.droppableId]: (ui as any)[source.droppableId].filter(
                  (_: any, index: number) => index !== source.index
                ),
              },
            });
          } else if (
            destination.droppableId !== "unused" &&
            source.droppableId === "unused"
          ) {
            const newArray = [...(ui as any)[destination.droppableId]];
            newArray.splice(destination.index, 0, unusedFields[source.index]);
            spaceStore.updateSpace({
              id: space.id,
              ui: {
                ...space.ui,
                [destination.droppableId]: newArray,
              },
            });
          } else if (destination.droppableId === source.droppableId) {
            const newArray = [...(ui as any)[destination.droppableId]];
            const [itemToMove] = newArray.splice(source.index, 1);
            newArray.splice(destination.index, 0, itemToMove);
            spaceStore.updateSpace({
              id: space.id,
              ui: {
                ...space.ui,
                [source.droppableId]: newArray,
              },
            });
          } else if (
            destination.droppableId !== "unused" &&
            source.droppableId !== "unused"
          ) {
            const newDestinationArray = [
              ...(ui as any)[destination.droppableId],
            ];
            newDestinationArray.splice(
              destination.index,
              0,
              (ui as any)[source.droppableId][source.index]
            );
            const newSourceArray = (ui as any)[source.droppableId].filter(
              (_: any, index: number) => index !== source.index
            );
            spaceStore.updateSpace({
              id: space.id,
              ui: {
                ...space.ui,
                [source.droppableId]: newSourceArray,
                [destination.droppableId]: newDestinationArray,
              },
            });
          }
        }
      } catch {}
    },
    [unusedFields, spaceStore, space, ui]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Text>字段 (未使用)</Text>
      <Droppable direction="horizontal" droppableId={"unused"}>
        {(provided, snapshot) => (
          <Flex
            {...provided.droppableProps}
            ref={provided.innerRef}
            my="20px"
            h="80px"
            direction="row"
            alignItems="center"
            border="1px solid #1e272e"
          >
            {(unusedFields || []).map((item, index) => {
              const field = fieldsByKey[item];
              return (
                <FieldLabel
                  containerProps={{
                    fontSize: 12,
                    mx: 2,
                    borderRadius: "5px",
                    padding: "5px",
                    bg: "gray.600",
                    color: "white",
                  }}
                  label={field.label}
                  item={item}
                  index={index}
                />
              );
            })}
          </Flex>
        )}
      </Droppable>

      <Text my="20px">项目详情页面</Text>

      <Flex dir="row" height="100%" width="100%" border="1px solid #1e272e">
        <Flex flex="6" direction="column" borderRight="1px solid #1e272e">
          <Droppable direction="horizontal" droppableId={"head"}>
            {(provided, snapshot) => (
              <Flex
                borderBottom="1px solid #1e272e"
                height="80px"
                direction="row"
                alignItems="center"
                padding="10px"
                position="relative"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {ui.head.map((item, index) => {
                  const field = fieldsByKey[item];
                  return (
                    <FieldLabel
                      containerProps={{
                        fontSize: 12,
                        mx: 2,
                        borderRadius: "5px",
                        padding: "5px",
                        bg: "gray.600",
                        color: "white",
                      }}
                      label={field.label}
                      item={item}
                      index={index}
                    />
                  );
                })}
              </Flex>
            )}
          </Droppable>
          <Droppable droppableId={"body"}>
            {(provided, snapshot) => (
              <Flex
                padding="20px"
                flex="1"
                minH="0"
                direction="column"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {ui.body.map((item, index) => {
                  const field = fieldsByKey[item];
                  return (
                    <FieldLabel
                      containerProps={{
                        fontSize: 12,
                        my: 2,
                        borderRadius: "5px",
                        padding: "5px",
                        bg: "gray.600",
                        color: "white",
                      }}
                      label={field.label}
                      item={item}
                      index={index}
                    />
                  );
                })}
              </Flex>
            )}
          </Droppable>
          <Flex
            w="100%"
            borderTop="1px solid #1e272e"
            height="60px"
            direction="row"
            alignItems="center"
            padding="30px"
          >
            <Center w="100%">添加文件</Center>
          </Flex>
        </Flex>
        <Flex flex="4" flexDirection="column">
          <Flex
            w="100%"
            borderBottom="1px solid #1e272e"
            height="80px"
            direction="row"
            alignItems="center"
            padding="30px"
          ></Flex>
          <Flex flex={1} minH="0" overflowY="auto">
            <Center w="100%">操作</Center>
          </Flex>
          <Flex
            w="100%"
            borderTop="1px solid #1e272e"
            height="60px"
            direction="row"
            alignItems="center"
            padding="30px 0"
          >
            <Center w="100%">评论</Center>
          </Flex>
        </Flex>
      </Flex>
    </DragDropContext>
  );
});
