import { Box, Flex } from "@chakra-ui/layout";
import { LanguageSwitcher } from "component/LanguageSwitcher";
import { NavGroup } from "component/NavGroup";
import { NavItem } from "component/NavItem";
import React from "react";
import { FaMoneyBill, FaUser } from "react-icons/fa";
import { useIntl } from "react-intl";
import { Route, RouteChildrenProps, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { AccountSettings } from "./AccountSettings";
import { Billing } from "./Billing";

import { Members } from "screen/Members";
import { BiGroup } from "react-icons/bi";

interface Props {}

export const Settings: React.FunctionComponent<RouteChildrenProps> = ({
  match,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Flex height="100%" flex="1">
      <Flex
        height="100%"
        display={{ base: "none", lg: "flex" }}
        width="250px"
        direction="column"
        overflowY="auto"
        borderRightWidth="1px"
        borderLeftWidth="1px"
        p="6"
        bg="gray.900"
      >
        <NavGroup label={formatMessage({ id: "settings.menu.title" })}>
          <NavItem
            href={`${match!.url}/account`}
            icon={<FaUser />}
            label={formatMessage({ id: "settings.menu.account" })}
          />
          <NavItem
            href={`${match!.url}/billing`}
            icon={<FaMoneyBill />}
            label={formatMessage({ id: "settings.menu.billing" })}
          />{" "}
          <NavItem
            href={`${match!.url}/members`}
            icon={<BiGroup />}
            label={formatMessage({ id: "dashboard.label.teams" })}
          />
        </NavGroup>
        <Box flex={1}></Box>
        <LanguageSwitcher />
      </Flex>

      <Box w="100%" h="100%" overflowY="auto">
        <Switch>
          <Redirect exact from={`${match?.url}`} to={`${match?.url}/account`} />
          <Route path={`${match?.url}/billing`} component={Billing} />
          <Route path={`${match?.url}/account`} component={AccountSettings} />
          <Route path={`${match?.url}/members`} component={Members} />
        </Switch>
      </Box>
    </Flex>
  );
};
