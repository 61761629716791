import { gql } from "@apollo/client";
import {
  useItemResource,
  useTeamItems,
  useTeamSingleItem,
} from "./useTeamQuery";

const GET_TEAM_PAGES = gql`
  query TeamPagesQuery($where: SundayPageWhereInput) {
    sundayPages(where: $where) {
      id
      title
      path
      teamId
      data
      isPublic
    }
  }
`;

const GET_TEAM_PAGE = gql`
  query SundayPage($where: SundayPageWhereUniqueInput!) {
    sundayPage(where: $where) {
      id
      path
      title
      data
      isPublic
    }
  }
`;

const CREATE_TEAM_PAGE = gql`
  mutation CreateOneSundayPage($data: SundayPageCreateInput!) {
    createOneSundayPage(data: $data) {
      id
    }
  }
`;

const UPDATE_TEAM_PAGE = gql`
  mutation UpdateOneSundayPage(
    $data: SundayPageUpdateInput!
    $where: SundayPageWhereUniqueInput!
  ) {
    updateOneSundayPage(data: $data, where: $where) {
      id
      data
      isPublic
      title
    }
  }
`;

const REMOVE_TEAM_PAGE = gql`
  mutation DeleteOneSundayPage($where: SundayPageWhereUniqueInput!) {
    deleteOneSundayPage(where: $where) {
      id
    }
  }
`;

export const useTeamPages = (options = {}) => {
  return useTeamItems(GET_TEAM_PAGES, options);
};

export const usePageFromId = (id: string) => {
  return useTeamSingleItem(GET_TEAM_PAGE, id);
};

export const usePageResource = () => {
  return useItemResource({
    CREATE_QUERY: CREATE_TEAM_PAGE,
    UPDATE_QUERY: UPDATE_TEAM_PAGE,
    REMOVE_QUERY: REMOVE_TEAM_PAGE,
    REFTECH_QUERY: GET_TEAM_PAGES,
  });
};
