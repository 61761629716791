import { Box, Flex, Text } from "@chakra-ui/layout";
import { Item } from "const/type";
import { observer } from "mobx-react-lite";
import { FileLoader } from "module/file/component/FileLoader";
import { FilePreview } from "module/file/component/FilePreview";
import React from "react";

interface Props {
  item: Item;
}

export const ItemAttachments = observer(({ item }: Props) => {
  return (
    <Flex my={2} flexDir="column">
      <Text>附件</Text>

      <Flex my={2} flexDir="row">
        {(item.attachments || []).map((attachment) => (
          <Box mx={1} key={attachment.id}>
            <FileLoader id={attachment.id}>
              <FilePreview id={attachment.id} />
            </FileLoader>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
});
