import React, { useCallback, useState } from "react";
import { EditorContent } from "@tiptap/react";
import {
  Editable,
  EditablePreview,
  EditableInput,
  Box,
  Flex,
} from "@chakra-ui/react";
import { SundayEditorMenu } from "component/SundayEditor";
import { useSundayEditor } from "component/SundayEditor/hooks";
import { IDocument, useDocumentById } from "module/sunday/document";
import { observer } from "mobx-react-lite";
import { useDebounce } from "react-use";

interface Props {
  document: IDocument;
}

export const DocDetail = observer(({ document }: Props) => {
  const { updateDocument } = useDocumentById(document.id);
  const [html, setHtml] = useState(document.content);

  const editor = useSundayEditor({
    content: document.content,
    onUpdate: ({ editor }) => {
      setHtml(editor.getHTML());
    },
  });

  useDebounce(
    () => {
      if (html !== document.content) {
        console.log(html);
        updateDocument({
          content: html,
        });
      }
    },
    3000,
    [html]
  );

  return (
    <Flex flexDir="column" h="full">
      <Editable
        onSubmit={(title) => {
          updateDocument({
            title,
          });
        }}
        fontSize="20px"
        defaultValue={document!.title}
      >
        <EditablePreview
          borderWidth="1px"
          borderColor="transparent"
          p="2"
          _hover={{
            borderColor: "white",
          }}
        />
        <EditableInput />
      </Editable>
      <SundayEditorMenu editor={editor} />
      <Box flex={1} minH="0" overflowY="auto" py={"20px"}>
        <EditorContent editor={editor} />
      </Box>
    </Flex>
  );
});
