import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  LightMode,
  Stack,
  useColorModeValue as mode,
  Text,
  Box,
  Heading,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { FaGoogle, FaWeixin } from "react-icons/fa";
import { UnderlineLink } from "component/UnderlineLink";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Redirect } from "react-router";
export const SigninForm = observer(() => {
  const { authStore } = useStore();
  const { formatMessage } = useIntl();
  const [error, setError] = useState<string | undefined>(undefined);

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async ({ email, password }: any) => {
    setError(undefined);
    try {
      await authStore.login({ email, password });
    } catch (err: any) {
      setError(err?.message || "Unkown Error");
    }
  };

  const onGoogleLogin = async () => {
    setError(undefined);
    await authStore.googleLogin();
  };

  const onWechatLogin = async () => {
    setError(undefined);
    await authStore.wechatLogin();
  };

  return (
    <>
      {!!authStore.user && <Redirect to="/dashboard" />}
      <Box textAlign="center" mb={{ base: "10", md: "16" }}>
        <Heading as="h1" size="xl" fontWeight="extrabold" letterSpacing="tight">
          <FormattedMessage
            id="signIn.title"
            defaultMessage=" Sign in to your account"
          />
        </Heading>
        <Text mt="3" color={mode("gray.600", "gray.400")} fontWeight="medium">
          <FormattedMessage
            id="signIn.no.account"
            defaultMessage="Need an account?"
          />{" "}
          <Link to="/auth/signup">
            <UnderlineLink>
              <FormattedMessage
                id="signIn.signup"
                defaultMessage="Sign up for free"
              />
            </UnderlineLink>
          </Link>
        </Text>
      </Box>
      <Button
        onClick={onGoogleLogin}
        fontSize="sm"
        fontWeight="bold"
        size="lg"
        leftIcon={<FaGoogle fontSize="18px" />}
        iconSpacing="3"
        colorScheme="red"
        width="full"
      >
        <FormattedMessage
          id="signIn.google.login"
          defaultMessage="Continue with Google"
        />
      </Button>

      <Button
        my={2}
        onClick={onWechatLogin}
        fontSize="sm"
        fontWeight="bold"
        size="lg"
        leftIcon={<FaWeixin fontSize="18px" />}
        iconSpacing="3"
        colorScheme="green"
        width="full"
      >
        <FormattedMessage
          id="signIn.wechat.login"
          defaultMessage="Continue with Wechat"
        />
      </Button>
      <HStack my="8">
        <Divider />
        <Text
          px="3"
          textTransform="uppercase"
          fontSize="sm"
          fontWeight="semibold"
          color={mode("gray.600", "gray.200")}
        >
          <FormattedMessage id="label.or" defaultMessage="or" />
        </Text>
        <Divider />
      </HStack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="-px">
          <FormControl id="email-address">
            <FormLabel srOnly>
              <FormattedMessage
                id="label.email.address"
                defaultMessage="Email address"
              />
            </FormLabel>
            <Input
              size="lg"
              type="email"
              autoComplete="email"
              required
              placeholder={formatMessage({ id: "label.email.address" })}
              bg={mode("white", "gray.700")}
              fontSize="md"
              roundedBottom="0"
              {...register("email", {
                required: "This is required",
              })}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel srOnly>
              <FormattedMessage id="label.password" defaultMessage="Password" />
            </FormLabel>
            <Input
              type="password"
              autoComplete="current-password"
              required
              size="lg"
              bg={mode("white", "gray.700")}
              fontSize="md"
              roundedTop="0"
              placeholder={formatMessage({ id: "label.password" })}
              {...register("password", {
                required: "This is required",
              })}
            />
          </FormControl>
        </Stack>
        <Flex align="center" justify="space-between" mt="8">
          <LightMode>
            <Checkbox
              size="lg"
              colorScheme="blue"
              sx={{
                ".chakra-checkbox__control": {
                  "&:not([data-checked])": { bg: mode("white", "gray.700") },
                  rounded: "base",
                  borderWidth: "1px",
                },
                ".chakra-checkbox__label": { fontSize: "sm" },
              }}
            >
              <FormattedMessage
                id="signIn.remember.me"
                defaultMessage="Remember me"
              />
            </Checkbox>
          </LightMode>
          <UnderlineLink fontSize="sm">
            <FormattedMessage
              id="signIn.forgot.password"
              defaultMessage="Forgot Password"
            />
          </UnderlineLink>
        </Flex>

        {error && (
          <Text textAlign="center" mt={5} color="red.600">
            {error}
          </Text>
        )}

        <LightMode>
          <Button
            disabled={isSubmitting}
            size="lg"
            type="submit"
            mt="8"
            w="full"
            colorScheme="blue"
            fontSize="md"
            fontWeight="bold"
          >
            <FormattedMessage id="label.signin" defaultMessage="Sign in" />
          </Button>
        </LightMode>
      </form>
    </>
  );
});
