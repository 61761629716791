import { action, makeObservable, observable } from "mobx";
import { SundayBaseStore } from "module/sunday/store";
import { FlowService, FlowServiceClass } from "../service";

export interface IFlow {
  id: string;
  triggerType: string;
  triggerParams: any;
  steps: any[];
  updatedAt: any;
  isActive: boolean;
  name: string;
}

export class FlowStore extends SundayBaseStore<IFlow, FlowServiceClass> {
  isModalOpen: boolean = false;

  constructor() {
    super(FlowService, "FlowStore");

    makeObservable(this, {
      isModalOpen: observable,
      setIsModalOpen: action,
    });
  }

  setIsModalOpen = (bool: boolean) => {
    this.isModalOpen = bool;
  };
}

export const flowStore = new FlowStore();
