import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useMemo,
  forwardRef,
} from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { UserAvatar } from "component/UserAvatar";
import Mention from "@tiptap/extension-mention";
import { teamStore } from "module/sunday/team";
import { SundayEditorMentionList } from "./SundayEditorMentionList";

const MentionList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const items = useMemo(() => {
    return teamStore.users.filter((item) =>
      item.username.includes(props.query)
    );
  }, [props.query]);

  const selectItem = (index: number) => {
    const item = items[index];

    if (item) {
      console.log(item.id);
      console.log(props);
      props.command({ id: item.username });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: any) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Box
      w="200px"
      background="gray.800"
      p="10px"
      borderRadius="10px"
      className="items"
    >
      {items.map((item: any, index: number) => (
        <Flex
          padding="5px"
          borderRadius="5px"
          background={selectedIndex === index ? "gray.900" : "transparent"}
          my="10px"
          cursor="pointer"
          flexDir="row"
          alignItems="center"
          key={index}
          onClick={() => selectItem(index)}
        >
          <UserAvatar avatarProps={{ size: "xs" }} id={item.id} />
          <Text ml="5px">{item.username}</Text>
        </Flex>
      ))}

      {items.length === 0 && <span>No Users</span>}
    </Box>
  );
});

const suggestion = {
  items: () => {
    return [];
  },

  render: () => {
    let component: any;
    let popup: any;

    return {
      onStart: (props: any) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
          zIndex: 10000000,
        });
      },

      onUpdate(props: any) {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: any) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};

export const SundayMention = Mention.configure({
  suggestion,
  HTMLAttributes: {
    class: "mention",
  },
  // renderLabel: ({ options, node }: any) => {
  //   return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
  // },
});
