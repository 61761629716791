import { createContext, useContext } from "react";
import { ISpace } from "./SpaceStore";

export const SpaceContext = createContext<ISpace>({
  fields: [],
  views: [],
  id: "",
  name: "",
  key: "",
  ui: {
    head: [],
    body: [],
    create: [],
  },
});

export const useSpaceContext = () => {
  return useContext(SpaceContext);
};

export const SpaceViewContext = createContext({});

export const useSpaceViewContext = () => {
  return useContext(SpaceViewContext);
};
