import { Box, HStack, Text } from "@chakra-ui/layout";
import BigNumber from "bignumber.js";
import { Item } from "const/type";
import { ItemService } from "module/sunday/item";
import { ISpaceView } from "module/sunday/space";
import React, { useCallback, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useStore } from "store/RootStore";
import { ItemBoardCard } from "./ItemBoardCard";
import { arrayMoveImmutable } from "array-move";

interface Props {
  columns: any[];
  itemsByColumn: {
    [key: string]: {
      id: string;
      updatedAt: string;
      sortIndex: string;
    }[];
  };
  groupBy: string;
  update: (id: string, value: any) => void;
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "white",
  position: "relative",
  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver: boolean) => ({
  // background: isDraggingOver ? "lightblue" : "rgb(235, 236, 240)",
  width: "100%",
  minHeight: 300,
});

export const ItemBoard = ({
  columns = [],
  itemsByColumn = {},
  update,
  groupBy,
}: Props) => {
  const { itemStore } = useStore();
  const onDragEnd = useCallback(
    async ({ source, destination }) => {
      try {
        if (destination && source) {
          console.log(destination, source);
          const itemToUpdate: any = {};

          const newPropertyValue = destination.droppableId;
          const itemId = itemsByColumn[source.droppableId][source.index].id;
          if (
            (destination.droppableId === source.droppableId &&
              source.index !== destination.index) ||
            destination.droppableId !== source.droppableId
          ) {
            const originalColumnData = itemsByColumn[destination.droppableId];
            let columnData = arrayMoveImmutable(
              originalColumnData,
              source.index,
              destination.index
            );
            if (destination.droppableId !== source.droppableId) {
              columnData = [...originalColumnData];
              columnData.splice(
                destination.index,
                0,
                itemsByColumn[source.droppableId][source.index]
              );
            }
            const prevItem = columnData[destination.index - 1];
            const nextItem = columnData[destination.index + 1];
            ItemService.updatePosition({
              prevItemId: prevItem?.id,
              nextItemId: nextItem?.id,
              itemId,
              field: groupBy,
            });

            let sortIndex;
            const prevSortIndex = prevItem?.sortIndex;
            const nextSortIndex = nextItem?.sortIndex;
            if (!prevSortIndex && nextSortIndex) {
              sortIndex = new BigNumber(nextSortIndex).minus(1).toString();
            } else if (prevSortIndex && !nextSortIndex) {
              sortIndex = new BigNumber(prevSortIndex).plus(1).toString();
            } else {
              sortIndex = new BigNumber(prevSortIndex)
                .plus(new BigNumber(nextSortIndex))
                .dividedBy(2)
                .toString();
            }
            itemToUpdate.sortIndex = sortIndex;
          }

          if (destination.droppableId !== source.droppableId) {
            itemToUpdate.order_field = newPropertyValue;
            itemStore.saveItem(
              {
                id: itemId,
                customData: {
                  [groupBy]: newPropertyValue,
                },
              },
              false
            );
          }

          update(itemId, itemToUpdate);
        }
      } catch {}
    },
    [update, itemsByColumn, groupBy, itemStore]
  );
  return (
    <Box flex={1} height="100%">
      <DragDropContext onDragEnd={onDragEnd}>
        <HStack
          flex={1}
          h="100%"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {columns.map((column) => (
            <Box
              display="flex"
              flexDir="column"
              h="100%"
              width="300px"
              key={column.key}
            >
              <Box
                borderTop="2px"
                borderColor={column.color}
                width="100%"
                h="50px"
                background="#2b343b"
                borderBottomLeftRadius="5px"
                borderBottomRightRadius="5px"
              >
                <HStack p="0 10px" w="100%" h="100%">
                  <Text>{column.label}</Text>
                </HStack>
              </Box>

              <Droppable droppableId={column.key}>
                {(provided, snapshot) => (
                  <Box
                    flex={1}
                    overflowY="auto"
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {itemsByColumn[column.key].map(
                      (
                        { id: itemId, updatedAt, sortIndex }: any,
                        index: number
                      ) => (
                        <Draggable
                          key={`${itemId}-${sortIndex}`}
                          draggableId={`${itemId}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Box
                              my="15px"
                              w="100%"
                              padding="5px"
                              bg="#2b343b"
                              borderRadius="5px"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ItemBoardCard
                                updatedAt={updatedAt}
                                id={itemId}
                              />
                            </Box>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
          ))}
        </HStack>
      </DragDropContext>
    </Box>
  );
};
