import React from 'react'

type Props = {
  value: any;
  onChange: any
}

export const ImagePicker = ({
  value,
  onChange
}: Props) => {
  return (
    <div>ImagePicker</div>
  )
}