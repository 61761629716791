import {
  Box,
  Portal,
  Input,
  HStack,
  Avatar,
  Text,
  AvatarProps,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";

interface Props {
  onSelect?: (status: any) => void;
  trigger: React.ReactNode;
}

export const TaskStatusPopover = observer(({ onSelect, trigger }: Props) => {
  const initRef = useRef<any>();
  const { configStore } = useStore();

  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>{trigger}</PopoverTrigger>
          <Portal appendToParentPortal={true}>
            <PopoverContent width="200px">
              <PopoverArrow />
              <PopoverCloseButton top="10px" />
              <PopoverBody>
                <Input
                  focusBorderColor="none"
                  border="none"
                  ref={initRef}
                  placeholder="搜索"
                />

                {configStore.statuses.map((status) => (
                  <HStack
                    cursor="pointer"
                    key={status.key}
                    onClick={() => {
                      onClose();
                      onSelect && onSelect(status);
                    }}
                    my="5px"
                  >
                    <Box
                      width="20px"
                      height="20px"
                      borderRadius="10px"
                      background={status.color}
                    />
                    <Text>{status.label}</Text>
                  </HStack>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
});
