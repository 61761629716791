import { Select, Switch } from "@chakra-ui/react";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { useStore } from "store/RootStore";
import { FlowControllerProps } from "./const";

export const SpaceSelector: React.FunctionComponent<FlowControllerProps> = ({
  fieldName,
}) => {
  const { spaceStore } = useStore();
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
  });
  return (
    <div>
      <Select onChange={onChange} value={value}>
        <option value={''}>选择空间</option>
        {spaceStore.spaces.map((space) => (
          <option key={space.id} value={space.id}>
            {space.name}
          </option>
        ))}
      </Select>
    </div>
  );
};
