import React, { useMemo } from "react";
import {
  Portal,
  Box,
  HStack,
  VStack,
  Text,
  IconButton,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import Select from "react-select";
import { ICustomField } from "module/sunday/space";
import { useIntl } from "react-intl";
import { MdExpandMore, MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import {
  FILTER_OPERATIONS,
  FILTER_DATE_OPERATIONS,
  CONDITION_CONNECTOR_OPTIONS,
} from "util/filter";
import { FaPlus } from "react-icons/fa";

interface AdvancedFilterRowProps {
  fields: Array<ICustomField>;
  filters: Array<any>;
  isDisabled?: boolean;
  handleAdvancedFilterOptionClick: (
    id?: string,
    key?: string,
    option?: string,
    op?: string,
    conditionConnector?: string
  ) => void;
  handleSaveFilter: (id: string) => void;
  handleDeleteFilter: (index: number) => void;
}

const AdvancedFilterRows: React.FC<AdvancedFilterRowProps> = ({
  fields,
  filters,
  isDisabled = false,
  handleAdvancedFilterOptionClick,
  handleSaveFilter,
  handleDeleteFilter,
}) => {
  const { formatMessage } = useIntl();

  const fieldOptions = useMemo(() => {
    return fields.map((_field) => ({ value: _field.key, label: _field.label }));
  }, [fields]);

  const fieldsById = useMemo(() => {
    return fields.reduce((acc: any, field) => {
      acc[field.key] = field;
      return acc;
    }, {});
  }, [fields]);

  return (
    <VStack>
      {filters.map((_filter, index: number) => {
        const { id, key, op, conditionConnector } = _filter;
        const currentField = fieldsById[key];
        const isTypeDate = currentField?.type === "date";
        const options = isTypeDate
          ? FILTER_DATE_OPERATIONS
          : currentField?.options.map((_option: any) => ({
              ..._option,
              value: _option.key,
            }));
        return (
          <HStack width="100%">
            {index === 0 ? (
              <Box marginRight="8px" width="100px">
                {formatMessage({ id: "table.filter.advanced.where" })}
              </Box>
            ) : (
              <Box marginRight="8px" width="100px">
                <Select
                  isDisabled={isDisabled}
                  name="field"
                  options={CONDITION_CONNECTOR_OPTIONS}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 10000000,
                    }),
                    menu: (base) => ({
                      ...base,
                      color: "gray",
                    }),
                  }}
                  onChange={(option) => {
                    handleAdvancedFilterOptionClick(
                      id,
                      key,
                      _filter.value,
                      op,
                      option?.value
                    );
                  }}
                  value={CONDITION_CONNECTOR_OPTIONS.find(
                    (connector) => connector.value === conditionConnector
                  )}
                  menuPortalTarget={document.body}
                />
              </Box>
            )}
            <Box marginRight="8px" width="200px">
              <Select
                isDisabled={isDisabled}
                name="field"
                options={fieldOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 10000000,
                  }),
                  menu: (base) => ({
                    ...base,
                    color: "gray",
                  }),
                }}
                onChange={(option) => {
                  handleAdvancedFilterOptionClick(
                    id,
                    option?.value,
                    _filter.value,
                    op,
                    conditionConnector
                  );
                }}
                value={fieldOptions.find((option) => option.value === key)}
                menuPortalTarget={document.body}
              />
            </Box>
            <Box marginRight="8px" width="200px">
              <Select
                isDisabled={isDisabled}
                name="operation"
                options={FILTER_OPERATIONS}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 10000000,
                  }),
                  menu: (base) => ({
                    ...base,
                    color: "gray",
                  }),
                }}
                onChange={(option) => {
                  handleAdvancedFilterOptionClick(
                    id,
                    key,
                    _filter.value,
                    option?.value,
                    conditionConnector
                  );
                }}
                value={FILTER_OPERATIONS.find((option) => option.value === op)}
                menuPortalTarget={document.body}
              />
            </Box>
            <Box marginRight="8px" minWidth="300px">
              <Select
                isDisabled={isDisabled}
                isMulti={!isTypeDate}
                name="options"
                options={options}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 10000000,
                  }),
                  menu: (base) => ({
                    ...base,
                    color: "gray",
                  }),
                }}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(option) => {
                  handleAdvancedFilterOptionClick(
                    id,
                    key,
                    isTypeDate
                      ? [option?.value]
                      : option.map((o: any) => o.value),
                    op,
                    conditionConnector
                  );
                }}
                value={
                  isTypeDate
                    ? options?.filter((option: any) =>
                        _filter.value.includes(option?.value)
                      )
                    : options?.filter((option: any) =>
                        _filter.value.includes(option?.key)
                      )
                }
                menuPortalTarget={document.body}
              />
            </Box>
            <IconButton
              aria-label="delete-filter"
              icon={<MdOutlineClose size="24px" color="red" />}
              variant="ghost"
              cursor="pointer"
              onClick={() => {
                handleDeleteFilter(index);
              }}
            />
            {!_filter.isSaved && (
              <IconButton
                aria-label="save-filter"
                icon={<MdOutlineCheck size="24px" color="green" />}
                isDisabled={
                  !_filter.key || !_filter.op || _filter.value.length < 1
                }
                variant="ghost"
                cursor="pointer"
                onClick={() => {
                  handleSaveFilter(_filter.id);
                }}
              />
            )}
          </HStack>
        );
      })}
    </VStack>
  );
};

interface AdvancedFilterProps {
  popoverHeight: number;
  validGroupByFields: Array<ICustomField>;
  setFilters: (value: any) => void;
  filters: Array<any>;
}

const AdvancedFilter: React.FC<AdvancedFilterProps> = (props) => {
  const { popoverHeight, validGroupByFields, filters, setFilters } = props;
  const { formatMessage } = useIntl();

  const handleAdvancedFilterOptionClick = (
    id?: string,
    key?: string,
    option?: string,
    op?: string,
    conditionConnector?: string
  ) => {
    const updatedFilters = filters.map((_filter) => {
      if (id && _filter.id === id) {
        if (key === _filter.key) {
          return { ..._filter, id, key, op, conditionConnector, value: option };
        } else {
          return { ..._filter, id, key, op: "", conditionConnector, value: [] };
        }
      } else if (!id && !_filter.id) {
        return { ..._filter, key, op, conditionConnector, value: option };
      } else {
        return _filter;
      }
    });
    setFilters(updatedFilters);
  };

  const handleAddNewFilter = () => {
    setFilters(
      filters.concat({
        key: "",
        value: [],
        op: "",
        conditionConnector: "and",
        isSaved: false,
      })
    );
  };

  const handleSaveFilter = (id: string) => {
    const updatedFilters = filters.map((_filter) => {
      if (_filter.id === id) {
        return { ..._filter, isSaved: true };
      } else {
        return _filter;
      }
    });
    setFilters(updatedFilters);
  };

  const handleDeleteFilter = (index: number) => {
    setFilters(filters.filter((f, _index) => _index !== index));
  };

  return (
    <Popover isLazy closeOnBlur>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <HStack
              cursor="pointer"
              _hover={{ bg: "gray.400" }}
              _active={{ bg: "gray.500" }}
              px={2}
              borderRadius="0px 5px 5px 0px"
              height="24px"
            >
              <MdExpandMore color="gray.300" />
            </HStack>
          </PopoverTrigger>
          <Portal appendToParentPortal={true}>
            <PopoverContent
              width="auto"
              height={`${popoverHeight}px`}
              overflow="hidden"
            >
              <PopoverArrow />
              <PopoverBody padding="16px" height="100%">
                <Box marginBottom="16px">
                  {formatMessage({ id: "table.filter.advanced" })}
                </Box>
                <HStack
                  display="flex"
                  alignItems="flex-start"
                  height={`${popoverHeight - 114}px`}
                  overflow="scroll"
                >
                  {validGroupByFields.length > 0 ? (
                    <AdvancedFilterRows
                      fields={validGroupByFields}
                      filters={filters}
                      handleAdvancedFilterOptionClick={
                        handleAdvancedFilterOptionClick
                      }
                      handleSaveFilter={handleSaveFilter}
                      handleDeleteFilter={handleDeleteFilter}
                    />
                  ) : (
                    <Box>{formatMessage({ id: "table.filter.noFilter" })}</Box>
                  )}
                </HStack>
                <HStack
                  _hover={{
                    bg: "gray.500",
                  }}
                  p="10px"
                  cursor="pointer"
                  alignItems="center"
                  width="160px"
                  onClick={handleAddNewFilter}
                >
                  <FaPlus />
                  <Text ml="5px">
                    {formatMessage({ id: "table.filter.advanced.add" })}
                  </Text>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default AdvancedFilter;
