import { Center } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FaFileExcel } from "react-icons/fa";

interface Props {}

export const Sheet = (props: Props) => {
  const { control } = useFormContext();

  return (
    <Center py="30px" flexDir="column" w="full" h="full">
      <FaFileExcel fontSize="40px"></FaFileExcel>
      <Text mt="20px">Sheet</Text>
      <Text color="gray.200" fontSize="12px">
        Sheet View Give you seamless experience when mirgrating from excel
      </Text>
    </Center>
  );
};
