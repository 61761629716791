import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { useStore } from "store/RootStore";

const GET_TEAM_BOARDS = gql`
  query TeamBoardsQuery($where: SundayBoardWhereInput) {
    sundayBoards(where: $where) {
      id
      title
      teamId
      data
    }
  }
`;

const GET_TEAM_BOARD = gql`
  query SundayBoardQuery($where: SundayBoardWhereUniqueInput!) {
    sundayBoard(where: $where) {
      id
      title
      teamId
      data
      createdAt
      updatedAt
    }
  }
`;

const CREATE_TEAM_BOARD = gql`
  mutation CreateOneSundayBoard($data: SundayBoardCreateInput!) {
    createOneSundayBoard(data: $data) {
      id
    }
  }
`;

const UPDATE_TEAM_BOARD = gql`
  mutation UpdateOneSundayBoard(
    $data: SundayBoardUpdateInput!
    $where: SundayBoardWhereUniqueInput!
  ) {
    updateOneSundayBoard(data: $data, where: $where) {
      id
    }
  }
`;

export const useTeamBoards = () => {
  const { teamStore } = useStore();

  return useQuery(GET_TEAM_BOARDS, {
    variables: {
      where: {
        teamId: {
          equals: teamStore.selectedTeam?.id,
        },
      },
    },
  });
};

export const useBoard = (id: string) => {
  return useQuery(GET_TEAM_BOARD, {
    variables: {
      where: {
        id,
      },
    },
  });
};

export const useBoardResource = () => {
  const { teamStore } = useStore();
  const [mutateCreate] = useMutation(CREATE_TEAM_BOARD, {
    refetchQueries: [
      {
        query: GET_TEAM_BOARDS,
      },
    ],
  });
  const [mutateUpdate] = useMutation(UPDATE_TEAM_BOARD);

  const create = useCallback(
    async (values) => {
      const data: any = {
        ...values,
      };
      data.team = {
        connect: {
          id: teamStore.selectedTeam?.id,
        },
      };
      return mutateCreate({
        variables: {
          data,
        },
      });
    },
    [mutateCreate, teamStore.selectedTeam]
  );

  const update = useCallback(
    async (id, data) => {
      return mutateUpdate({
        variables: {
          data,
          where: {
            id,
          },
        },
      });
    },
    [mutateUpdate]
  );

  return {
    create,
    update,
  };
};
