import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  List,
  Spinner,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { IBlock, IBlockItem, BLOCKS_MAP } from "./const";
import { BlockItem } from "./ListItem";
import { BlocksModal } from "./BlocksModal";
import {
  IoClose,
  IoPencil,
  IoArrowUp,
  IoArrowDown,
  IoCopy,
  IoSettings,
  IoAirplane,
} from "react-icons/io5";
import cuid from "cuid";
import { arrayMove } from "react-sortable-hoc";
import { SingleBlockEditor } from "./SingleBlockEditor";
import { BlocksEditor } from "./BlocksEditor";

type Props = {
  blocks: IBlockItem[];
  onChange: (blocks: IBlockItem[]) => void;
  onGenerate?: (block: IBlock) => Promise<IBlockItem["props"]>;
};

const ListBlockWrapper = ({
  setEditingItemIndex,
  index,
  block,
  setAddingIndex,
  copyBlock,
  moveBlock,
  removeItem,
  blocks,
  onChange,
  onGenerate,
}: {
  setEditingItemIndex: (index: number) => void;
  index: number;
  block: IBlockItem;
  setAddingIndex: (index: number) => void;
  copyBlock: Function;
  moveBlock: Function;
  removeItem: Function;
  blocks: IBlockItem[];
  onGenerate?: Function;
  onChange: Function;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClickGenerate = async (block: IBlockItem) => {
    try {
      setIsLoading(true);
      if (onGenerate) {
        const newProps = await onGenerate(BLOCKS_MAP[block.type]);
        if (newProps) {
          onChange(
            blocks.map((_block) => {
              if (_block.id === block.id) {
                return {
                  ...block,
                  props: newProps,
                };
              } else {
                return _block;
              }
            })
          );
        }
      }
    } catch {}
    finally {
      setIsLoading(false)
    }
  };

  return (
    <Box id={block.id} position={"relative"} key={block.id}>
      {index === 0 && (
        <Button
          position={"absolute"}
          top={"0"}
          left={"50%"}
          zIndex={2}
          transform="translate(-50%, -50%)"
          onClick={() => {
            setAddingIndex(index);
          }}
        >
          Add Section
        </Button>
      )}
      <Box position={"relative"}>
        <Box
          top={0}
          left={0}
          right={0}
          bottom={0}
          position="absolute"
          background={"black"}
          opacity={isLoading ? 1 : 0}
          zIndex={1}
          border={"5px solid #3498db"}
          _hover={{
            opacity: 0.7,
          }}
        >
          <Center w="full" h="full">
            {isLoading ? (
              <Spinner />
            ) : (
              <Flex flexDir={"row"} gap={"10px"}>
                <IoPencil
                  size={30}
                  cursor={"pointer"}
                  onClick={() => {
                    setEditingItemIndex(index);
                  }}
                />
                <IoCopy
                  size={30}
                  cursor={"pointer"}
                  onClick={() => {
                    copyBlock(index);
                  }}
                />
                <IoArrowUp
                  size={30}
                  cursor={index !== 0 ? "pointer" : "not-allowed"}
                  onClick={() => {
                    index !== 0 && moveBlock(index, index - 1);
                  }}
                />
                <IoArrowDown
                  size={30}
                  cursor={
                    index !== blocks.length - 1 ? "pointer" : "not-allowed"
                  }
                  onClick={() => {
                    index !== blocks.length - 1 && moveBlock(index, index + 1);
                  }}
                />
                <IoClose
                  size={30}
                  cursor={"pointer"}
                  onClick={() => {
                    removeItem(index);
                  }}
                />
                <IoAirplane
                  size={30}
                  cursor={"pointer"}
                  onClick={() => {
                    handleClickGenerate(block);
                  }}
                />
              </Flex>
            )}
          </Center>
        </Box>
        <BlockItem block={block} />
      </Box>
      <Button
        position={"absolute"}
        zIndex={2}
        bottom={"0"}
        left={"50%"}
        transform="translate(-50%, 50%)"
        onClick={() => {
          setAddingIndex(index + 1);
        }}
      >
        Add Section
      </Button>
    </Box>
  );
};

export const ListView = ({ blocks, onChange, onGenerate }: Props) => {
  const [addingIndex, setAddingIndex] = useState<number>(-1);
  const [editingItemIndex, setEditingItemIndex] = useState<number>(-1);
  const [isShowingBlocksEditor, setIsShowingBlocksEditor] =
    useState<boolean>(false);

  const editingItem = blocks[editingItemIndex];

  const handleSelectBlock = (block: IBlockItem) => {
    const newBlock: IBlockItem = {
      type: block.type,
      props: {},
      id: cuid(),
    };
    const newBlocks = [...blocks];
    newBlocks.splice(addingIndex, 0, newBlock);
    onChange(newBlocks);
    setAddingIndex(-1);
  };

  const removeItem = (index: number) => {
    onChange(blocks.filter((_, _index) => index !== _index));
  };

  const moveBlock = (oldIndex: number, newIndex: number) => {
    onChange(arrayMove(blocks, oldIndex, newIndex));
  };

  const copyBlock = (index: number) => {
    const newBlock: IBlockItem = {
      type: blocks[index].type,
      props: blocks[index].props,
      id: cuid(),
    };
    const newBlocks = [...blocks];
    newBlocks.splice(index, 0, newBlock);
    onChange(newBlocks);
  };

  const handleSaveBlock = (newProps: IBlockItem["props"]) => {
    onChange(
      blocks.map((block, index) => {
        if (index === editingItemIndex) {
          return {
            ...block,
            props: newProps,
          };
        } else {
          return block;
        }
      })
    );
  };

  return (
    <Box w="full" position={"relative"}>
      {!isShowingBlocksEditor && (
        <IconButton
          position={"absolute"}
          top="20px"
          left="0"
          aria-label="showBlocksEditor"
          onClick={() => {
            setIsShowingBlocksEditor(true);
          }}
          zIndex={3}
          icon={<IoSettings />}
        />
      )}

      {isShowingBlocksEditor && (
        <BlocksEditor
          blocks={blocks}
          onChange={onChange}
          handleEditBlock={setEditingItemIndex}
          editingBlockIndex={editingItemIndex}
          onClose={() => {
            setIsShowingBlocksEditor(false);
          }}
        />
      )}

      {blocks.map((block, index: number) => (
        <ListBlockWrapper
          block={block}
          index={index}
          blocks={blocks}
          setAddingIndex={setAddingIndex}
          setEditingItemIndex={setEditingItemIndex}
          copyBlock={copyBlock}
          moveBlock={moveBlock}
          removeItem={removeItem}
          onChange={onChange}
          onGenerate={onGenerate}
        />
      ))}

      {blocks.length === 0 && (
        <Center paddingY={"50px"}>
          <Button
            onClick={() => {
              setAddingIndex(0);
            }}
          >
            Add your first component
          </Button>
        </Center>
      )}

      {addingIndex !== -1 && (
        <BlocksModal
          handleSelectBlock={handleSelectBlock}
          onClose={() => {
            setAddingIndex(-1);
          }}
        />
      )}

      {editingItem && (
        <Flex
          padding="15px"
          paddingTop="40px"
          background={"white"}
          border="1px solid"
          color="black"
          borderColor={"gray.700"}
          width="400px"
          flexDir={"column"}
          overflowY={"auto"}
          position={"fixed"}
          right="20px"
          top="20px"
          bottom="20px"
          shadow={"base"}
          borderRadius={"20px"}
          zIndex={10}
        >
          <Box
            position={"absolute"}
            top={5}
            right={5}
            cursor={"pointer"}
            onClick={() => {
              setEditingItemIndex(-1);
            }}
          >
            <IoClose />
          </Box>
          <Box height={"full"} overflowY="auto">
            <SingleBlockEditor block={editingItem} onSave={handleSaveBlock} />
          </Box>
        </Flex>
      )}
    </Box>
  );
};
