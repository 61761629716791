import { Box } from "@chakra-ui/layout";
import React from "react";
import { ICustomField } from "module/sunday/space";

interface Props {
  field: ICustomField;
  option: any;
}

export const SelectorTrigger = ({ field, option }: Props) => {
  if (field.subtype === "status-button") {
    return (
      <Box
        cursor="pointer"
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        fontSize="16px"
        whiteSpace="nowrap"
        background={option.color}
        padding={"20px"}
      >
        {option.label}
      </Box>
    );
  }

  return (
    <Box
      cursor="pointer"
      display="flex"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      fontSize="16px"
      whiteSpace="nowrap"
      background={option.color}
    >
      {option.label}
    </Box>
  );
};
