import { Td, Tr, Text, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaEye } from "react-icons/fa";

interface Props {
  execution: any;
}

export const FlowExecutionItem = ({ execution }: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Tr>
        <Td>{execution.id}</Td>
        <Td>{execution.createdAt}</Td>
        <Td>
          <FaEye onClick={() => setExpanded(!expanded)} />
        </Td>
      </Tr>

      {expanded && (
        <Tr>
          <Td maxW={0} colSpan={3}>
            {execution.logs.map((log: any) => (
              <Flex
                paddingY="10px"
                borderBottom={"1px solid white"}
                flexDir={"row"}
              >
                <Text minW={0} flex={1}>
                  {log.content}
                </Text>
                <Text>{log.timestamp}</Text>
              </Flex>
            ))}
          </Td>
        </Tr>
      )}
    </>
  );
};
