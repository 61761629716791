import { Center, Text } from "@chakra-ui/layout";
import { Item } from "const/type";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useStore } from "store/RootStore";

interface Props {
  item: Item;
}

export const ItemFileUpload = ({ item }: Props) => {
  const { fileStore, itemStore } = useStore();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      // Do something with the files
      const fileId = await fileStore.upload(acceptedFiles[0], {
        itemId: item.id,
      });
      itemStore.addAttachment(item.id, fileId);
    },
    [itemStore, fileStore, item]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Center cursor="pointer" w="100%" {...getRootProps()}>
      <input {...getInputProps()} />
      <Text>放置文件</Text>
    </Center>
  );
};
