import React from "react";
import { ITaskAction } from "const/type";
import { Box, HStack, Text } from "@chakra-ui/layout";
import { Avatar } from "@chakra-ui/avatar";
import moment from "moment";
import { chakra } from "@chakra-ui/system";
import { observer } from "mobx-react-lite";

interface Props {
  actions?: ITaskAction[];
}

export const ItemActions = observer(({ actions = [] }: Props) => {
  return (
    <Box w="100%">
      {actions.map((action) => (
        <Box w="100%">
          {action.type === "change" && (
            <HStack padding="20px" justifyContent="space-between">
              <Text>
                {`${action.user?.email} changed`} <b>{action.data.key}</b> from{" "}
                <b>{action.data.from}</b> to <b>{action.data.to}</b>
              </Text>
              <Text>{moment(action.created_at).format("MM-DD HH:mm a")}</Text>
            </HStack>
          )}
          {action.type === "create" && (
            <HStack padding="20px" justifyContent="space-between">
              <Text>{`${action.user?.email} created the task`}</Text>
              <Text>{moment(action.created_at).format("MM-DD HH:mm a")}</Text>
            </HStack>
          )}
          {action.type === "comment" && (
            <HStack alignItems="flex-start" padding="20px">
              <Avatar cursor="pointer" size="sm" name={action.user?.username} />
              <Box
                background="gray.800"
                flexGrow={1}
                minW="0px"
                border="1px solid #1e272e"
                borderRadius="8px"
                padding="10px 20px"
              >
                <HStack justifyContent="space-between">
                  <Text>
                    <chakra.b>{action.user?.username}</chakra.b> commented
                  </Text>
                  <Text>
                    {moment(action.created_at).format("MM-DD HH:mm a")}
                  </Text>
                </HStack>

                <Text
                  dangerouslySetInnerHTML={{
                    __html: action.data?.comment,
                  }}
                ></Text>
              </Box>
            </HStack>
          )}
        </Box>
      ))}
    </Box>
  );
});
