import { Button } from "@chakra-ui/button";
import { Box, Center, Flex, FlexProps, Text } from "@chakra-ui/layout";
import { Progress } from "@chakra-ui/progress";
import { observer } from "mobx-react-lite";
import React from "react";
import { FaDatabase, FaFile, FaUser } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useStore } from "store/RootStore";

interface Props {}

const Card: React.FunctionComponent<any> = ({ children }) => {
  return (
    <Box
      borderRadius="10px"
      bg="gray.900"
      padding="20px"
      my={2}
      w="100%"
      display="flex"
      flexDir="column"
    >
      {children}
    </Box>
  );
};

const Section: React.FunctionComponent<FlexProps> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      borderTopWidth="1px"
      borderColor="gray.700"
      px="20px"
      mx="-20px"
      alignItems="center"
      flexDir="row"
      {...props}
    >
      {children}
    </Flex>
  );
};

export const Billing = observer((props: Props) => {
  const { formatMessage } = useIntl();
  const { teamStore, spaceStore } = useStore();
  return (
    <Flex p="3" w="100%" h="100%" flex={1} flexDir="column">
      <Card>
        <Text>{formatMessage({ id: "billing.current" })}</Text>
        <Flex justifyContent="space-between" alignItems="center" flexDir="row">
          <Text>{formatMessage({ id: "billing.free" })}</Text>
          <Button>升级到专业方案</Button>
        </Flex>

        <Text fontSize="12px" color="gray.400" mb={2}>
          {formatMessage(
            { id: "billing.see-pricing" },
            {
              href: <a href="https://sundayteamwork.com/pricing">价格</a>,
            }
          )}
        </Text>

        <Section h="50px" textAlign="right">
          <Text textAlign="left" width="300px">
            项目
          </Text>
          <Text flex={1}>数量</Text>
          <Text flex={1}>单位价格</Text>
          <Text flex={1}>总价格</Text>
        </Section>

        <Section h="50px" textAlign="right">
          <Text textAlign="left" width="300px">
            团队 (基础版)
          </Text>
          <Text flex={1}>{teamStore.users.length}</Text>
          <Text flex={1}>$0</Text>
          <Text flex={1}>${teamStore.users.length * 0}</Text>
        </Section>

        <Section justifyContent="space-between" h="80px" mb="-20px">
          <Text>{formatMessage({ id: "billing.estimated-cost" })}</Text>
          <Text fontSize={20}>$0.00</Text>
        </Section>
      </Card>

      <Card>
        <Flex mb={"20px"} alignItems="center">
          <Center bg="white" w="40px" h="40px">
            <FaDatabase color="black" />
          </Center>
          <Text ml="20px">储存空间使用情况</Text>
        </Flex>

        <Section h="50px">
          <Text width="200px">项目空间</Text>
          <Text flex={1}>无限制</Text>
          <Text flex={1}></Text>
        </Section>

        <Section h="50px">
          <Text width="200px">团队空间</Text>
          <Text flex={1}>{(spaceStore.spaces.length / 5) * 100}%</Text>
          <Flex flexDir="column" flex={1}>
            <Flex justifyContent="space-between">
              <Text>{spaceStore.spaces.length}</Text>
              <Text>5</Text>
            </Flex>
            <Progress value={(spaceStore.spaces.length / 5) * 100} />
          </Flex>
        </Section>
      </Card>

      <Card>
        <Flex mb={"20px"} alignItems="center">
          <Center bg="white" w="40px" h="40px">
            <FaUser color="black" />
          </Center>
          <Text ml="20px">成员</Text>
        </Flex>

        <Section h="50px">
          <Text width="200px">用户</Text>
          <Text flex={1}>{(teamStore.users.length / 5) * 100}%</Text>
          <Flex flexDir="column" flex={1}>
            <Flex justifyContent="space-between">
              <Text>{teamStore.users.length}</Text>
              <Text>5</Text>
            </Flex>
            <Progress value={(teamStore.users.length / 5) * 100} />
          </Flex>
        </Section>
      </Card>

      <Card>
        <Flex mb={"20px"} alignItems="center">
          <Center bg="white" w="40px" h="40px">
            <FaFile color="black" />
          </Center>
          <Text ml="20px">文件储存空间</Text>
        </Flex>

        <Section h="50px">
          <Text width="200px">储存空间</Text>
          <Text flex={1}>0%</Text>
          <Flex flexDir="column" flex={1}>
            <Flex justifyContent="space-between">
              <Text>0</Text>
              <Text>100Mb</Text>
            </Flex>
            <Progress value={0} />
          </Flex>
        </Section>
      </Card>
    </Flex>
  );
});
