import { Flex, FlexProps, HStack, Text } from "@chakra-ui/layout";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import cuid from "cuid";
import { ISpaceView } from "module/sunday/space";
import { useCurrentSpace } from "module/sunday/space/hooks";
import React from "react";
import { FaDatabase, FaFile, FaPlus, FaTable } from "react-icons/fa";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { useIntl } from "react-intl";
import { SpaceToolbarAddAddView } from "./SpaceToolbarAddAddView";

interface Props {}

export const SpaceToolbarAdd = (props: Props) => {
  return (
    <Popover isLazy>
      <PopoverTrigger>
        <HStack
          _hover={{
            bg: "gray.500",
          }}
          p="10px"
          cursor="pointer"
          alignItems="center"
        >
          <FaPlus />
          <Text ml="5px">添加视图</Text>
        </HStack>
      </PopoverTrigger>
      <PopoverContent w="600px">
        <SpaceToolbarAddAddView />
      </PopoverContent>
    </Popover>
  );
};
