import { action, makeObservable, reaction } from "mobx";
import { spaceStore } from "../space";
import { Item } from "const/type";
import { toast } from "util/toast";
import { queryClient } from "util/query";
import { SundayBaseStore } from "../store";
import { DocumentServiceClass } from "./DocumentService";
import { DocumentService } from "./DocumentService";
import cuid from "cuid";
import { teamStore } from "../team";

export interface IDocument {
  id: string;
  content: string;
  title: string;
  updatedAt: any;
  createdAt: any;
}

export class DocumentStore extends SundayBaseStore<
  IDocument,
  DocumentServiceClass
> {
  constructor() {
    super(DocumentService, "DocumentStore");

    makeObservable(this, {
      createNew: action,
    });
  }

  createNew = async () => {
    const id = cuid();

    const doc = await this.create({
      id,
      title: "New Document",
      content: "",
      teamId: teamStore.selectedTeam?.id,
    } as any);

    queryClient.invalidateQueries("docs");

    return doc;
  };

  updateContent = async () => {};
}

export const documentStore = new DocumentStore();
