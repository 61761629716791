import { Skeleton } from "@chakra-ui/skeleton";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams, useRouteMatch } from "react-router";
import { useStore } from "store/RootStore";
import { DocDetail } from "./DocDetail";
interface Props {}

export const DocumentLoader: React.FunctionComponent<Props> = observer(() => {
  const params = useParams();
  const id = (params as any).documentId;
  const { documentStore } = useStore();
  const { byId, byIdShouldUpdate, byIdLoading } = documentStore;
  useEffect(() => {
    if (id && (!byId[id] || byIdShouldUpdate[id])) {
      documentStore.addToLoader(id);
    }
  }, [documentStore, id, byId, byIdShouldUpdate]);

  return !byId[id] || byIdLoading[id] ? (
    <Skeleton width="100%" height="100%" />
  ) : (
    <DocDetail key={id} document={byId[id]} />
  );
});
