import { Center, Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { IoSettings } from "react-icons/io5";
import { useHistory, useRouteMatch } from "react-router";
import { RiTeamFill } from "react-icons/ri";
import { MdOutlineSpaceDashboard } from "react-icons/md";

interface Props {
  match: any;
}

interface TabItemProps {
  href: string;
  icon: React.ReactNode;
  title: string;
}

const TabItem: React.FunctionComponent<TabItemProps> = ({
  href,
  icon,
  title,
}) => {
  const history = useHistory();
  const match = useRouteMatch(href);
  const active = !!href && match;
  const handleClick = React.useCallback(() => {
    history.push(href);
  }, [history, href]);

  return (
    <Center
      onClick={handleClick}
      flexDir="column"
      flex={1}
      fontSize="lg"
      color={active ? "#3498db" : "gray.200"}
    >
      {icon}
      <Text mt="5px" fontSize="12px">
        {title}
      </Text>
    </Center>
  );
};

export const TabBar = ({ match }: Props) => {
  return (
    <Flex flexDir="row">
      {/* <TabItem
        href={`${match!.url}/tasks`}
        title="主页"
        icon={<BiHome fontSize="30px" />}
      /> */}
      <TabItem
        href={`${match!.url}/spaces`}
        title="空间"
        icon={<MdOutlineSpaceDashboard fontSize="30px" />}
      />
      <TabItem
        href={`${match!.url}/members`}
        title="团队"
        icon={<RiTeamFill fontSize="30px" />}
      />
      <TabItem
        href={`${match!.url}/settings`}
        title="设置"
        icon={<IoSettings fontSize="30px" />}
      />
    </Flex>
  );
};
