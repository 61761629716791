import { Box, Center } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { ItemBoard } from "component/ItemBoard";
import { observer } from "mobx-react-lite";
import { ItemService } from "module/sunday/item";
import { ISpaceView } from "module/sunday/space";
import { useCurrentSpace } from "module/sunday/space/hooks";
import { SpaceService } from "module/sunday/space/SpaceService";
import React, { useCallback, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStore } from "store/RootStore";

interface Props {
  view: ISpaceView;
}

export const BoardView = observer(({ view }: Props) => {
  const { spaceStore, configStore } = useStore();
  const queryClient = useQueryClient();
  const { space } = useCurrentSpace();

  const groupBy = view.groupBy || "status";

  const { data = [], isLoading } = useQuery(
    ["sundayTable", groupBy],
    () => {
      const orderBy: any[] = [];
      const select: any = [['item."updatedAt"', "updatedAt"]];

      if (groupBy) {
        select.push([`customData.${groupBy}`, "order_field"]);
      }
      return ItemService.query({
        select,
        where: {
          "space.key": spaceStore.viewingSpace!.key,
        },
        orderBy,
        groupBy,
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const update = useCallback(
    (id, value) => {
      const itemIndex = data.findIndex((item: any) => item.id === id);
      const newData = [...data];
      // console.log("Update", id, "value", value, "itemIndex", itemIndex);
      newData[itemIndex] = {
        ...newData[itemIndex],
        ...value,
      };
      queryClient.setQueryData(["sundayTable", groupBy], newData);
    },
    [groupBy, queryClient, data]
  );

  const columns = useMemo(() => {
    if (groupBy === "status") {
      return (
        space!.fields.find((field) => field.key === "status")?.options ||
        configStore.statuses
      );
    }
    return [];
  }, [groupBy, space, configStore]);

  const itemsByColumn: any = useMemo(() => {
    const byColumn: any = columns.reduce((acc, item) => {
      return {
        ...acc,
        [item.key]: [],
      };
    }, {});

    data.forEach((item: any) => {
      byColumn[item.order_field] && byColumn[item.order_field].push({
        id: item.id,
        updatedAt: item.updatedAt,
        sortIndex: item.sortIndex,
      });
    });

    Object.keys(byColumn).forEach((key) => {
      byColumn[key] = byColumn[key].sort((a: any, b: any) => {
        if (a.sortIndex && b.sortIndex) {
          return a.sortIndex > b.sortIndex ? 1 : -1;
        } else if (!a.sortIndex && b.sortIndex) {
          return 1;
        } else if (a.sortIndex && !b.sortIndex) {
          return -1;
        } else {
          return 0;
        }
      });
    });

    return byColumn;
  }, [data, columns]);

  return (
    <Box h="100%" w="100%" flex={1}>
      {/* <Button onClick={() => SpaceService.resetPositions(space.id)}>
        Reset Positions
      </Button> */}
      {isLoading && (
        <Center h="100%">
          <Spinner size="xl" />
        </Center>
      )}
      {!isLoading && (
        <ItemBoard
          groupBy={groupBy}
          update={update}
          columns={columns}
          itemsByColumn={itemsByColumn}
        />
      )}
    </Box>
  );
});
