import React from "react";
import { chakra, Box, Flex, Button, Stack, Heading } from "@chakra-ui/react";
import { SundayComponentType } from "@zhuxinai/frontend-core";
import { COMMON_DEFINITION } from "./common/definitions";
import { ChakraIcon } from "./icon";
import { IButton } from "./common/type";

type Props = {
  title: string;
  titleHighlight: string;
  primaryButton: IButton;
};

export const HeroWithBackground = ({
  title = "Title",
  titleHighlight = "Highlight",
  primaryButton,
}: Props) => {
  return (
    <Box
      w="full"
      h="container.sm"
      backgroundImage="url(https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80)"
      bgPos="center"
      bgSize="cover"
    >
      <Flex
        align="center"
        pos="relative"
        justify="center"
        boxSize="full"
        bg="blackAlpha.700"
      >
        <Stack textAlign="center" alignItems="center" spacing={6}>
          <Heading
            fontSize={["2xl", "initial", "3xl"]}
            fontWeight="semibold"
            color="white"
            textTransform="uppercase"
          >
            {title}{" "}
            <chakra.span color="blue.400" textDecor="underline">
              {titleHighlight}
            </chakra.span>
          </Heading>
          {primaryButton && <Button colorScheme="brand" textTransform="uppercase" w="fit-content">
            {primaryButton?.text}
          </Button>}
        </Stack>
      </Flex>
    </Box>
  );
};

(HeroWithBackground as SundayComponentType).config = {
  type: "chakra_hero_with_bg",
  label: "Hero With Background",
  layoutExtra: {
    minW: 12,
    minH: 5,
    w: 12,
    h: 5,
  },
  schema: {
    type: "object",
    definitions: COMMON_DEFINITION,
    properties: {
      title: {
        type: "string",
        title: "Title",
      },
      titleHighlight: {
        type: "string",
        title: "Title Highlight",
      },
      primaryButton: {
        title: "Primary Button",
        $ref: "#/definitions/button",
      },
    },
  },
  component: HeroWithBackground,
  icon: <ChakraIcon />,
};
