import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "store/RootStore";
import { SpaceCreateForm } from "./SpaceCreateForm";
import { SpaceCustomFields } from "./SpaceCustomFields";
import { SpaceSettings } from "./SpaceSettings";
import { SpaceUI } from "./SpaceUI";

interface Props {}

export const SpaceFieldEditor = observer((props: Props) => {
  const { spaceStore } = useStore();
  const isOpen = !!spaceStore.editingSpace;
  const { editingSpace } = spaceStore;

  const onClose = () => {
    spaceStore.setEditingSpace(null);
  };

  if (!editingSpace) {
    return null;
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent width="100%" maxWidth="800px">
        <DrawerCloseButton />
        <DrawerHeader>{`空间 - ${editingSpace?.name}`}</DrawerHeader>
        <DrawerBody>
          <Tabs h="100%" display="flex" flexDir="column">
            <TabList>
              <Tab>字段</Tab>
              <Tab>界面UI</Tab>
              <Tab>创建UI</Tab>
              <Tab>接入</Tab>
            </TabList>

            <TabPanels flex={1}>
              <TabPanel h="100%">
                <SpaceCustomFields space={editingSpace!} />
              </TabPanel>
              <TabPanel h="100%">
                <SpaceUI space={editingSpace!} />
              </TabPanel>
              <TabPanel h="100%">
                <SpaceCreateForm space={editingSpace!} />
              </TabPanel>
              <TabPanel h="100%">
                <SpaceSettings space={editingSpace!}/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>

        {/* <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue">Save</Button>
        </DrawerFooter> */}
      </DrawerContent>
    </Drawer>
  );
});
