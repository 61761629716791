import {
  Box,
  Portal,
  Input,
  HStack,
  Avatar,
  Text,
  AvatarProps,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { IUser } from "const/type";
import { useIntl } from "react-intl";

interface Props {
  onSelect?: (user: IUser) => void;
  assignedTo?: string;
  avatarProps?: AvatarProps;
}

export const AssignToButton = observer(
  ({ onSelect, assignedTo, avatarProps = {} }: Props) => {
    const initRef = useRef<any>();
    const { teamStore } = useStore();
    const { formatMessage } = useIntl();
    const { users, usersById } = teamStore;

    return (
      <Popover isLazy initialFocusRef={initRef}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              {assignedTo && usersById[assignedTo] ? (
                <Center>
                  <Avatar
                    cursor="pointer"
                    {...avatarProps}
                    name={
                      usersById[assignedTo].username ||
                      usersById[assignedTo].email
                    }
                  />
                </Center>
              ) : (
                <Box
                  cursor="pointer"
                  border="1px solid black"
                  width="40px"
                  height="40px"
                  borderRadius="20px"
                />
              )}
            </PopoverTrigger>
            <Portal appendToParentPortal={false}>
              <PopoverContent width="500px">
                <PopoverArrow />
                <PopoverCloseButton top="10px" />
                <PopoverBody>
                  <Input
                    focusBorderColor="none"
                    border="none"
                    ref={initRef}
                    placeholder={formatMessage({ id: "label.search" })}
                  />

                  {users.map((user) => (
                    <HStack
                      my="10px"
                      p="5px"
                      key={user.id}
                      onClick={() => {
                        onClose();
                        onSelect && onSelect(user);
                      }}
                      borderRadius="10px"
                      cursor="pointer"
                      _hover={{
                        backgroundColor: "gray.500",
                      }}
                    >
                      <Avatar name={user.username || user.email || "Unkown"} />
                      <Text>
                        {user.username || user.email}{" "}
                        {user.username && `(${user.email})`}
                      </Text>
                    </HStack>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    );
  }
);
