import {
    Box,
    Portal,
    Input,
    HStack,
    Avatar,
    Text,
    AvatarProps,
    Center,
  } from "@chakra-ui/react";
  import React, { useEffect, useMemo, useRef, useState } from "react";
  import {
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
  } from "@chakra-ui/popover";
  import { observer } from "mobx-react-lite";
  import { ICustomField } from "module/sunday/space";
  import { SelectorTrigger } from "./SelectorTrigger";
  
  interface Props {
    field: ICustomField;
    value: any;
    onChange: any;
  }
  
  export const SelectorField = observer(({ field, value, onChange }: Props) => {
    const optionsByKey = useMemo(() => {
      return (field.options || []).reduce((acc: any, item: any) => {
        acc[item.key] = item;
        return acc;
      }, {});
    }, [field]);
  
    const selectedOption = useMemo(() => {
      return (
        optionsByKey[value || field.defaultOption] ||
        (field.options || [])[0]
      );
    }, [optionsByKey, value, field]);
  
    return (
      <Popover isLazy>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Box width="100%" height="100%">
                <SelectorTrigger field={field} option={selectedOption} />
              </Box>
            </PopoverTrigger>
            <Portal appendToParentPortal={true}>
              <PopoverContent width="200px">
                <PopoverArrow />
                <PopoverBody>
                  {(field.options || []).map((option) => (
                    <HStack
                      cursor="pointer"
                      key={option.key}
                      onClick={() => {
                        onClose();
                        onChange(option.key);
                      }}
                      my="5px"
                    >
                      <Box
                        width="20px"
                        height="20px"
                        borderRadius="10px"
                        background={option.color}
                      />
                      <Text>{option.label}</Text>
                    </HStack>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    );
  });
  