import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import { IFlow } from "module/flow/store";
import React, { useCallback, useMemo, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import { IoMove } from "react-icons/io5";
import { useIntl } from "react-intl";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { ActionBox } from "./ActionBox";
import { ACTION_BY_KEY } from "./ActionBox/actions";
import { TriggerBox } from "./TriggerBox";

interface Props {
  flow?: IFlow;
  onSubmit: (value: any) => void;
}

const ActionDrag = SortableHandle(() => {
  return (
    <Box
      position={"absolute"}
      left="10px"
      top="25px"
    >
      <IoMove />
    </Box>
  );
});

const ActionSortableContainer = SortableContainer(({ children }: any) => {
  return <Box>{children}</Box>;
});

const ActionSortableItem = SortableElement(
  ({ children, setSelectedFieldId, fieldIndex, field, remove }: any) => {
    const [isOpened, setIsOpened] = useState(false);
    const intl = useIntl();
    const { control } = useFormContext();
    const actionType = useWatch({
      control,
      name: `actions.${fieldIndex}.type`,
    });

    return (
      <Box
        padding="20px"
        border="1px solid"
        borderColor="gray.700"
        background="gray.900"
        position="relative"
        my="10px"
        style={{ zIndex: 10000 }}
        borderRadius={"10px"}
        w="400px"
        cursor={"pointer"}
      >
        <ActionDrag />
        <Center
          onClick={() => {
            setIsOpened(!isOpened);
          }}
        >
          {actionType
            ? intl.formatMessage({
                id: `flow.action.label.${actionType}`,
              })
            : "选择一个工作流类型"}
        </Center>

        <Box onClick={() => {
          remove(fieldIndex)
        }} position={'absolute'} right='10px' top="25px">
          <FaTimes />
        </Box>

        {(isOpened || !actionType) && <ActionBox fieldIndex={fieldIndex} />}
      </Box>
    );
  }
);

export const FlowForm = ({ flow, onSubmit: onSubmitFromProps }: Props) => {
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const form = useForm<{
    actions: any[];
    triggerParams: any;
    triggerType: string;
  }>({
    defaultValues: {
      actions: flow?.steps?.[0]?.actions || [
        {
          key: "new",
          params: {},
          type: "",
        },
      ],
      triggerParams: flow?.triggerParams || {},
      triggerType: flow?.triggerType || "",
    },
  });
  const { control, handleSubmit } = form;
  const {
    fields = [],
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    update,
  } = useFieldArray<{
    actions: any[];
    triggerParams: any;
    triggerType: string;
  }>({
    control,
    name: "actions",
  });

  const hasExistingFlow = !!flow;

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    move(oldIndex, newIndex);
  };

  const onSubmit = useCallback(
    (values) => {
      onSubmitFromProps && onSubmitFromProps(values);
    },
    [onSubmitFromProps]
  );

  const selectedFieldIndex = useMemo(() => {
    return fields.findIndex((field) => field.id === selectedFieldId);
  }, [fields, selectedFieldId]);

  return (
    <FormProvider {...form}>
      <Flex w="100%" minH="0" flex={1} direction={"row"}>
        <Box overflowX={"hidden"} h="100%" flex={1} minH="0" overflowY={"auto"}>
          <Flex py="50px" w="full" alignItems={"center"} flexDir={"column"}>
            <Box position="relative">
              <TriggerBox />
            </Box>
            <Box
              height="30px"
              borderRightWidth="1px"
              borderRightColor="gray.700"
              borderRightStyle="dashed"
            ></Box>
            <ActionSortableContainer useDragHandle onSortEnd={onSortEnd}>
              {fields.map((field: any, fieldIndex: number) => (
                <ActionSortableItem
                  key={field.id}
                  index={fieldIndex}
                  fieldIndex={fieldIndex}
                  field={field}
                  remove={remove}
                  setSelectedFieldId={setSelectedFieldId}
                />
              ))}
            </ActionSortableContainer>

            <Button
              onClick={() => {
                append({
                  params: {},
                  type: "",
                });
              }}
              mt="20px"
              w="400px"
            >
              添加Action
            </Button>
          </Flex>
        </Box>

        <Box
          position={"absolute"}
          bottom="20px"
          right="20px"
          onClick={handleSubmit(onSubmit)}
        >
          <Button>{hasExistingFlow ? "保存" : "新增"}</Button>
        </Box>

        {false && (
          <Box height="100%" padding="20px" paddingBottom={"80px"}>
            <Box
              minW="420px"
              height="100%"
              borderRadius={"10px"}
              bg="gray.800"
              padding="10px"
            >
              {selectedFieldId && (
                <ActionBox
                  key={selectedFieldId}
                  fieldIndex={selectedFieldIndex}
                />
              )}
            </Box>
          </Box>
        )}
      </Flex>
    </FormProvider>
  );
};
