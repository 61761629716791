import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import {
  Flex,
  Box,
  Input,
  Text,
  VStack,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import { AssignToButton } from "component/AssignToButton";
import { SundayEditor } from "component/SundayEditor";
import { FieldModeEnum, ITask, Item } from "const/type";
import debounce from "lodash.debounce";
import moment from "moment";
import { ItemActions } from "./ItemActions";
import { ItemComment } from "./ItemComment";
import { TaskPriorityPopover } from "component/TaskPriorityPopover";
import { FaFlag } from "react-icons/fa";
import { useLocationQuery } from "hooks/useLocationQuery";
import { useLocation } from "react-router";
import { useCurrentSpace, useSpaceInfo } from "module/sunday/space/hooks";
import { SundayItemCell } from "component/SundayItemList/SundayItemCell";
import { ItemAttachments } from "./ItemAttachments";
import { ItemFileUpload } from "./ItemAttachments/ItemFileUpload";
import { useIsMobile } from "hooks/useDevice";

interface Props {
  item: Item;
}

export const MobileItemModalContent = observer(({ item }: Props) => {
  const { itemStore, configStore, spaceStore } = useStore();
  // const space = spaceStore.spacesById[item.spaceId!];
  const { space } = useCurrentSpace();
  const { fieldsByKey } = useSpaceInfo(space);

  return (
    <Flex direction="column" height="100%" overflowY={"auto"}>
      <Flex
        borderBottom="1px solid #1e272e"
        height="80px"
        direction="row"
        alignItems="center"
        padding="20px"
        bg="gray.800"
      >
        {(space.ui.head || []).map((key) => {
          return (
            <Center h="100%" mx="10px">
              <SundayItemCell item={item} field={fieldsByKey[key]} />
            </Center>
          );
        })}
      </Flex>
      <Flex padding="20px" flex="1" minH="0" direction="column">
        {(space.ui.body || []).map((key) => {
          return (
            <Center w="100%" my="10px">
              <SundayItemCell
                mode={FieldModeEnum.MODAL}
                item={item}
                field={fieldsByKey[key]}
              />
            </Center>
          );
        })}

        <ItemAttachments item={item} />
      </Flex>
      {/*<Flex
          w="100%"
          borderTop="1px solid #1e272e"
          height="60px"
          direction="row"
          alignItems="center"
          padding="30px"
          bg="gray.800"
        >
          <ItemFileUpload item={item} />
        </Flex> */}
      {/* <Flex
          w="100%"
          borderBottom="1px solid #1e272e"
          height="80px"
          direction="row"
          alignItems="center"
          padding="30px"
          bg="gray.800"
        >
          <VStack alignItems="flex-start">
            <Text fontWeight="bold">CREATED</Text>
            <Text mt="0px">
              {moment(item.createdAt).format("MMM DD, HH:mm a")}
            </Text>
          </VStack>
        </Flex>
        <Flex flex={1} minH="0" overflowY="auto">
          <ItemActions actions={item.actions} />
        </Flex> */}
      <ItemComment />
    </Flex>
  );
});

export const ItemModalContent = observer(({ item }: Props) => {
  const { itemStore, configStore, spaceStore } = useStore();
  // const space = spaceStore.spacesById[item.spaceId!];
  const { space } = useCurrentSpace();
  const { fieldsByKey } = useSpaceInfo(space);

  return (
    <Flex dir="row" height="100%">
      <Flex
        minW="0"
        flex="6"
        direction="column"
        borderRight="1px solid #1e272e"
      >
        <Flex
          borderBottom="1px solid #1e272e"
          height="80px"
          direction="row"
          alignItems="center"
          padding="20px"
          bg="gray.800"
        >
          {(space.ui.head || []).map((key) => {
            return (
              <Center h="100%" mx="10px">
                <SundayItemCell item={item} field={fieldsByKey[key]} />
              </Center>
            );
          })}
        </Flex>
        <Flex
          overflowY={"auto"}
          padding="20px"
          flex="1"
          minH="0"
          direction="column"
        >
          {(space.ui.body || []).map((key) => {
            return (
              <Center w="100%" my="10px">
                <SundayItemCell
                  mode={FieldModeEnum.MODAL}
                  item={item}
                  field={fieldsByKey[key]}
                />
              </Center>
            );
          })}

          <ItemAttachments item={item} />
        </Flex>
        <Flex
          w="100%"
          borderTop="1px solid #1e272e"
          height="60px"
          direction="row"
          alignItems="center"
          padding="30px"
          bg="gray.800"
        >
          <ItemFileUpload item={item} />
        </Flex>
      </Flex>
      <Flex flex="4" flexDirection="column">
        <Flex
          w="100%"
          borderBottom="1px solid #1e272e"
          height="80px"
          direction="row"
          alignItems="center"
          padding="30px"
          bg="gray.800"
        >
          <VStack alignItems="flex-start">
            <Text fontWeight="bold">创建时间</Text>
            <Text mt="0px">
              {moment(item.createdAt).format("MMM DD, HH:mm a")}
            </Text>
          </VStack>
        </Flex>
        <Flex flex={1} minH="0" overflowY="auto">
          <ItemActions actions={item.actions} />
        </Flex>
        <ItemComment />
      </Flex>
    </Flex>
  );
});

export const ItemModal = observer(() => {
  const { itemStore } = useStore();
  const item = itemStore.viewingItem!;
  const location = useLocation().search;
  const isMobile = useIsMobile();
  const query: any = useMemo(() => {
    return new URLSearchParams(location.substring(1));
  }, [location]);

  useEffect(() => {
    const itemId = query.get("itemId");
    if (itemId && itemStore.itemsHash[itemId!]) {
      itemStore.setViewingItemId(itemId);
    }
  }, [itemStore.itemsHash, query, itemStore]);

  return (
    <Modal
      trapFocus={false}
      isOpen={!!item}
      onClose={() => itemStore.setViewingItemId(null)}
    >
      <ModalOverlay />
      <ModalContent
        alignSelf="center"
        margin="0"
        width={{ base: "100vw", sm: "95vw" }}
        maxW={{ base: "100vw", sm: "95vw" }}
        height={{ base: "100vh", sm: "95vh" }}
      >
        <ModalCloseButton />
        <ModalBody height="100%" padding="0">
          {item &&
            (isMobile ? (
              <MobileItemModalContent item={item} />
            ) : (
              <ItemModalContent item={item} />
            ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
