import { ReactNode } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import { SundayComponentType } from "@zhuxinai/frontend-core";
import { COMMON_DEFINITION } from "./common/definitions";
import { ChakraIcon } from "./icon";

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={"h3"} fontSize={"xl"}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string;
  name: string;
  title: string;
}) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={"center"}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export const Testimonials = ({
  title,
  subtitle,
  testimonials = [],
}: {
  title: string;
  subtitle: string;
  testimonials: {
    title: string;
    content: string;
    image: string;
    name: string;
    position: string;
  }[];
}) => {
  return (
    <Box bg={useColorModeValue("gray.100", "gray.700")}>
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={"center"}>
          <Heading>{title}</Heading>
          <Text>{subtitle}</Text>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          {testimonials.map((testimonial) => (
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>{testimonial.title}</TestimonialHeading>
                <TestimonialText>{testimonial.content}</TestimonialText>
              </TestimonialContent>
              <TestimonialAvatar
                src={testimonial.image}
                name={testimonial.name}
                title={testimonial.position}
              />
            </Testimonial>
          ))}
          {/* <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Intuitive Design</TestimonialHeading>
              <TestimonialText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
                neque sed imperdiet nibh lectus feugiat nunc sem.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
              }
              name={'Jane Cooper'}
              title={'CEO at ABC Corporation'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Mindblowing Service</TestimonialHeading>
              <TestimonialText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
                neque sed imperdiet nibh lectus feugiat nunc sem.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
              }
              name={'Jane Cooper'}
              title={'CEO at ABC Corporation'}
            />
          </Testimonial> */}
        </Stack>
      </Container>
    </Box>
  );
};

(Testimonials as SundayComponentType).config = {
  type: "chakra_testimonials",
  label: "Testimonials",
  layoutExtra: {
    minW: 12,
    minH: 5,
    w: 12,
    h: 5,
  },
  schema: {
    type: "object",
    definitions: COMMON_DEFINITION,
    properties: {
      title: {
        type: "string",
        title: "Title",
      },
      subtitle: {
        type: "string",
        title: "Subtitle",
      },
      testimonials: {
        type: "array",
        title: "Testimationals",
        items: {
          type: "object",
          properties: {
            title: {
              type: "string",
              title: "Title",
            },
            content: {
              type: "string",
              title: "Content",
            },
            name: {
              type: "string",
              title: "Name",
            },
            image: {
              type: "image",
              title: "Avatar",
            },
            position: {
              type: "string",
              title: "Position",
            },
          },
        },
      },
    },
  },
  component: Testimonials,
  icon: <ChakraIcon />,
};
