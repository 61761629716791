import React, { useMemo } from "react";
import { Portal, Box } from "@chakra-ui/react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { observer } from "mobx-react-lite";
import { useCurrentSpace, useSpaceInfo } from "module/sunday/space/hooks";
import { useIntl } from "react-intl";

interface Props {
  onSelect?: (status: any) => void;
  groupBy?: string;
}

export const GroupByFilter = observer(({ onSelect, groupBy }: Props) => {
  const { space } = useCurrentSpace();
  const { fieldsByKey } = useSpaceInfo(space);
  const { formatMessage } = useIntl();
  const validGroupByFields = useMemo(() => {
    if (!space) {
      return [];
    } else {
      return space.fields.filter(
        (field) => !["text", "textarea"].includes(field.type)
      );
    }
  }, [space]);

  if (!space) {
    return null;
  }

  return (
    <Popover isLazy closeOnBlur>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Box cursor="pointer">
              {formatMessage({ id: "table.label.groupBy" })}:{" "}
              {groupBy ? fieldsByKey[groupBy]?.label : "无"}
            </Box>
          </PopoverTrigger>
          <Portal appendToParentPortal={true}>
            <PopoverContent width="200px">
              <PopoverArrow />
              <PopoverBody>
                <Box
                  onClick={() => {
                    onClose();
                    onSelect && onSelect(undefined);
                  }}
                >
                  无
                </Box>
                {validGroupByFields.map((field) => (
                  <Box
                    onClick={() => {
                      onClose();
                      onSelect && onSelect(field.key);
                    }}
                  >
                    {field.label}
                  </Box>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
});
