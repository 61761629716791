import * as React from "react";
import { Box, Center, Spinner } from "@chakra-ui/react";

import { useStore } from "store/RootStore";
import { observer } from "mobx-react-lite";
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useParams,
} from "react-router";
import { Team } from "screen/Team";
import { NewTeam } from "screen/NewTeam";

export const Dashboard = observer(() => {
  const { authStore, teamStore } = useStore();
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (
      teamStore.selectedTeam &&
      ["/dashboard", "/dashboard/new-team"].includes(location.pathname)
    ) {
      history.push(`/dashboard/team/${teamStore.selectedTeam.id}`);
    }
  }, [location.pathname, teamStore.selectedTeam, history]);

  return (
    <Box height="100vh" overflow="hidden" position="relative">
      {!authStore.isLoggedIn && <Redirect to="/auth" />}

      {teamStore.selectedTeam ? (
        <Route path="/dashboard/team/:teamId" component={Team}></Route>
      ) : (
        <Route path="/dashboard/team/:teamId">
          <Center>
            <Spinner />
          </Center>
        </Route>
      )}

      <Route path="/dashboard/new-team" component={NewTeam}></Route>
    </Box>
  );
});
