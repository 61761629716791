import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Route, RouteChildrenProps, Switch } from "react-router-dom";
import { BoardDetail } from "./BoardDetail";
import { BoardList } from "./BoardList";
import { SelectBoard } from "./SelectBoard";

type Props = {};

export const Boards: React.FC<Props & RouteChildrenProps> = ({ match }) => {
  return (
    <Flex flexDir={"row"} height="100%" flex="1">
      {/* <Flex
        height="100%"
        display={{ base: "none", lg: "flex" }}
        width="250px"
        direction="column"
        overflowY="auto"
        borderRightWidth="1px"
        borderLeftWidth="1px"
        p="6"
        bg="gray.900"
      >
        <BoardList />
      </Flex> */}

      <Box position={"relative"} flex={1} minW="0" h="100%">
        <Switch>
          <Route exact path={`${match?.url}`} component={SelectBoard} />
          <Route path={`${match?.url}/:boardId`} component={BoardDetail} />
        </Switch>
      </Box>
    </Flex>
  );
};
