import { Box, Flex, Text } from "@chakra-ui/layout";
import { IconButton, useColorMode } from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";
import { LanguageLabels, LanguageOptions } from "i18n";
import { observer } from "mobx-react-lite";
import React from "react";
import { FaGlobe, FaLanguage, FaMoon, FaSun } from "react-icons/fa";
import { useStore } from "store/RootStore";

interface Props {}

export const LanguageSwitcher = observer((props: Props) => {
  const { configStore } = useStore();
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Flex alignItems="center">
      <FaGlobe fontSize="20px" />
      <Select
        flex={1}
        ml="10px"
        minW="100px"
        value={configStore.language}
        onChange={(event) => configStore.setLanguage(event.target.value)}
      >
        {LanguageOptions.map((item) => (
          <option value={item.key}>{item.label}</option>
        ))}
      </Select>

      <Box>
        <IconButton
          aria-label="color-mode"
          onClick={toggleColorMode}
          icon={colorMode === "dark" ? <FaMoon /> : <FaSun />}
        />
      </Box>
    </Flex>
  );
});
