import { Box, Flex, Text } from "@chakra-ui/layout";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { Item } from "const/type";
import { ItemService } from "module/sunday/item";
import React from "react";
import { FaArchive, FaBoxOpen, FaTrash } from "react-icons/fa";
import { MdMoreHoriz } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useStore } from "store/RootStore";

interface Props {
  item: Item;
}

export const SundayItemMore = ({ item }: Props) => {
  const { itemStore } = useStore();
  const queryClient = useQueryClient();

  const handleDelete = async () => {
    await ItemService.delete(item.id);
    queryClient.invalidateQueries('sundayTable')
  }

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Box>
          <MdMoreHoriz />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <Flex flexDir="column">
          <Flex
            onClick={() => {
              itemStore.setViewingItemId(item.id);
            }}
            alignItems="center"
            p="3"
            w="100%"
            _hover={{ bg: "gray.800" }}
          >
            <Box mr="2">
              <FaBoxOpen />
            </Box>
            <Text>开启</Text>
          </Flex>
          <Box w="100%" h="1px" background="gray.800" />
          <Flex alignItems="center" p="3" w="100%" _hover={{ bg: "gray.800" }}>
            <Box mr="2">
              <FaArchive />
            </Box>
            <Text>归档</Text>
          </Flex>
          <Flex onClick={handleDelete} alignItems="center" p="3" w="100%" _hover={{ bg: "gray.800" }}>
            <Box mr="2">
              <FaTrash />
            </Box>
            <Text>删除</Text>
          </Flex>
        </Flex>
      </PopoverContent>
    </Popover>
  );
};
