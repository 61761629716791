import React, { useState, useMemo, useCallback } from "react";
import { Portal, Box, HStack, VStack, IconButton } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useCurrentSpace, useSpaceInfo } from "module/sunday/space/hooks";
import { useIntl } from "react-intl";
import { MdExpandMore } from "react-icons/md";
import QuickFilter from "./QuickFilter";
import AdvancedFilter from "./AdvancedFilter";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { API_BASE } from "api";
interface Props {
  setFilters: (filters: Array<any>) => void;
  filters: Array<any>;
}

export const Filter = observer(({ setFilters: setFiltersFromProps }: Props) => {
  const { space } = useCurrentSpace();

  const methods = useForm<{ filters: any[] }>({
    defaultValues: {
      filters: [],
    },
  });

  const setFilters = useCallback(
    (value) => {
      methods.setValue("filters", value);
      setFiltersFromProps(methods.getValues("filters"));
    },
    [methods, setFiltersFromProps]
  );

  const filters = useWatch({
    control: methods.control,
    name: "filters",
  });

  const { fields: filterFields } = useFieldArray<{
    filters: any[];
  }>({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: "filters", // unique name for your Field Array
  });

  useSpaceInfo(space);
  const validGroupByFields = useMemo(() => {
    if (!space) {
      return [];
    } else {
      return space.fields.filter((field) =>
        ["selector", "date"].includes(field.type)
      );
    }
  }, [space]);

  if (!space) {
    return null;
  }

  const filtersCount = useMemo(() => {
    let count = 0;
    filters
      .filter((f) => f.isSaved)
      .forEach((_filter) => {
        count += _filter.value.length;
      });
    return count;
  }, [filters]);

  const popoverHeight = validGroupByFields.length > 0 ? 320 : 80;

  return (
    <FormProvider {...methods}>
      <HStack>
        <QuickFilter
          filtersCount={filtersCount}
          popoverHeight={popoverHeight}
          validGroupByFields={validGroupByFields}
          setFilters={setFilters}
          filters={filterFields}
        />
        <AdvancedFilter
          popoverHeight={popoverHeight}
          validGroupByFields={validGroupByFields}
          setFilters={setFilters}
          filters={filterFields}
        />
      </HStack>
    </FormProvider>
  );
});
