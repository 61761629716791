import { Skeleton } from "@chakra-ui/skeleton";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "store/RootStore";
interface Props {
  id: string;
}

export const FileLoader: React.FunctionComponent<Props> = observer(
  ({ id, children }) => {
    const { fileStore } = useStore();
    const { filesById } = fileStore;
    useEffect(() => {
      if (!filesById[id]) {
        fileStore.addFileToLoader(id);
      }
    }, [filesById, fileStore, id]);

    return !filesById[id] ? (
      <Skeleton width="100%" height="65px" />
    ) : (
      <>{children}</>
    );
  }
);
