import {
  Center,
  Editable,
  EditableInput,
  EditablePreview,
  Spinner,
} from "@chakra-ui/react";
import { Board } from "component/Board";
import { EMPTY_BOARD } from "const/board";
import { useBoard, useBoardResource } from "graphql/useTeamBoards";
import React, { useCallback, useMemo, useRef } from "react";
import { useParams } from "react-router";
import { debounceFunction } from "util/debounce";
import { toast } from "util/toast";


type Props = {};

export const BoardDetailWithData = ({
  value,
  boardId,
}: {
  value: any;
  boardId: string;
}) => {
  const { update } = useBoardResource();

  const boardState = useRef({
    ...JSON.parse(JSON.stringify(value)),
    boardStates: {}
  });

  const onUpdate = useCallback(
    (data) => {
      update(boardId, {
        data: data.document,
      });
    },
    [boardId, update]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPersist = useCallback(debounceFunction(onUpdate, 2000), [onUpdate]);

  return (
    <>
      <Board value={boardState.current} onPersist={onPersist} />
    </>
  );
};

export const BoardDetail = (props: Props) => {
  const { boardId } = useParams<{ boardId: string }>();
  const { data = {}, loading: isLoading } = useBoard(boardId);
  const { sundayBoard = {} } = data;
  const { update } = useBoardResource();

  const boardValue = useMemo(() => {
    if (sundayBoard?.data && Object.keys(sundayBoard?.data).length !== 0) {
      return sundayBoard?.data;
    }
    return EMPTY_BOARD;
  }, [sundayBoard]);

  if (isLoading) {
    return (
      <Center>
        <Spinner></Spinner>
      </Center>
    );
  }

  if (!isLoading && boardValue) {
    return (
      <>
        <Editable
          position={"absolute"}
          left="10px"
          top="10px"
          zIndex="10"
          onSubmit={async (title) => {
            await update(boardId, {
              title: {
                set: title
              },
            });
            toast({
              title: "Title Updated",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }}
          fontSize="20px"
          defaultValue={sundayBoard?.title}
        >
          <EditablePreview
            borderWidth="1px"
            borderColor="transparent"
            p="2"
            _hover={{
              borderColor: "white",
            }}
          />
          <EditableInput />
        </Editable>
        <BoardDetailWithData boardId={boardId} value={boardValue} />
      </>
    );
  } else {
    return null;
  }
};
