import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { Sidebar } from "./Sidebar/Sidebar";

type Props = {
  match: any;
};

export const MobileDrawer = observer(({ match }: Props) => {
  const { uiStore } = useStore();
  return (
    <Drawer
      placement={"left"}
      onClose={() => {
        uiStore.setIsMobileDrawerOpen(false);
      }}
      isOpen={uiStore.isMobileDrawerOpen}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody padding="0px">
          <Box w="full" h="full">
            <Sidebar match={match} />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
});
