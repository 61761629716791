import React from "react";
import "./App.css";
import "jsoneditor-react/es/editor.min.css";
import { RootContext, rootStore } from "./store/RootStore";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "const/theme";
import { Routes } from "./Routes";
import { QueryClientProvider } from "react-query";
import { ApolloProvider } from "@apollo/client";
import { queryClient } from "util/query";
import { graphqlClient } from "util/apollo";
import { registerComponent } from "@zhuxinai/frontend-core/utils/register";
import * as Sites from "@zhuxinai/frontend-starter/components/Sites";

registerComponent(Object.values(Sites))

function App() {
  return (
    <ApolloProvider client={graphqlClient}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <RootContext.Provider value={rootStore}>
            <Routes />
          </RootContext.Provider>
        </ChakraProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export default App;
