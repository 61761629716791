import { Flex, FlexProps, Text } from "@chakra-ui/layout";
import cuid from "cuid";
import { useCurrentSpace } from "module/sunday/space/hooks";
import React from "react";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { FaFile, FaTable } from "react-icons/fa";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { useIntl } from "react-intl";
import { Table } from "./Views/Table";
import { ImEmbed, ImFileExcel } from "react-icons/im";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/react";
import { Board } from "./Views/Board";
import { File } from "./Views/File";
import { Embed } from "./Views/Embed";
import { Sheet } from "./Views/Sheet";
interface Props {}

const MenuItem: React.FunctionComponent<
  FlexProps & {
    icon: any;
    label: any;
    active: boolean;
  }
> = ({ icon, label, active, ...props }) => {
  return (
    <Flex
      w="100%"
      _hover={{
        bg: "gray.600",
      }}
      bg={active ? "gray.800" : "transparent"}
      flexDir="row"
      p="10px"
      cursor="pointer"
      alignItems="center"
      {...props}
    >
      {icon}
      <Text ml="15px">{label}</Text>
    </Flex>
  );
};

export const SpaceToolbarAddAddView = (props: Props) => {
  const { space, updateSpace } = useCurrentSpace();
  const intl = useIntl();

  const methods = useForm({
    defaultValues: {
      type: "table",
      label: "新的视图",
    },
  });

  const { control, register, setValue, watch, handleSubmit } = methods;

  const addView = (values: any) => {
    let viewToAdd: any = {
      key: cuid(),
      ...values,
    };
    if (values.type === "table" || values.type === "sheet") {
      viewToAdd.columns = space!.fields.map((field) => ({
        key: field.key,
      }));
    }
    if (values.type === "board") {
      viewToAdd.groupBy = "status";
    }
    updateSpace({
      views: [...space!.views, viewToAdd],
    });
  };

  const activeType = watch("type", "table");

  return (
    <FormProvider {...methods}>
      <Flex flexDir="row">
        <Flex flexDir="column" flex={1} p="20px">
          <Flex flex={1}>
            {activeType === "table" && <Table />}
            {activeType === "board" && <Board />}
            {activeType === "file" && <File />}
            {activeType === "embed" && <Embed />}
            {activeType === "sheet" && <Sheet />}
          </Flex>
          <Flex alignItems="center" w="100%" justifyContent="space-between">
            <Controller
              control={control}
              name="label"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    maxW="50%"
                    variant="flushed"
                    placeholder="新的视图名称"
                    size="sm"
                  />
                );
              }}
            />
            <Button onClick={handleSubmit(addView)}>创建</Button>
          </Flex>
        </Flex>
        <Flex flexDir="column" w="100px">
          <MenuItem
            active={activeType === "table"}
            onClick={() => setValue("type", "table")}
            icon={<FaTable />}
            label={"表格"}
          />
          <MenuItem
            active={activeType === "board"}
            onClick={() => setValue("type", "board")}
            icon={<MdOutlineSpaceDashboard />}
            label={"看板"}
          />
          <MenuItem
            active={activeType === "file"}
            onClick={() => setValue("type", "file")}
            icon={<FaFile />}
            label={"文件"}
          />
          <MenuItem
            active={activeType === "embed"}
            onClick={() => setValue("type", "embed")}
            icon={<ImEmbed />}
            label={"嵌入"}
          />
          <MenuItem
            active={activeType === "sheet"}
            onClick={() => setValue("type", "sheet")}
            icon={<ImFileExcel />}
            label={"Sheet"}
          />
          {/* <MenuItem icon={<FaDatabase />} label={"仪表盘"} /> */}
        </Flex>
      </Flex>
    </FormProvider>
  );
};
