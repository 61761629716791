import React from "react";
import { Tldraw } from "@tldraw/tldraw";

type Props = {
  onPersist: any;
  value?: any;
};

export const Board = ({ onPersist, value }: Props) => {
  return (
    <Tldraw
      document={value}
      onPersist={onPersist}
      showMenu={false}
      showMultiplayerMenu={false}
      showPages={false}
    />
  );
};
