import { Box, Center, CenterProps, Flex, Text } from "@chakra-ui/layout";
import { observer } from "mobx-react-lite";
import { ISpace } from "module/sunday/space";
import { useSpaceInfo } from "module/sunday/space/hooks";
import React, { useCallback, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFieldArray, useForm } from "react-hook-form";
import { useStore } from "store/RootStore";
import ReactDOM from "react-dom";

interface Props {
  space: ISpace;
}

const FieldLabel = ({
  item,
  label,
  index,
  containerProps = {},
}: {
  item: any;
  index: number;
  label: string;
  containerProps?: CenterProps;
}) => {
  const children = (provided: any, snapshot: any) => {
    const content = (
      <Box
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={provided.draggableProps.style}
        position="relative"
      >
        <Center {...containerProps}>{label}</Center>
      </Box>
    );
    return snapshot.isDragging
      ? ReactDOM.createPortal(content, document.body)
      : content;
  };

  return (
    <Draggable key={item} draggableId={`${item}`} index={index}>
      {children}
    </Draggable>
  );
};

export const SpaceCreateForm = observer(({ space }: Props) => {
  const { spaceStore } = useStore();
  const { fieldsByKey } = useSpaceInfo(space);

  const ui = useMemo(
    () => ({
      create: space.ui?.create.filter((item) => !!fieldsByKey[item]),
    }),
    [space.ui, fieldsByKey]
  );

  const unusedFields = useMemo(() => {
    return space.fields
      .filter((field) => !ui.create.includes(field.key))
      .map((item) => item.key);
  }, [ui, space.fields]);

  const onDragEnd = useCallback(
    async ({ source, destination }) => {
      try {
        if (destination && source) {
          if (
            destination.droppableId === "unused" &&
            source.droppableId !== "unused"
          ) {
            spaceStore.updateSpace({
              id: space.id,
              ui: {
                ...space.ui,
                [source.droppableId]: (ui as any)[source.droppableId].filter(
                  (_: any, index: number) => index !== source.index
                ),
              },
            });
          } else if (
            destination.droppableId !== "unused" &&
            source.droppableId === "unused"
          ) {
            const newArray = [...(ui as any)[destination.droppableId]];
            newArray.splice(destination.index, 0, unusedFields[source.index]);
            spaceStore.updateSpace({
              id: space.id,
              ui: {
                ...space.ui,
                [destination.droppableId]: newArray,
              },
            });
          } else {
            const newArray = [...(ui as any)[destination.droppableId]];
            const [itemToMove] = newArray.splice(source.index, 1);
            newArray.splice(destination.index, 0, itemToMove);
            spaceStore.updateSpace({
              id: space.id,
              ui: {
                ...space.ui,
                [source.droppableId]: newArray,
              },
            });
          }
        }
      } catch {}
    },
    [unusedFields, spaceStore, space, ui]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Text>字段 (未使用)</Text>
      <Droppable direction="horizontal" droppableId={"unused"}>
        {(provided, snapshot) => (
          <Flex
            {...provided.droppableProps}
            ref={provided.innerRef}
            my="20px"
            h="80px"
            direction="row"
            alignItems="center"
            border="1px solid #1e272e"
          >
            {(unusedFields || []).map((item, index) => {
              const field = fieldsByKey[item];
              return (
                <FieldLabel
                  containerProps={{
                    fontSize: 12,
                    mx: 2,
                    borderRadius: "5px",
                    padding: "5px",
                    bg: "gray.600",
                    color: "white",
                  }}
                  label={field.label}
                  item={item}
                  index={index}
                />
              );
            })}
          </Flex>
        )}
      </Droppable>
      <Droppable droppableId={"create"}>
        {(provided, snapshot) => (
          <Flex
            padding="20px"
            flex="1"
            minH="0"
            direction="column"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {ui.create.map((item, index) => {
              const field = fieldsByKey[item];
              return (
                <FieldLabel
                  containerProps={{
                    fontSize: 12,
                    my: 2,
                    borderRadius: "5px",
                    padding: "5px",
                    bg: "gray.600",
                    color: "white",
                  }}
                  label={field.label}
                  item={item}
                  index={index}
                />
              );
            })}
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  );
});
