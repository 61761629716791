import { API } from "api";
import { SundayService } from "../service";

class InviteServiceClass extends SundayService {
  getInviteInfoFromToken = async (token: string) => {
    const { data } = await API.get(`/sunday-invitations/get-info/${token}`);
    return data;
  };

  getInviteToken = async (teamId: string) => {
    const { data } = await API.get(`/sunday-teams/${teamId}/inviteToken`);
    return data;
  };
}

export const InviteService = new InviteServiceClass("sunday-invitations");
