import { Box, Flex, Stack } from "@chakra-ui/react";
import { ListView } from "@zhuxinai/frontend-core/components/ListView";

type Props = {
  blocks: any[];
  setBlocks: (blocks: any[]) => void;
};

export const Builder = ({ blocks, setBlocks }: Props) => {
  return (
    <Flex h="full" w="full" flexDir={"row"}>
      <ListView blocks={blocks} onChange={setBlocks} />
    </Flex>
  );
};
