import { Switch, Text } from "@chakra-ui/react";
import { useCurrentSpace, useSpaceInfo } from "module/sunday/space/hooks";
import React, { useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import { FlowSelect } from "../FlowSelect";
import { FlowControllerProps } from "./const";

export const Selector: React.FunctionComponent<FlowControllerProps> = ({
  fieldName,
  field,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
  });
  const optionsFromProps = field.options || [];
  const options = useMemo(() => {
    return optionsFromProps.map((field: any) => ({
      value: field.value,
      label: field.label,
    }));
  }, [optionsFromProps]);

  const activeOption = useMemo(() => {
    return options.find((option: any) => option.value === value);
  }, [options, value]);

  return (
    <div>
      <FlowSelect
        options={options}
        selected={
          activeOption ? (
            <Text>{activeOption.label}</Text>
          ) : (
            <Text>Select A Field</Text>
          )
        }
        onSelect={(option: any) => {
          onChange(option.value);
        }}
      />
    </div>
  );
};
