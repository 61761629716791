import { FieldModeEnum, Item } from "const/type";
import { ICustomField } from "module/sunday/space";
import { TextCreate } from "./Text/TextCreate";
import { TextAreaCreate } from "./TextArea/TextAreaCreate";

export interface FieldProps {
  field: ICustomField;
  item: Item;
  mode?: FieldModeEnum;
}

export const FIELD_TYPES = [
  {
    key: "selector",
    label: "Selector",
  },
  {
    key: "assign",
    label: "Assign",
  },
  {
    key: "text",
    label: "Text",
  },
  {
    key: "textarea",
    label: "TextArea",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "file",
    label: "File",
  },
  {
    key: "checklist",
    label: "Checklist",
  },
];

export const SELECTOR_FIELD_TYPES = [
  {
    key: "status-button",
    label: "Status Button",
  },
];
