import { Item } from "const/type";
import localforage from "localforage";
import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import moment from "moment";
import { toast } from "util/toast";
import { SundayService } from "./service";

interface SundayBaseType {
  id: string;
  updatedAt: any;
}

export abstract class SundayBaseStore<
  Type extends SundayBaseType,
  Service extends SundayService
> {
  service: Service;

  constructor(service: Service, name: string) {
    this.service = service;

    makeObservable(this, {
      byId: observable,
      toLoad: observable,
      loadId: action,
      loadIds: action,
      addToLoader: action,
      create: action,
    });

    makePersistable(
      this,
      {
        name,
        properties: ["byId"],
        storage: localforage,
        expireIn: 86400000, // One day in millsesconds
        removeOnExpiration: true,
        stringify: false,
      },
      { delay: 200, fireImmediately: false }
    );
  }

  byIdShouldUpdate: {
    [key: string]: boolean;
  } = {};

  byId: {
    [key: string]: Type;
  } = {};
  byIdLoading: {
    [key: string]: boolean;
  } = {};

  toLoad: string[] = [];
  toLoadDebounceTimeout: any;

  init = () => {};

  loadId = async (id: string) => {
    if (!this.byIdLoading[id]) {
      this.byIdLoading = { ...this.byIdLoading, [id]: true };
      const item: any = await this.service.getOne(id);
      delete this.byIdLoading[id];
      if (item) {
        this.byId = {
          ...this.byId,
          [id]: item,
        };
      }
    }
  };

  addToLoader = (id: string) => {
    if (this.toLoad.includes(id) || !!this.byIdLoading[id]) {
      return;
    }
    this.toLoad.push(id);
    if (this.toLoadDebounceTimeout) {
      clearTimeout(this.toLoadDebounceTimeout);
    }
    this.toLoadDebounceTimeout = setTimeout(() => {
      this.loadIds(this.toLoad);
      this.toLoad = [];
    }, 100);
  };

  loadIds = async (ids: string[]) => {
    const loadingIds = ids.filter((id) => !this.byIdLoading[id]);

    loadingIds.forEach((id) => {
      this.byIdLoading = { ...this.byIdLoading, [id]: true };
    });
    const items: Item[] = await this.service.get({
      where: {
        id: {
          in: loadingIds,
        },
      },
    });
    loadingIds.forEach((id) => {
      this.byIdLoading = { ...this.byIdLoading, [id]: false };
      this.byIdShouldUpdate = { ...this.byIdShouldUpdate, [id]: false };
    });

    const newHash: any = {};
    items.forEach((item: Item) => {
      newHash[item.id] = item;
    });
    this.byId = {
      ...this.byId,
      ...newHash,
    };
  };

  create = async (item: Partial<Type>) => {
    const savedItem = await this.service.create(item);
    toast({
      title: "创建成功",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    this.byId = {
      ...this.byId,
      [item.id!]: savedItem,
    };
  };

  update = async (item: Partial<Type>) => {
    this.byId = {
      ...this.byId,
      [item.id!]: {
        ...this.byId[item.id!],
        ...item,
      },
    };
    this.service.update(item.id!, item).catch(() => {
      toast({
        title: "We spot some internet issues",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  checkForShouldUpdate = (list: Type[] = []) => {
    list.forEach((item) => {
      if (item.updatedAt) {
        // console.log(item.updatedAt);
        const currentItem = this.byId[item.id];
        // console.log(currentItem.updatedAt);
        if (
          currentItem &&
          moment(currentItem.updatedAt).isBefore(item.updatedAt)
        ) {
          this.byIdShouldUpdate[item.id] = true;
          this.addToLoader(item.id);
        }
      }
    });
  };

  fetch = async (query: any = {}) => {};
}
