import { Center, Text } from "@chakra-ui/layout";
import { Item } from "const/type";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useStore } from "store/RootStore";

interface Props {}

export const FileUploader = ({}: Props) => {
  const { fileStore } = useStore();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      await fileStore.upload(acceptedFiles[0]);
    },
    [fileStore]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Center
      w="100%"
      borderColor="white"
      borderStyle="dashed"
      borderWidth="2px"
      p="50px"
      borderRadius="10px"
      cursor="pointer"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Text>放置文件</Text>
    </Center>
  );
};
