import { Box, Center, Flex, Spinner, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Route, useHistory, useRouteMatch } from "react-router";
import { useEffectOnce } from "react-use";
import PagesSidebar from "screen/Team/Sidebar/PagesSidebar/PagesSidebar";
import { PageDetail } from "./PageDetail";
import { SitePageDetail } from "./SitePageDetail";
import { useTeamPages, usePageResource } from "graphql/useTeamPages";
import cuid from "cuid";

type Props = {};

export const PagesWithData = () => {
  const match = useRouteMatch();

  return (
    <Flex h="full" w="full" flexDir={"row"}>
      <Box flex={1} minW='0px' overflowY={"auto"}>
        <Route path={`${match?.url}/grid/:pageId`} component={PageDetail} />
        <Route path={`${match?.url}/site/:pageId`} component={SitePageDetail} />
      </Box>
    </Flex>
  );
};


export const Pages = () => {
  const [isReady, setIsReady] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();
  const { create } = usePageResource();
  const { loading } = useTeamPages({
    onCompleted: async (result: any) => {
      if (result.sundayPages.length === 0) {
        await create({
          title: "home",
          data: {
            blocks: [],
            pageType: 'list'
          },
          path: cuid(),
        })
      }
      else {
        history.push(`${match?.url}/site/${result.sundayPages[0].id}`)
      }
      setIsReady(true);
    }
  });

  if (!isReady) {
    return <Center p="50px">
      <Spinner />
    </Center>
  }

  return (
    <PagesWithData />
  );
};
