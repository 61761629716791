import React from "react";
import { Input } from "@chakra-ui/input";
import { ICustomField } from "module/sunday/space";
import { useController, useFormContext } from "react-hook-form";
import { SundayEditor } from "component/SundayEditor";

interface Props {
  field: ICustomField;
}

export const TextAreaCreate = ({ field }: Props) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, name, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name: field.key,
    control,
    defaultValue: "",
  });
  return <SundayEditor value={value} onChange={onChange} />;
};
