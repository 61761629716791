import { API } from "api";

class AuthServiceClass {
  getUser = async () => {
    return API.get("/auth/profile");
  };

  signIn = async (data: any, options?: any) => {
    if (data.provider) {
      let href = `${process.env.REACT_APP_API_ENDPOINT}/oauth/${data.provider}`;
      if (options?.state) {
        href += `?state=${options.state}`;
      }
      window.location.href = href;
      return {};
    } else {
      const { data: user } = await API.post("/auth/login", data);
      return user;
    }
  };

  signUp = async ({ email, password }: { email: string; password: string }) => {
    return API.post("/auth/signup", {
      email,
      password,
    });
  };

  signOut = async () => {};
}

export const AuthService = new AuthServiceClass();
