import { FieldModeEnum, ITask, Item } from "const/type";
import { Selector } from "field";
import { Assign } from "field/Assign";
import { Date } from "field/Date";
import { Text } from "field/Text";
import { observer } from "mobx-react-lite";
import React from "react";
import { ICustomField } from "module/sunday/space";
import { File } from "field/File";
import { Checklist } from "field/Checklist";
import { TextArea } from "field/TextArea";

interface Props {
  field: ICustomField;
  item: Item;
  mode?: FieldModeEnum;
}

export const SundayItemCustomCell = observer(
  ({ field, item, mode = FieldModeEnum.TABLE }: Props) => {
    return (
      <>
        {field.type === "selector" && (
          <Selector field={field} item={item} mode={mode} />
        )}
        {field.type === "assign" && (
          <Assign field={field} item={item} mode={mode} />
        )}
        {field.type === "text" && (
          <Text field={field} item={item} mode={mode} />
        )}
        {field.type === "textarea" && <TextArea field={field} item={item} />}
        {field.type === "date" && (
          <Date field={field} item={item} mode={mode} />
        )}
        {field.type === "file" && (
          <File field={field} item={item} mode={mode} />
        )}
        {field.type === "checklist" && (
          <Checklist field={field} item={item} mode={mode} />
        )}
      </>
    );
  }
);
