import { Image } from "@chakra-ui/image";
import { Box, Center, Flex, HStack, Text } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import { FieldModeEnum } from "const/type";
import { FileLoader } from "module/file/component/FileLoader";
import { FileView } from "module/file/component/FileView";
import { IFile } from "module/file/store";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FaFileImage, FaFilePdf, FaTrash } from "react-icons/fa";
import { useStore } from "store/RootStore";
import { humanFileSize } from "util/file";

interface Props {
  value: string;
  onChange: any;
  mode?: FieldModeEnum;
}

const FileDisplay = ({ file, onReset }: { file: IFile; onReset: any }) => {
  return (
    <Flex
      p="10px"
      flexDir="row"
      position="relative"
      role="group"
      alignItems="center"
      cursor="pointer"
    >
      {file.contentType.includes("image") && <FaFileImage fontSize="20px" />}
      {file.contentType.includes("pdf") && <FaFilePdf fontSize="20px" />}
      <Tooltip label={file.name}>
        <Text
          overflow="hidden"
          maxW="60px"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {file.name}
        </Text>
      </Tooltip>
      <Box flex={1}></Box>
      <Text
        _groupHover={{ display: "none" }}
        textAlign="center"
        color="gray.300"
      >
        {humanFileSize(+file.size)}
      </Text>
      <HStack
        alignItems="center"
        justifyContent="center"
        display="none"
        _groupHover={{ display: "flex" }}
      >
        <Box onClick={onReset} color="red.200" cursor="pointer">
          <FaTrash />
        </Box>
      </HStack>
    </Flex>
  );
};

export const FileField = ({ value, onChange, mode }: Props) => {
  const { fileStore } = useStore();

  const selectFile = useCallback(async () => {
    const file = await fileStore.chooseFile();
    onChange(file.id);
  }, [fileStore, onChange]);

  const resetFile = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const file = fileStore.filesById[value];

  return !value ? (
    <Center onClick={selectFile} cursor="pointer" w="100%">
      <Text>放置文件/点击上传</Text>
    </Center>
  ) : (
    <>
      <FileLoader id={value}>
        {file && <FileDisplay onReset={resetFile} file={file} />}
      </FileLoader>
    </>
  );
};
