import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FaChevronDown } from "react-icons/fa";
import { useIntl } from "react-intl";
import { FlowController } from "../../FlowController/const";
import { FlowField } from "../../FlowField";
import { FlowSelect } from "../../FlowSelect";
import { TRIGGERS, TRIGGER_BY_KEY } from "./triggers";

interface Props {}

export const TriggerBox = (props: Props) => {
  const intl = useIntl();
  const { control, setValue } = useFormContext();
  const triggerType = useWatch({
    control,
    name: "triggerType",
  });

  const triggerParams = useWatch({
    control,
    name: "triggerParams",
  });

  return (
    <Box
      border="1px solid"
      borderColor="gray.700"
      background="gray.900"
      w="400px"
      borderRadius={"10px"}
      padding="20px"
    >
      <FlowSelect
        options={TRIGGERS.map((trigger) => ({
          value: trigger.key,
          label: intl.formatMessage({
            id: `flow.trigger.label.${trigger.key}`,
          }),
        }))}
        selected={
          !!triggerType ? (
            <Text>
              {intl.formatMessage({
                id: `flow.trigger.label.${triggerType}`,
              })}
            </Text>
          ) : (
            <Text>选择一个触发</Text>
          )
        }
        onSelect={(option: any) => {
          setValue("triggerType", option.value);
        }}
      />

      {!!triggerType &&
        (TRIGGER_BY_KEY[triggerType].fields || []).map((field: any) => (
          <FlowField
            key={field.key}
            label={intl.formatMessage({
              id: `flow.trigger.label.${TRIGGER_BY_KEY[triggerType].key}.${field.key}`,
              defaultMessage: field.label,
            })}
            fieldName={`triggerParams.${field.key}`}
            field={field}
            params={triggerParams}
          />
        ))}
    </Box>
  );
};
