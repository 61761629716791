import { FieldProps } from "field";
import React from "react";
import { useStore } from "store/RootStore";
import { FileField } from "./FileField";

interface Props extends FieldProps {}

export const File = ({ item, field, mode }: Props) => {
  const { itemStore } = useStore();
  const dateValue = item.customData?.[field.key];
  return (
    <FileField
      mode={mode}
      value={dateValue}
      onChange={(fileId: any) => {
        itemStore.saveItem({
          id: item.id,
          customData: {
            [field.key]: fileId,
          },
        });
      }}
    />
  );
};
