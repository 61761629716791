import { Button, Flex, HStack, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { BiPlus } from "react-icons/bi";
import { useRouteMatch } from "react-router";
import { useStore } from "store/RootStore";
import TablesSidebarItem from "./TablesSidebarItem";

interface TablesSidebarProps {
  match?: any;
}

const TablesSidebar: React.FC<TablesSidebarProps> = observer(() => {
  const { spaceStore } = useStore();
  const match = useRouteMatch();

  return (
    <VStack>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        mb="20px"
      >
        <Button
          width="100%"
          fontSize={"12px"}
          my={"10px"}
          onClick={() => {
            spaceStore.setShouldShowCreateSpaceModal(true);
          }}
        >
          <BiPlus />
          添加
        </Button>
      </HStack>
      {spaceStore.spaces.map((space) => (
        <Flex width="100%" key={space.id}>
          <TablesSidebarItem
            href={`${match!.url}/${space.id}`}
            space={space}
          />
        </Flex>
      ))}
    </VStack>
  );
});

export default TablesSidebar;
