import React, { useCallback, useRef, useState } from "react";
import { Input } from "@chakra-ui/input";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { Box, Flex, HStack } from "@chakra-ui/layout";
import { SundayEditor } from "component/SundayEditor";
import { Button } from "@chakra-ui/button";
import Placeholder from "@tiptap/extension-placeholder";
import { useOutsideClick } from "@chakra-ui/hooks";

interface Props {}

export const ItemComment = observer((props: Props) => {
  const { itemStore, fileStore } = useStore();
  const ref = useRef<any>();
  const editorRef = useRef<any>();

  const [focus, setFocus] = useState(false);
  const [comment, setComment] = useState("");

  const handleOnSubmit = useCallback(async () => {
    await itemStore.comment(itemStore.viewingItem!, comment);
    setComment("");
  }, [comment, itemStore]);

  const handleChange = useCallback((value) => {
    setComment(value);
  }, []);

  const onPasteCapture = useCallback(
    async (event) => {
      const item = event.clipboardData.items[0];
      if (item) {
        const file = item.getAsFile();
        const fileId = await fileStore.upload(
          file,
          {
            itemId: itemStore.viewingItem!.id,
          },
          (url: string) => {
            editorRef.current?.addImage(url);
          }
        );
        itemStore.addAttachment(itemStore.viewingItem!.id, fileId);
      }
    },
    [itemStore, fileStore, editorRef]
  );

  useOutsideClick({
    ref: ref,
    handler: () => setFocus(false),
  });

  return (
    <Flex
      ref={ref}
      p="2"
      bg="gray.800"
      flexDir="column"
      h={focus ? "200px" : "60px"}
      minH={focus ? "200px" : "60px"}
      width="100%"
    >
      <SundayEditor
        ref={editorRef}
        boxProps={{ flex: 1, border: "none", padding: "5px" }}
        onFocus={() => setFocus(true)}
        value={comment}
        onChange={handleChange}
        placeholder="添加评论"
        editorContentProps={{
          onPasteCapture,
        }}
      />

      {focus && (
        <HStack minH="50px">
          <Box flex={1}></Box>
          <Button onClick={handleOnSubmit}>保存</Button>
        </HStack>
      )}
    </Flex>
  );
});
