import { Box, Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { Route, useRouteMatch } from "react-router";
import { FlowDetail } from "./FlowDetail";
import { FlowList } from "./FlowList";

type Props = {};

export const Flows = (props: Props) => {
  const match = useRouteMatch();

  return (
    <Flex h="full" w="full" flexDir={'row'}>
      <Box
        h="full"
        w={{ base: "200px", xs: "100%" }}
        bg="gray.900"
        color="white"
        fontSize="sm"
        borderRight={'1px solid'} 
        borderRightColor="gray.700"
      >
        <Flex h="full" direction="column" px="4" py="4">
          <Stack spacing="8" flex="1" overflow="auto" pt="8">
            <FlowList />
          </Stack>
        </Flex>
      </Box>
      <Box flex={1} minW='0px' overflowY={'auto'} padding="20px">
        <Route path={`${match?.url}/:flowId`} component={FlowDetail} />
      </Box>
    </Flex>
  );
};
