import React from "react";
import { Image } from "@chakra-ui/image";
import { Box, Center, Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import { Progress } from "@chakra-ui/progress";
import { Spinner } from "@chakra-ui/spinner";
import { observer } from "mobx-react-lite";
import { HiRefresh } from "react-icons/hi";
import { useStore } from "store/RootStore";
import { FaFilePdf, FaTrash } from "react-icons/fa";
import { humanFileSize } from "util/file";
import { Tooltip } from "@chakra-ui/react";
import { FileView } from "./FileView";

interface Props {
  id: string;
}

export const FilePreview = observer(({ id }: Props) => {
  const { fileStore } = useStore();
  const file = fileStore.filesById[id];
  if (!file) {
    return null;
  }

  return (
    <Center
      borderRadius="10px"
      _hover={{
        bg: "gray.800",
      }}
      p="20px"
      h="180px"
    >
      {file.uploading && (
        <VStack>
          <Spinner />
          <Progress value={file.progress || 0} />
        </VStack>
      )}

      {file.failed && (
        <VStack>
          <Box
            cursor="pointer"
            onClick={() => {
              fileStore.reUpload(id);
            }}
          >
            <HiRefresh />
          </Box>
          <Text>Upload Failed</Text>
          <Text>{file.name}</Text>
        </VStack>
      )}

      {!file.uploading && !file.failed && (
        <FileView
          id={id}
          onDelete={() => {
            fileStore.remove(file);
          }}
        />
      )}
    </Center>
  );
});
