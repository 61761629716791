import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Box,
  Button,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FlowForm } from "module/flow/component/FlowForm";
import React, { useCallback } from "react";
import { useStore } from "store/RootStore";
import { FaChevronLeft } from "react-icons/fa";
import { toJS } from "mobx";
import { useMutation } from "react-query";
import { SpaceService } from "module/sunday/space/SpaceService";
import { FlowExecution } from "module/flow/component/FlowExecution";

interface Props {}

export const SpaceAutomation = observer((props: Props) => {
  const { spaceStore } = useStore();

  const { mutateAsync: createAsync } = useMutation((values: any) =>
    SpaceService.createFlow(spaceStore.viewingSpaceId!, values)
  );

  const { mutateAsync: updateAsync } = useMutation((values: any) =>
    SpaceService.updateFlow(spaceStore.editingFlow?.id, values)
  );

  const { mutateAsync: triggerAsync } = useMutation(() =>
    SpaceService.triggerFlow(spaceStore.editingFlow?.id)
  );

  const handleCreate = useCallback(
    async (values) => {
      await createAsync({
        triggerParams: values.triggerParams,
        triggerType: values.triggerType,
        steps: [
          {
            name: "action",
            actions: values.actions,
          },
        ],
      });
    },
    [createAsync]
  );

  const handleSave = useCallback(
    async (values) => {
      await updateAsync({
        triggerParams: values.triggerParams,
        triggerType: values.triggerType,
        steps: [
          {
            name: "action",
            actions: values.actions,
          },
        ],
      });
    },
    [updateAsync]
  );

  const handleTrigger = useCallback(async () => {
    await triggerAsync();
  }, [triggerAsync]);

  return (
    <Modal
      trapFocus={false}
      onClose={() => {
        spaceStore.setEditingFlow(null);
        spaceStore.setIsCreatingFlow(false);
        spaceStore.setIsFlowModalOpen(false);
      }}
      isOpen={spaceStore.isFlowModalOpen}
    >
      <ModalOverlay />
      <ModalContent
        alignSelf="center"
        margin="0"
        width="95vw"
        maxW="95vw"
        height="90vh"
      >
        <ModalCloseButton />
        <ModalBody
          display={"flex"}
          flexDir={"column"}
          height="100%"
          padding="0"
        >
          {!spaceStore.editingFlow && !spaceStore.isCreatingFlow && (
            <Flex h="100%">
              {/* <FlowList
                onClickCreate={() => {
                  spaceStore.setIsCreatingFlow(true);
                }}
                onSelectFlow={(flow: any) => {
                  spaceStore.setEditingFlow(flow);
                }}
                spaceId={spaceStore.viewingSpaceId!}
              /> */}
            </Flex>
          )}

          {spaceStore.isCreatingFlow && (
            <>
              <Box
                cursor={"pointer"}
                onClick={() => {
                  spaceStore.setIsCreatingFlow(false);
                }}
                p="20px"
              >
                <FaChevronLeft />
              </Box>
              <FlowForm onSubmit={handleCreate} />
            </>
          )}

          {spaceStore.editingFlow && (
            <Box h="100%" w="100%" px="50px" py="15px">
              <Box
                position={"absolute"}
                cursor={"pointer"}
                onClick={() => {
                  spaceStore.setEditingFlow(null);
                }}
                top="30px"
                left="20px"
              >
                <FaChevronLeft />
              </Box>
              <Button
                position={"absolute"}
                top="10px"
                right="50px"
                onClick={handleTrigger}
              >
                Trigger
              </Button>
              <Tabs
                h="100%"
                display={"flex"}
                flexDir={"column"}
                minH="0"
                flex={1}
                isLazy
              >
                <TabList>
                  <Tab>Flow Edit</Tab>
                  <Tab>Logs</Tab>
                </TabList>
                <TabPanels flex={1} minH={0}>
                  <TabPanel overflowY={"auto"} h="100%">
                    <FlowForm
                      onSubmit={handleSave}
                      flow={toJS(spaceStore.editingFlow)}
                    />
                  </TabPanel>
                  <TabPanel overflowY={"auto"} h="100%">
                    <FlowExecution flowId={spaceStore.editingFlow.id} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
