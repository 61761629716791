import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import { arrayMove } from "react-sortable-hoc";
import { BLOCKS_MAP, IBlockItem } from "../const";
import { SortableDrag, SortableItem, SortableList } from "../Sortable";

type Props = {
  blocks: IBlockItem[];
  onChange: (blocks: IBlockItem[]) => void;
  handleEditBlock: (index: number) => void;
  editingBlockIndex: number;
  onClose: () => void;
};

export const BlocksEditor = ({
  blocks,
  onChange,
  editingBlockIndex,
  handleEditBlock,
  onClose,
}: Props) => {
  const onSortEnd = ({
    newIndex,
    oldIndex,
  }: {
    newIndex: number;
    oldIndex: number;
  }) => {
    onChange(arrayMove(blocks, oldIndex, newIndex));
  };

  return (
    <Flex
      padding="15px"
      background={"white"}
      border="1px solid"
      color="black"
      borderColor={"gray.700"}
      width="400px"
      flexDir={"column"}
      overflowY={"auto"}
      position={"fixed"}
      left="20px"
      top="20px"
      bottom="20px"
      shadow={"base"}
      borderRadius={"20px"}
      zIndex={2}
    >
      <Box paddingBottom={"15px"} borderBottom="1px solid grey">
        <Text fontSize={"xl"}>Blocks</Text>
      </Box>
      <Box onClick={onClose} position="absolute" top="15px" right="15px">
        <IoClose size="20px" cursor={"pointer"} />
      </Box>
      <Box paddingTop={"15px"}>
        <SortableList
          useDragHandlde
          onSortEnd={onSortEnd}
          sortableHelper={{
            zIndex: 3,
          }}
        >
          {blocks.map((block, index) => (
            <SortableItem index={index} key={block.id}>
              <Flex
                alignItems={"center"}
                borderWidth={1}
                borderColor={
                  editingBlockIndex === index ? "#3498db" : "transparent"
                }
                flexDir={"row"}
              >
                <SortableDrag>
                  <Box marginRight={"10px"}>
                    <IoMenu size={25} cursor={"pointer"} />
                  </Box>
                </SortableDrag>
                <Box
                  onClick={(event) => {
                    event.stopPropagation();
                    handleEditBlock(index);
                  }}
                >
                  {BLOCKS_MAP[block.type].label}
                </Box>
              </Flex>
            </SortableItem>
          ))}
        </SortableList>
      </Box>
    </Flex>
  );
};
