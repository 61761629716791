import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  LightMode,
  Stack,
  useColorModeValue as mode,
  Text,
  Box,
  Heading,
} from "@chakra-ui/react";
import { UnderlineLink } from "component/UnderlineLink";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

export const SignupForm = observer(() => {
  const { formatMessage } = useIntl();
  const { authStore } = useStore();
  const [error, setError] = useState<string | undefined>(undefined);

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async ({ email, password }: any) => {
    setError(undefined);
    try {
      await authStore.register({ email, password });
    } catch (error: any) {
      setError(error?.message);
    }
  };

  return (
    <>
      <Box textAlign="center" mb={{ base: "10", md: "16" }}>
        <Heading as="h1" size="xl" fontWeight="extrabold" letterSpacing="tight">
          <FormattedMessage
            id="signup.title"
            defaultMessage="Sign up for Sunday"
          />
        </Heading>
        <Text mt="3" color={mode("gray.600", "gray.400")} fontWeight="medium">
          <FormattedMessage
            id="signup.have.account"
            defaultMessage="Already have an account?"
          />{" "}
          <Link to="/auth/signin">
            <UnderlineLink>
              <FormattedMessage id="label.signin" defaultMessage="Sign in" />
            </UnderlineLink>
          </Link>
        </Text>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="-px">
          <FormControl id="email-address">
            <FormLabel srOnly>
              <FormattedMessage
                id="label.email.address"
                defaultMessage="Email address"
              />
            </FormLabel>
            <Input
              size="lg"
              type="email"
              autoComplete="email"
              required
              placeholder={formatMessage({ id: "label.email.address" })}
              bg={mode("white", "gray.700")}
              fontSize="md"
              roundedBottom="0"
              {...register("email", {
                required: "This is required",
              })}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel srOnly>
              <FormattedMessage id="label.password" defaultMessage="Password" />
            </FormLabel>
            <Input
              type="password"
              autoComplete="current-password"
              required
              size="lg"
              bg={mode("white", "gray.700")}
              fontSize="md"
              roundedTop="0"
              placeholder={formatMessage({ id: "label.password" })}
              {...register("password", {
                required: "This is required",
              })}
            />
          </FormControl>
        </Stack>
        {error && (
          <Text textAlign="center" mt={5} color="red.600">
            {error}
          </Text>
        )}

        <LightMode>
          <Button
            disabled={isSubmitting}
            size="lg"
            type="submit"
            mt="8"
            w="full"
            colorScheme="blue"
            fontSize="md"
            fontWeight="bold"
          >
            <FormattedMessage id="label.signup" defaultMessage="Sign up" />
          </Button>
        </LightMode>
      </form>
    </>
  );
});
