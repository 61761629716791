import { Box, Flex, useColorModeValue as mode } from "@chakra-ui/react";
import * as React from "react";
import { useStore } from "store/RootStore";
import { observer } from "mobx-react-lite";
import { Redirect, Route, RouteChildrenProps, Switch } from "react-router";
import { Docs } from "screen/Docs";
import { Settings } from "screen/Settings";
import { Spaces } from "screen/Spaces";
import { CreateSpace } from "module/sunday/space";
import { Sidebar } from "./Sidebar/Sidebar";
import { useIsMobile } from "hooks/useDevice";
import { TabBar } from "./TabBar";
import { MobileDrawer } from "./MobileDrawer";
import { Boards } from "screen/Boards";
import { Pages } from "screen/Pages";
import { Builder } from "screen/Builder";
import Header from "./Header";
import { Flows } from "screen/Flows";

export const Team: React.FunctionComponent<RouteChildrenProps> = observer(
  ({ match }) => {
    const { authStore } = useStore();

    const isMobile = useIsMobile();

    const routeElements = (
      <Box
        height="100%"
        overflowY="auto"
        bg={mode("white", "gray.800")}
        flex="1"
      >
        <Switch>
          <Redirect exact from={`${match!.url}`} to={`${match!.url}/spaces`} />
          <Route path={`${match!.path}/docs`} component={Docs} />
          <Route path={`${match!.path}/spaces`} component={Spaces} />
          <Route path={`${match!.path}/boards`} component={Boards} />
          <Route path={`${match!.path}/settings`} component={Settings} />
          <Route path={`${match!.path}/pages`} component={Pages} />
          <Route path={`${match!.path}/workflows`} component={Flows} />
          <Route path={`${match!.path}/builder`} component={Builder} />
        </Switch>
      </Box>
    );

    return (
      <Box height="100vh" overflow="hidden" position="relative">
        {!authStore.isLoggedIn && <Redirect to="/auth" />}
        <Flex height="80px" bg="gray.900" width="100%">
          <Header match={match} />
        </Flex>

        {isMobile ? (
          <Flex overflow="hidden" w="full" h="full" flexDir="column">
            <Box minH={0} flex={1}>
              <MobileDrawer match={match} />
              {routeElements}
            </Box>
            <TabBar match={match} />
          </Flex>
        ) : (
          <Flex h="calc(100% - 80px)" id="app-container">
            {/* <Sidebar match={match} /> */}
            {routeElements}
          </Flex>
        )}
        <CreateSpace />
      </Box>
    );
  }
);
