import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Text,
  Input,
  Select,
  Button,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { ISpace } from "module/sunday/space";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { SpaceService } from "module/sunday/space/SpaceService";
import { FIELD_TYPES } from "field";
import { SpaceCustomTypeFields } from "./SpaceCustomTypeFields";
import { useStore } from "store/RootStore";

interface Props {
  space: ISpace;
}

export const SpaceCustomFields = ({ space }: Props) => {
  const { spaceStore } = useStore();
  const methods = useForm({
    defaultValues: space,
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty },
  } = methods;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "fields", // unique name for your Field Array
    }
  );

  const onSubmit = useCallback(
    (values) => {
      spaceStore.updateSpace({
        id: space.id,
        fields: values.fields,
      });
    },
    [space, spaceStore]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HStack my={2}>
          <Box flex={1}></Box>
          {isDirty && <Button type="submit">保存</Button>}
          <Button
            onClick={() => {
              append({
                key: "new-field",
                label: "新增项",
                type: "text",
              });
            }}
          >
            添加
          </Button>
        </HStack>
        <Accordion defaultIndex={[0]} allowMultiple>
          {fields.map((field, index) => (
            <AccordionItem key={field.id}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {field.label}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <HStack my={2}>
                  <Text width="100px">Key</Text>
                  <Input
                    key={field.id}
                    {...register(`fields.${index}.key`)}
                  ></Input>
                </HStack>
                <HStack my={2}>
                  <Text width="100px">标签</Text>
                  <Input
                    key={field.id}
                    {...register(`fields.${index}.label`)}
                  ></Input>
                </HStack>
                <HStack my={2}>
                  <Text width="100px">类型</Text>
                  <Select key={field.id} {...register(`fields.${index}.type`)}>
                    {FIELD_TYPES.map((fieldType) => (
                      <option value={fieldType.key}>{fieldType.label}</option>
                    ))}
                  </Select>
                </HStack>
                <SpaceCustomTypeFields index={index} control={control} />
                <HStack my={2}>
                  <Box flex={1}></Box>
                  <Button
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    删除
                  </Button>
                </HStack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </form>
    </FormProvider>
  );
};
