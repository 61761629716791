export const JavascriptAction = {
  key: "javascript",
  label: "Javascript",
  fields: [
    {
      key: "code",
      label: "Code",
      type: "javascript",
    },
  ],
};
