export const SMTPAction = {
    key: "smtp",
    label: "SMTP Email Send",
    fields: [
      {
        key: "host",
        label: "SMTP Host",
        type: "input",
      },
      {
        key: "port",
        label: "SMTP Port",
        type: "input",
      },
      {
        key: "secure",
        label: "Use Secure",
        type: "boolean",
      },
      {
        key: "username",
        label: "SMTP Username",
        type:"input"
      },
      {
        key:  "password",
        label: "SMTP Password",
        type: "password"
      },
    ],
};
  