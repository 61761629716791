import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { FlowController } from "../../FlowController/const";
import { FlowField } from "../../FlowField";
import { FlowSelect } from "../../FlowSelect";
import { ACTIONS, ACTION_BY_KEY } from "./actions";
import { ActionType } from "./ActionType";

interface Props {
  fieldIndex: number;
}

export const ActionBox = ({ fieldIndex }: Props) => {
  const intl = useIntl();
  const fieldName = `actions.${fieldIndex}`;
  const { control } = useFormContext();
  const actionType = useWatch({
    control,
    name: `actions.${fieldIndex}.type`,
  });

  const actionParams = useWatch({
    control,
    name: `actions.${fieldIndex}.params`,
  });

  return (
    <Box w="100%">
      {!actionType && (
        <Box my="15px">
          <ActionType fieldName={fieldName} />
        </Box>
      )}
      {!!actionType &&
        (ACTION_BY_KEY[actionType].fields || []).map((field: any) => (
          <FlowField
            key={field.key}
            label={intl.formatMessage({
              id: `flow.action.label.${ACTION_BY_KEY[actionType].key}.${field.key}`,
              defaultMessage: field.label,
            })}
            fieldName={`${fieldName}.params.${field.key}`}
            field={field}
            params={actionParams}
          />
        ))}
    </Box>
  );
};
