import {
  Box,
  Portal,
  Input,
  HStack,
  Avatar,
  Text,
  AvatarProps,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { ICustomField } from "module/sunday/space";
import { FieldModeEnum, ITask, Item } from "const/type";
import { SelectorTrigger } from "./SelectorTrigger";
import { SelectorField } from "./SelectorField";

interface Props {
  field: ICustomField;
  item: Item;
  mode?: FieldModeEnum;
}

export const Selector = observer(({ field, item }: Props) => {
  const { itemStore } = useStore();
  return (
    <SelectorField field={field} value={item.customData?.[field.key]} onChange={(value: any) => {
      itemStore.saveItem({
        id: item.id,
        customData: {
          [field.key]: value,
        },
      });
    }}/>
  );
});
