import * as React from "react";
import {
  Badge,
  Box,
  Flex,
  HStack,
  Img,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { SigninForm } from "./SigninForm";
import { observer } from "mobx-react-lite";
import { Redirect, Route } from "react-router";
import { SignupForm } from "./SignupForm";
import { FormattedMessage } from "react-intl";
import { LanguageSwitcher } from "component/LanguageSwitcher";

export const Auth = observer(() => {
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      overflow="hidden"
      minH="100vh"
      height="100%"
      bg={mode("gray.50", "inherit")}
    >
      <Box position="absolute" top="20px" right="20px">
        <LanguageSwitcher />
      </Box>

      <Box
        display={{ base: "none", lg: "block" }}
        maxH="100vh"
        overflow="hidden"
        flex="1"
        bg="blue.600"
        color="white"
        px="20"
        pt="32"
      >
        <Badge
          bg="blue.700"
          px="4"
          py="1"
          rounded="md"
          letterSpacing="wide"
          color="whiteAlpha.900"
        >
          <FormattedMessage
            id="marketing.badge"
            defaultMessage="The Metaverse is coming"
          />
        </Badge>
        <Text
          mt="6"
          fontWeight="extrabold"
          fontSize={{ base: "2xl", lg: "3xl" }}
          maxW="sm"
          letterSpacing="tight"
          lineHeight="normal"
        >
          <FormattedMessage id="marketing.title" defaultMessage="Meet Sunday" />
        </Text>
        <Text mt="5" maxW="md" fontSize="lg">
          <FormattedMessage
            id="marketing.subtitle"
            defaultMessage="The next generation of online teamwork"
          />
        </Text>
        <HStack
          as="a"
          href="#"
          justify="center"
          display="inline-flex"
          minW="2xs"
          py="3"
          px="2"
          mt="5"
          fontWeight="semibold"
          border="2px solid white"
          rounded="lg"
          transition="all 0.2s"
          _hover={{ bg: "whiteAlpha.200" }}
        >
          <Box>
            <FormattedMessage
              id="marketing.more"
              defaultMessage=" Learn more"
            />
          </Box>
        </HStack>
        <Box mt="10" position="relative">
          <Img
            alt="App screenshot"
            src={`${process.env.PUBLIC_URL}/screenshot.jpg`}
          />
        </Box>
      </Box>

      <Box
        overflowY="auto"
        flex="2"
        py={{ base: "10", md: "16" }}
        px={{ base: "6", md: "10" }}
      >
        <Box maxW="sm" mx="auto">
          <Route path={"/auth/signin"} exact component={SigninForm} />
          <Route path={"/auth/signup"} exact component={SignupForm} />
          <Redirect exact from="/auth" to="/auth/signin" />
        </Box>
      </Box>
    </Flex>
  );
});
