import React from "react";
import { chakra, Box, Flex, Button, HStack } from "@chakra-ui/react";
import { SundayComponentType } from "@zhuxinai/frontend-core";
import { COMMON_DEFINITION } from "./common/definitions";
import { ChakraIcon } from "./icon";
import { IButton } from "./common/type";
import { EMPTY_BUTTON } from "./common/const";
type Props = {
  primaryButton: IButton;
  secondaryButton: IButton;
  title: string;
  subtitle: string;
  content: string;
};

export const SimpleCenter = ({
  title,
  subtitle,
  content,
  primaryButton,
  secondaryButton,
}: Props) => {
  return (
    <Flex px={4} py={32} mx="auto">
      <Box
        mx="auto"
        w={{
          lg: 8 / 12,
          xl: 5 / 12,
        }}
      >
        <chakra.p
          mb={2}
          fontSize="xs"
          fontWeight="semibold"
          letterSpacing="wide"
          color="gray.400"
          textTransform="uppercase"
        >
          {subtitle}
        </chakra.p>
        <chakra.h1
          mb={3}
          fontSize={{
            base: "3xl",
            md: "4xl",
          }}
          fontWeight="bold"
          lineHeight="shorter"
          color="gray.900"
          _dark={{
            color: "white",
          }}
        >
          {title}
        </chakra.h1>
        <chakra.p
          mb={5}
          color="gray.500"
          fontSize={{
            md: "lg",
          }}
        >
          {content}
        </chakra.p>
        <HStack>
          {primaryButton?.text && <Button
            as="a"
            w={{
              base: "full",
              sm: "auto",
            }}
            variant="solid"
            colorScheme="brand"
            size="lg"
            mb={{
              base: 2,
              sm: 0,
            }}
            cursor="pointer"
          >
            {primaryButton?.text}
          </Button>}
          {secondaryButton?.text && <Button
            as="a"
            w={{
              base: "full",
              sm: "auto",
            }}
            mb={{
              base: 2,
              sm: 0,
            }}
            size="lg"
            cursor="pointer"
          >
            {secondaryButton?.text}
          </Button>}
        </HStack>
      </Box>
    </Flex>
  );
};

(SimpleCenter as SundayComponentType).config = {
  type: "chakra_simple_title_with_cta",
  label: "Simple Title With CTA ",
  layoutExtra: {
    minW: 12,
    minH: 5,
    w: 12,
    h: 5,
  },
  schema: {
    type: "object",
    definitions: COMMON_DEFINITION,
    properties: {
      title: {
        type: "string",
        title: "Title",
      },
      subtitle: {
        type: "string",
        title: "Subtitle",
      },
      content: {
        type: "string",
        title: "Content",
      },
      primaryButton: {
        title: "Primary Button",
        $ref: "#/definitions/button",
      },
      secondaryButton: {
        title: "Secondary Button",
        $ref: "#/definitions/button",
      },
    },
  },
  component: SimpleCenter,
  icon: <ChakraIcon />,
};
