import React from "react";
import { withTheme } from "@rjsf/core";
import { Theme as ChakraUITheme } from "@rjsf/chakra-ui";
import validator from "@rjsf/validator-ajv6";
import { BLOCKS_MAP, IBlockItem } from "../const";
// Make modifications to the theme with your own fields and widgets

const Form = withTheme(ChakraUITheme);

type Props = {
  onSave: (values: any) => void;
  block: IBlockItem;
};

export const SingleBlockEditor = ({ onSave, block }: Props) => {
  const schema = BLOCKS_MAP[block.type].schema;
  const uiSchema = BLOCKS_MAP[block.type].uiSchema;
  return (
    <Form
      formData={block.props}
      schema={schema}
      uiSchema={{
        ...uiSchema,
        "ui:submitButtonOptions": {
          norender: true,
        },
      }}
      validator={validator}
      onChange={(values) => {
        onSave(values.formData);
      }}
    />
  );
};
