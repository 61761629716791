import moment from "moment";
export const FILTER_OPERATIONS = [
  { value: "in", label: "is" },
  { value: "isNot", label: "is not" },
  { value: "contains", label: "contains" },
  { value: "notContain", label: "doesn't contain" },
];

export const FILTER_DATE_OPERATIONS = [
  { key: "today", value: "today", label: "Today" },
  { key: "tomorrow", value: "tomorrow", label: "Tomorrow" },
  { key: "yesterday", value: "yesterday", label: "Yesterday" },
  { key: "thisWeek", value: "thisWeek", label: "This Week" },
  { key: "lastWeek", value: "lastWeek", label: "Last Week" },
  { key: "nextWeek", value: "nextWeek", label: "Next Week" },
  { key: "pastDates", value: "pastDates", label: "Past Dates" },
  { key: "futureDates", value: "futureDates", label: "Future Dates" },
  { key: "blank", value: "blank", label: "Blank" },
];

export const CONDITION_CONNECTOR_OPTIONS = [{ value: "and", label: "AND" }];

export const getTimeByDateOption = (date: string) => {
  switch (date) {
    case "today":
    default:
      return { start: moment().startOf("days"), end: moment().endOf("days") };
    case "tomorrow":
      return {
        start: moment().add(1, "days").startOf("days"),
        end: moment().add(1, "days").endOf("days"),
      };
    case "yesterday":
      return {
        start: moment().add(-1, "days").startOf("days"),
        end: moment().add(-1, "days").endOf("days"),
      };
    case "thisWeek":
      return { start: moment().startOf("week"), end: moment().endOf("week") };
    case "lastWeek":
      return {
        start: moment().add(-7, "days").startOf("week"),
        end: moment().add(-7, "days").endOf("week"),
      };
    case "nextWeek":
      return {
        start: moment().add(7, "days").startOf("week"),
        end: moment().add(7, "days").endOf("week"),
      };
    case "pastDates":
      return { start: undefined, end: moment().startOf("days") };
    case "futureDates":
      return { start: moment().startOf("days"), end: undefined };
  }
};
