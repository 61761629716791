import React from "react";
import { FlowControllerProps } from "./const";
import { useController, useFormContext } from "react-hook-form";
import { Box } from "@chakra-ui/react";
// import { JsonEditor as Editor } from "jsoneditor-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import { AutoSizer } from "react-virtualized";

export const Javascript: React.FunctionComponent<FlowControllerProps> = ({
  fieldName,
  field,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
  });

  return (
    <Box h="200px" w="100%">
      {/* <Editor
        value={value || field.defaultValue || {}}
        onChange={(data: any) => {
          onChange(data);
        }}
        mode="code"
      /> */}
      <AutoSizer>
        {({ width, height }) => (
          <AceEditor
            mode="javascript"
            theme="github"
            onChange={(data: any) => {
              onChange(data);
            }}
            width={`${width}px`}
            height={`${height}px`}
            value={value || field.defaultValue || "output = input;"}
            name={fieldName}
            editorProps={{ $blockScrolling: true }}
          />
        )}
      </AutoSizer>
    </Box>
  );
};
