import { Center, Button, Box, HStack } from "@chakra-ui/react";
import { urtcStore } from "module/urtc";
import React, { useMemo } from "react";
import sdk, { Client, User, Stream } from "urtc-sdk";
import { UrtcUser } from "../UrtcUser";
import config from "./config";
import { FormattedMessage } from "react-intl";

function useClient(appId: string, token: string): Client {
  const [client] = React.useState<Client>(() => {
    const client = new Client(appId, token);
    (window as any).c = client;
    return client;
  });
  return client;
}

function useRoomStatus(client: Client): {
  joined: boolean;
  join: Function;
  leave: Function;
} {
  const [joined, setJoined] = React.useState<boolean>(false);
  function join(roomId: string, userId: string) {
    if (joined) return;
    client.joinRoom(
      roomId,
      userId,
      (users: User[], streams: Stream[]) => {
        console.log("demo - join ", users, streams);
        setJoined(true);
      },
      (err: Error) => {
        console.error("demo - join ", err);
      }
    );
    client.enableAudioVolumeIndicator();
  }
  function leave() {
    if (!joined) return;
    client.leaveRoom(
      undefined,
      (): void => {
        setJoined(false);
        console.log("离开房间");
      },
      (err: Error): void => {
        console.error("离开房间 ", err);
      }
    );
  }
  return { joined, join, leave };
}

function useLocalStream(client: Client, joined: boolean): Stream | undefined {
  const [stream, setStream] = React.useState<Stream>();
  React.useEffect(() => {
    if (joined) {
      urtcStore.playEnterSound();
      // 加入房间立即推流
      client.publish(
        { audio: true, video: false, screen: false },
        (err: any) => {
          console.error("demo - publish ", err);
        }
      );
    } else {
      setStream(undefined);
    }
    function handlePublished(stream: Stream): void {
      console.log("stream-published ", stream);
      setStream(stream);
      // client.play(
      //   {
      //     container: stream.sid,
      //     streamId: stream.sid,
      //   },
      //   (err) => {
      //     if (err) {
      //       console.error("play publish stream ", err);
      //     }
      //   }
      // );
    }
    client.on("stream-published", handlePublished);
    return function () {
      client.off("stream-published", handlePublished);
    };
  }, [client, joined]);
  return stream;
}

function useRemoteStreams(client: Client, joined: boolean): Stream[] {
  const [remoteStreams, setRemoteStreams] = React.useState<Stream[]>([]);
  React.useEffect(() => {
    if (!joined) {
      setRemoteStreams([]);
    }
    function handleStreamAdded(stream: Stream): void {
      console.log("stream-added ", stream);
      client.subscribe(stream.sid, (err) =>
        console.error("demo - subscribe ", err)
      );
      // remoteStreams.push(stream);
      setRemoteStreams((preStreams: Stream[]): Stream[] =>
        preStreams.concat(stream)
      );
    }
    function handleSubscribed(stream: Stream): void {
      console.log("stream-subscribed ", stream);
      // client.play(
      //   {
      //     container: stream.sid,
      //     streamId: stream.sid,
      //   },
      //   (err) => {
      //     if (err) {
      //       console.error("play subscribe stream ", err);
      //     }
      //   }
      // );
    }
    function handleStreamRemoved(stream: Stream): void {
      console.log("stream-removed ", stream);
      // client.subscribe(stream.sid, (err) => console.error('demo - subscribe ', err));
      setRemoteStreams((preStreams: Stream[]): Stream[] =>
        preStreams.filter((item) => item.sid !== stream.sid)
      );
    }

    client.on("stream-added", handleStreamAdded);
    client.on("stream-subscribed", handleSubscribed);
    client.on("stream-removed", handleStreamRemoved);

    return function () {
      client.off("stream-subscribed", handleSubscribed);
      client.off("stream-added", handleStreamAdded);
      client.off("stream-removed", handleStreamRemoved);
    };
  }, [client, joined]);
  return remoteStreams;
}

export const UrtcChannel = ({
  roomId,
  userId,
}: {
  roomId: string;
  userId: string;
}) => {
  const token = useMemo(() => {
    return sdk.generateToken(config.AppId, config.AppKey, roomId, userId);
  }, [roomId, userId]);
  const client = useClient(config.AppId, token);
  const { joined, join, leave } = useRoomStatus(client);
  const stream = useLocalStream(client, joined);
  const remoteStreams = useRemoteStreams(client, joined);

  return (
    <Center
      flexDirection="column"
      width="100%"
      padding="20px"
      borderRadius="10px"
      background="rgba(0,0,0,0.4)"
    >
      {joined ? (
        <>
          <HStack
            width="100%"
            overflowY="auto"
            justifyContent="center"
            mb="10px"
          >
            {stream ? <UrtcUser client={client} stream={stream} /> : null}
            {remoteStreams.map((stream) => (
              <UrtcUser client={client} key={stream.sid} stream={stream} />
            ))}
          </HStack>
          <Button onClick={() => leave()}>
            <FormattedMessage
              id="channel.text.exit.space"
              defaultMessage="Exit Space"
            />
          </Button>
        </>
      ) : (
        <Button onClick={() => join(roomId, userId)}>
          <FormattedMessage
            id="channel.text.join.space"
            defaultMessage="Join Space"
          />
        </Button>
      )}
    </Center>
  );
};
