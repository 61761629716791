import { Box } from "@chakra-ui/layout";
import React from "react";

interface Props {}

export const StopPropagation: React.FunctionComponent<Props> = ({
  children,
}) => {
  return <Box onClick={(event) => event.stopPropagation()}>{children}</Box>;
};
