import { Box } from "@chakra-ui/layout";
import { SundayTableOrganized } from "component/SundayTable";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { useStore } from "store/RootStore";
import { ISpaceView } from "module/sunday/space";
import { SundayItemCell } from "component/SundayItemList/SundayItemCell";
import {
  useSpaceCustomFieldsByKey,
  useSpaceView,
} from "module/sunday/space/hooks";
import { useSpaceContext } from "module/sunday/space/context";

interface Props {
  view: ISpaceView;
}

export const TableView = observer(({ view }: Props) => {
  const { itemStore } = useStore();
  const space = useSpaceContext();
  const { fieldsByKey: customFieldsByKey } = useSpaceCustomFieldsByKey();
  const { updateView } = useSpaceView(view);

  const getColumnWidth = useCallback((column) => {
    if (column.key === "subject") {
      return "500px";
    } else {
      return null;
    }
  }, []);

  const renderCell = useCallback(
    ({ key, custom }: { key: string; custom?: boolean }) =>
      (item: any) => {
        const task = itemStore.itemsHash?.[item.row.original?.id];
        if (!task) {
          return null;
        }
        return <SundayItemCell field={customFieldsByKey[key]} item={task} />;
      },
    [customFieldsByKey, itemStore.itemsHash]
  );

  const tableColumns = useMemo(() => {
    return view.columns.map((column: any) => {
      const Header = customFieldsByKey[column.key]?.label;
      return {
        key: column.key,
        Header,
        width: getColumnWidth(column),
        Cell: renderCell(column),
      };
    });
  }, [view, renderCell, customFieldsByKey, getColumnWidth]);

  return (
    <Box w="100%" h="100%" padding={{ base: "0px", md: "20px" }}>
      <SundayTableOrganized
        query={{
          where: {
            "space.key": space.key,
          },
        }}
        sortBy={view.sortBy}
        groupBy={view.groupBy}
        columns={tableColumns}
        update={updateView}
      />
    </Box>
  );
});
