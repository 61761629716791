import React from "react";
import { FlowControllerProps } from "./const";
import { Input as ChakraInput } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";

export const Password: React.FunctionComponent<FlowControllerProps> = ({
  fieldName,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
  });
  return (
    <div>
      <ChakraInput
        type="password"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
    </div>
  );
};
