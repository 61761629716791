import { Button } from "@chakra-ui/button";
import { Box, Flex } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useStore } from "store/RootStore";
import { FileListView } from "../FileListView";
import { FileUploader } from "../FileUploader";

interface Props {}

export const FileChooser = observer((props: Props) => {
  const { fileStore } = useStore();

  const onClose = useCallback(() => {
    fileStore.confirmChooseFile(null);
  }, [fileStore]);

  const onSelect = useCallback(
    (file: any) => {
      fileStore.confirmChooseFile(file);
    },
    [fileStore]
  );

  return (
    <Modal isOpen={fileStore.isChoosingFile} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="90vw" w="90vw" zIndex="10000000">
        <ModalHeader>Choose/Upload A File</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {fileStore.isChoosingFile && (
            <Flex p="20px" w="100%" flexDir="column">
              <FileListView onSelect={onSelect} />
              <Box my="20px" h="1px" bg="white" w="100%"></Box>
              <FileUploader />
            </Flex>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
