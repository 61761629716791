import { Center } from "@chakra-ui/layout";
import { Text, Input } from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ImEmbed } from "react-icons/im";

interface Props {}

export const Embed = (props: Props) => {
  const { control } = useFormContext();

  return (
    <Center py="30px" flexDir="column" w="full" h="full">
      <ImEmbed fontSize="40px" />
      <Text mt="20px">嵌入</Text>
      <Text my="10px" color="gray.200" fontSize="12px">
        嵌入任何内容，更快的浏览和项目有关的信息
      </Text>

      <Controller
        rules={{ required: true }}
        control={control}
        name="data.url"
        render={({ field }) => {
          return <Input {...field} placeholder="嵌入URL" size="sm" />;
        }}
      />
    </Center>
  );
};
