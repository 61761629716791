import React from "react";
import { history } from "util/history";
import { Loading } from "screen/Loading";
import { Dashboard } from "screen/Dashboard";
import { Auth } from "./screen/Auth";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { useStore } from "store/RootStore";
import { Spinner, Center } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { SpaceFieldEditor } from "component/SpaceFieldEditor";
import { ItemModal } from "component/ItemModal";
import { Invite } from "screen/Invite";
import { IntlProvider } from "react-intl";
import { languages } from "i18n";
import { FileChooser } from "module/file/component/FileChooser";
import { Form } from "screen/Form";

interface Props {}

export const Routes = observer((props: Props) => {
  const { authStore, configStore } = useStore();

  if (!authStore.authLoaded) {
    return (
      <Center width="100vw" height="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <IntlProvider
      messages={languages[configStore.language]}
      locale={configStore.language}
      defaultLocale="en-US"
    >
      <Router history={history}>
        <Switch>
          <Route path="/loading" component={Loading} />
          <Route path="/auth" component={Auth} />
          <Route path="/form/:id" component={Form} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/invite" component={Invite} />
          <Redirect from="/" to="/loading" />
        </Switch>
        <ItemModal />
        <SpaceFieldEditor />
        <FileChooser />
      </Router>
    </IntlProvider>
  );
});
