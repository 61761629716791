import { useCallback, useMemo } from "react";
import { useStore } from "store/RootStore";
import { useSpaceContext } from "./context";
import { ISpace } from "./index";
import { ISpaceView } from "./SpaceStore";

export const useCurrentSpaceInfo = () => {
  const { space } = useCurrentSpace();
  return useSpaceInfo(space);
};

export const useSpaceInfo = (space: ISpace | null) => {
  const fieldsByKey = useMemo(() => {
    return (space?.fields || []).reduce((acc: any, item: any) => {
      acc[item.key] = item;
      return acc;
    }, {});
  }, [space]);

  return {
    fieldsByKey,
  };
};

export const useCurrentSpace = () => {
  const { spaceStore } = useStore();
  const space = spaceStore.viewingSpace!;
  const updateSpace = useCallback(
    (payload: Partial<ISpace>) => {
      spaceStore.updateSpace({
        id: space!.id,
        ...payload,
      });
    },
    [spaceStore, space]
  );

  return { space, updateSpace };
};

export const useSpaceView = (view: ISpaceView) => {
  const { spaceStore } = useStore();
  const space = useSpaceContext();

  const updateView = useCallback(
    (payload: Partial<ISpaceView>) => {
      spaceStore.updateSpace({
        id: space!.id,
        views: space!.views.map((_view) => {
          if (view === _view) {
            return {
              ...view,
              ...payload,
            };
          }
          return _view;
        }),
      });
    },
    [view, space, spaceStore]
  );

  const removeView = useCallback(
    (payload: Partial<ISpaceView>) => {
      spaceStore.updateSpace({
        id: space!.id,
        views: space!.views.filter((_view) => _view.key !== view.key),
      });
    },
    [view, space, spaceStore]
  );

  return {
    view,
    space,
    updateView,
    removeView,
  };
};

export const useCurrentSpaceView = () => {
  const { spaceStore } = useStore();
  const view = spaceStore.viewingSpaceView;
  return useSpaceView(view!);
};

export const useMeColumnKey = (space: ISpace) => {
  const columnKey = useMemo(() => {
    return (
      space.fields.find((field) => field.type === "assign")?.key || "assignedTo"
    );
  }, [space]);

  return columnKey;
};


export const useSpaceCustomFieldsByKey = () => {
  const space = useSpaceContext();
  return useSpaceInfo(space);
}