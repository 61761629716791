import { JavascriptAction } from "./javascript";
import { SMTPAction } from "./smtp";
import { SundayAction, SundayQueryAction } from "./sunday";
import { WebhookAction } from "./webhook";
import { WechatWorkAction } from "./wechat-work";

export const ACTIONS = [
  SundayAction,
  SundayQueryAction,
  SMTPAction,
  WebhookAction,
  JavascriptAction,
  WechatWorkAction,
];
export const ACTION_BY_KEY = ACTIONS.reduce((acc: any, item: any) => {
  acc[item.key] = item;
  return acc;
}, {});
