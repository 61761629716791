import { Skeleton } from "@chakra-ui/skeleton";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect } from "react";
import { useStore } from "store/RootStore";
interface Props {
  id: string;
  updatedAt: any;
  placeholder?: any;
}

export const ItemLoader: React.FunctionComponent<Props> = observer(
  ({ id, children, updatedAt, placeholder }) => {
    const { itemStore } = useStore();
    const { itemsHash } = itemStore;
    useEffect(() => {
      if (
        id &&
        (!itemsHash[id] || moment(itemsHash[id].updatedAt).isBefore(updatedAt))
      ) {
        itemStore.addItemToLoader(id);
      }
    }, [itemsHash, itemStore, id, updatedAt]);

    return !itemsHash[id] ? (
      placeholder || <Skeleton width="100%" height="65px" />
    ) : (
      <>{children}</>
    );
  }
);
