import { chakra, Box, Skeleton, Flex, Center } from "@chakra-ui/react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { ItemLoader } from "component/ItemLoader";
import React, { useState } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { useTable, useSortBy, useResizeColumns } from "react-table";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList } from "react-window";

interface Props {
  data: any[];
  columns: any[];
}

export const SundayTable = ({ data, columns }: Props) => {
  const [columnsState, setColumnsState] = useState(columns);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: columnsState, data }, useSortBy, useResizeColumns);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index] as any;
      prepareRow(row);
      console.log(row);
      return (
        <ItemLoader
          key={row.original.id}
          updatedAt={row.original.updatedAt}
          id={row.original.id}
        >
          <Flex
            borderBottom={"1px solid"}
            borderBottomColor="gray.700"
            flexDirection={"row"}
            height="65px"
            {...row.getRowProps({ style })}
          >
            {row.cells.map((cell: any) => (
              <Center
                borderRight={"1px solid"}
                borderRightColor="gray.700"
                height="65px"
                padding="0"
                {...cell.getCellProps()}
                isNumeric={cell.column.isNumeric}
                flex={1}
              >
                {cell.render("Cell")}
              </Center>
            ))}
          </Flex>
        </ItemLoader>
      );
    },
    [prepareRow, rows, columns]
  );

  return (
    <Table
      height={"100%"}
      border={"1px solid"}
      borderColor="gray.700"
      {...getTableProps()}
    >
      <Thead>
        {headerGroups.map((headerGroup: any) => (
          <Flex {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <Center
                h={"65px"}
                flex={1}
                position={"relative"}
                textAlign="center"
                isNumeric={column.isNumeric}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <chakra.span pl="4">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FaSortDown aria-label="sorted descending" />
                    ) : (
                      <FaSortUp aria-label="sorted ascending" />
                    )
                  ) : null}
                </chakra.span>
                <Box
                  display={"inline-block"}
                  as="span"
                  position={"absolute"}
                  right="0"
                  top="0"
                  h="100%"
                  w="1px"
                  backgroundColor={column.isResizing ? "gray.600" : "gray.700"}
                  {...column.getResizerProps()}
                />
              </Center>
            ))}
          </Flex>
        ))}
      </Thead>
      <AutoSizer>
        {({ height, width }) => (
          <Box {...getTableBodyProps()}>
            <FixedSizeList
              overscanCount={10}
              height={height}
              itemCount={rows.length}
              itemSize={65}
              width={width}
            >
              {RenderRow}
            </FixedSizeList>
          </Box>
        )}
      </AutoSizer>
    </Table>
  );
};
