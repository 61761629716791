import { Box, Center, Flex, HStack } from "@chakra-ui/layout";
import React, { useCallback } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
} from "@chakra-ui/react";
import {
  useCurrentSpace,
  useCurrentSpaceView,
} from "module/sunday/space/hooks";
import { FaCheck } from "react-icons/fa";
import { useStore } from "store/RootStore";

interface Props {
  existingFields?: string[];
}

export const SundayItemHeaderAddButton = ({ existingFields = [] }: Props) => {
  const { space, updateView, view } = useCurrentSpaceView();

  const toggleField = useCallback(
    (field) => {
      const currentColumns = view!.columns;
      const newColumns = currentColumns.find(
        (column) => column.key === field.key
      )
        ? currentColumns.filter(
            (column) =>
              column.key !== field.key &&
              space!.fields.find((_field) => column.key === _field.key)
          )
        : [...currentColumns, { key: field.key }];

      updateView({
        columns: newColumns,
      });
    },
    [view, updateView]
  );

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Center
          cursor="pointer"
          _hover={{
            background: "gray.700",
          }}
          w={50}
        >
          +
        </Center>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <Flex flexDir="column" p={1}>
          {space?.fields.map((field) => (
            <HStack
              key={field.key}
              _hover={{
                bg: "gray.800",
              }}
              p={2}
              my={1}
              cursor="pointer"
              justifyContent="space-between"
              onClick={() => toggleField(field)}
            >
              <Box>{field.label}</Box>
              <Box>{existingFields.includes(field.key) && <FaCheck />}</Box>
            </HStack>
          ))}
        </Flex>
      </PopoverContent>
    </Popover>
  );
};
