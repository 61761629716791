import { IBlock } from "@zhuxinai/frontend-core/components/ListView/const";
import axios from "axios";

const gptAxios = axios.create({
  baseURL: `${process.env.REACT_APP_SERVERLESS_AI_ENDPOINT}`,
});

const askGPT = async (message: string) => {
  const { data } = await gptAxios.post(`/api/open-ai?timestamp=${new Date().getTime()}`, {
    messages: [
      {
        role: "user",
        content: `You are a website generator bot for a "IT Consulting" industry company named "Buildmind". ${message}`,
      },
    ],
  });

  // const systemMessage = 'You are a website generator bot for a "IT Consulting" industry company named "Buildmind".';
  // const { data } = await gptAxios.get(`/api/open-ai?prompt=${systemMessage}${message}`);
  return data.choices[0]?.message?.content || "";
};

const BACKUP_PROMPT = `I will provide a json schema, according to the schema generate a json with filled values, try the best to follow the description when generating`;

export const fillTheBlock = async (block: IBlock) => {
  // const { data } = await gptAxios.get("/api/hello");
  try {
    // const schemaForGPT = {
    //   ...block.schema,
    //   properties: Object.keys(block.schema).filter(itemKey => block.schema?.[itemKey])
    // }
    Object.keys(block.schema)
    const content = await askGPT(BACKUP_PROMPT + JSON.stringify(block.schema));
    return JSON.parse(content);
  }
  catch {
    return null;
  }
  
};
