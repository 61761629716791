import { Center, Spinner } from "@chakra-ui/react";
import { API } from "api";
import React from "react";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router-dom";

export const Form: React.FunctionComponent<RouteComponentProps> = ({
  match,
}) => {
  const { id } = match.params as any;
  const {
    data = {},
    isLoading,
    isError,
  } = useQuery(
    ["form", id],
    () => {
      return API.get(`/sunday-spaces/${id}/form`).then(
        (response) => response.data
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  if (!id || isError) {
    return <Center>Form Not Found</Center>;
  }

  if (isLoading) {
    return (
      <Center w="full" h="full">
        <Spinner />
      </Center>
    );
  }

  return (
    <Center w="full" h="full">
      Form
    </Center>
  );
};
