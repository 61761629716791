import React from "react";
import { Box, Center, Flex, Text } from "@chakra-ui/layout";
import { DocDetail } from "./DocDetail";
import { DocList } from "./DocList";
import { Route, RouteChildrenProps } from "react-router";
import { FcFile } from "react-icons/fc";
import { DocumentLoader } from "./DocDetail/DocLoader";

interface Props {}

export const Docs: React.FunctionComponent<RouteChildrenProps> = ({
  match,
}) => {
  return (
    <Flex height="100%" flex="1">
      {/* <Flex
        height="100%"
        display={{ base: "none", lg: "block" }}
        width="96"
        direction="column"
        overflowY="auto"
        borderRightWidth="1px"
        p="6"
      >
        <DocList url={match!.url} />
      </Flex> */}
      <Flex flex="1" p="6">
        <Box rounded="base" h="full" w="full" padding="30px">
          <Route
            path={`${match!.url}/:documentId`}
            component={DocumentLoader}
          ></Route>
          <Route exact path={`${match!.url}`}>
            <Center h="full" w="full" flex={1} flexDir="column">
              <FcFile fontSize="100px" />
              <Text mt="20px">请选择文档查看</Text>
            </Center>
          </Route>
        </Box>
      </Flex>
    </Flex>
  );
};
