import { Box, Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { FlowController } from "../FlowController/const";

interface Props {
  label: string;
  params: any;
  field: {
    key: string;
    type: string;
    showCondition: any;
  };
  fieldName: string;
}

export const FlowField = ({ label, params, field, fieldName }: Props) => {
  const shouldShow = useMemo(() => {
    if (field.showCondition) {
      return field.showCondition(params);
    } else {
      return true;
    }
  }, [field, params]);

  if (!shouldShow) {
    return null;
  }

  return (
    <Flex alignItems={"center"} flexDir={"row"} my="10px">
      <Box w="100px">{label}</Box>
      <Box flex={1}>
        {(FlowController as any)[field.type]
          ? React.createElement((FlowController as any)[field.type], {
              fieldName,
              field,
            })
          : null}
      </Box>
    </Flex>
  );
};
