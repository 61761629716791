import { makeAutoObservable, reaction } from "mobx";
import { SpaceService } from "./SpaceService";
import { toast } from "util/toast";
import { teamStore } from "../team";

export interface ICustomFieldOption {
  key: string;
  label: string;
  color: string;
}

export interface ICustomField {
  key: string;
  label: string;
  type: string;
  subtype?: string;
  options?: Array<ICustomFieldOption>;
  defaultOption?: string;
}

export interface ISpaceView {
  key: string;
  label: string;
  type: string;
  columns: { key: string; custom?: boolean; width?: number }[];
  groupBy?: string;
  sortBy?: {
    key: string;
    direction: string;
  };
  data?: any;
}

export interface ISpaceUI {
  head: string[];
  body: string[];
  create: string[];
}

export interface ISpace {
  fields: ICustomField[];
  views: ISpaceView[];
  id: string;
  name: string;
  key: string;
  ui: ISpaceUI;
  allowExternalForm?: boolean;
}

export class SpaceStore {
  spacesById: {
    [key: string]: ISpace;
  } = {};
  viewingSpaceId: string | undefined;
  viewingSpaceViewIndex: number = 0;
  loaded: boolean = false;
  editingSpace: ISpace | null = null;
  isFlowModalOpen: boolean = false;
  editingFlow: any;
  isCreatingFlow: boolean = false;

  shouldShowCreateSpaceModal: boolean = false;

  customFieldsByKey: {
    [key: string]: ICustomField;
  } = {};

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => teamStore.selectedTeam,
      () => {
        if (teamStore.selectedTeam) {
          this.init();
        }
      }
    );
  }

  init = async () => {
    await this.getSpaces();
  };

  getSpaces = async () => {
    this.loaded = false;
    const data = await SpaceService.get({
      where: {
        teamId: teamStore.selectedTeam?.id,
      },
    });
    this.spacesById = (data || []).reduce((acc: any, item: any) => {
      acc[item.id] = item;
      return acc;
    }, {});
    this.loaded = true;
  };

  viewSpace = (spaceId: string) => {
    if (this.viewingSpaceId !== spaceId) {
      this.viewingSpaceId = spaceId;
      this.initSpaceData();
    }
  };

  initSpaceData = () => {
    if (this.viewingSpace) {
      this.customFieldsByKey = (this.viewingSpace.fields || []).reduce(
        (acc: any, item: any) => {
          acc[item.key] = item;
          return acc;
        },
        {}
      );
      this.viewingSpaceViewIndex = 0;
    }
  };

  viewSpaceView = (viewIndex: number) => {
    this.viewingSpaceViewIndex = viewIndex;
  };

  setEditingSpace = (space: any) => {
    this.editingSpace = space;
  };

  updateSpace = async (space: Partial<ISpace>) => {
    if (this.confirmNeedToUpdate(space)) {
      this.checkAndUpdateSpace(space);
      await SpaceService.update(space.id!, space);
      toast({
        title: "更新成功",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  confirmNeedToUpdate = (space: Partial<ISpace>) => {
    const existingSpace = this.spacesById[space.id!];
    return (
      Object.keys(space).filter((spaceKeyToUpdate) => {
        return (
          (existingSpace as any)[spaceKeyToUpdate] !==
          (space as any)![spaceKeyToUpdate]
        );
      }).length > 0
    );
  };

  setShouldShowCreateSpaceModal = (bool: boolean) => {
    this.shouldShowCreateSpaceModal = bool;
  };

  checkAndUpdateSpace = (space: Partial<ISpace>) => {
    this.spacesById = {
      ...this.spacesById,
      [space.id!]: {
        ...this.spacesById[space.id!],
        ...space,
      },
    };
    if (this.editingSpace && this.editingSpace.id === space.id) {
      this.editingSpace = {
        ...this.editingSpace,
        ...space,
      };
    }
  };

  setIsFlowModalOpen = (bool: boolean) => {
    this.isFlowModalOpen = bool;
  };

  setEditingFlow = (flow: any) => {
    this.editingFlow = flow;
  };

  setIsCreatingFlow = (boolean: boolean) => {
    this.isCreatingFlow = boolean;
  };

  get viewingSpace(): ISpace | null {
    return this.viewingSpaceId ? this.spacesById[this.viewingSpaceId] : null;
  }

  get viewingSpaceView(): ISpaceView | undefined {
    return this.viewingSpace?.views[this.viewingSpaceViewIndex];
  }

  get spaces(): ISpace[] {
    return Object.values(this.spacesById);
  }
}

export const spaceStore = new SpaceStore();
