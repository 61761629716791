import {
  Box,
  Portal,
  Input,
  HStack,
  Avatar,
  Text,
  AvatarProps,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { FieldModeEnum, ITask, Item, IUser } from "const/type";
import { ICustomField } from "module/sunday/space";

interface Props {
  value: string;
  onChange: any;
}

export const AssignField = observer(
  ({ value: assignValue, onChange }: Props) => {
    const initRef = useRef<any>();
    const { teamStore } = useStore();
    const { users, usersById } = teamStore;

    return (
      <Popover isLazy initialFocusRef={initRef}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              {assignValue && usersById[assignValue] ? (
                <Center>
                  <Avatar
                    size="sm"
                    cursor="pointer"
                    name={
                      usersById[assignValue].username ||
                      usersById[assignValue].email
                    }
                  />
                </Center>
              ) : (
                <Center>
                  <Box
                    cursor="pointer"
                    border="1px solid white"
                    borderStyle="dashed"
                    width="40px"
                    height="40px"
                    borderRadius="20px"
                  ></Box>
                </Center>
              )}
            </PopoverTrigger>
            <Portal>
              <PopoverContent width="500px">
                <PopoverArrow />
                <PopoverCloseButton top="10px" />
                <PopoverBody>
                  <Input
                    focusBorderColor="none"
                    border="none"
                    ref={initRef}
                    placeholder="搜索"
                  />

                  {users.map((user) => (
                    <HStack
                      my="10px"
                      p="5px"
                      key={user.id}
                      onClick={() => {
                        onClose();
                        onChange(user.id);
                      }}
                      borderRadius="10px"
                      cursor="pointer"
                      _hover={{
                        backgroundColor: "gray.500",
                      }}
                    >
                      <Avatar name={user.username || user.email || "Unkown"} />
                      <Text>
                        {user.username || user.email}{" "}
                        {user.username && `(${user.email})`}
                      </Text>
                    </HStack>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    );
  }
);
