import { HStack, VStack, Box, Text } from "@chakra-ui/layout";
import { AssignField } from "field/Assign/AssignField";
import { DateField } from "field/Date/DateField";
import { FileField } from "field/File/FileField";
import { SelectorField } from "field/Selector/SelectorField";
import { TextCreate } from "field/Text/TextCreate";
import { TextAreaCreate } from "field/TextArea/TextAreaCreate";
import { ISpace } from "module/sunday/space";
import { useSpaceInfo } from "module/sunday/space/hooks";
import React from "react";
import { useController, useFormContext } from "react-hook-form";

interface Props {
  space: ISpace;
}

const ComponentMap = {
  date: DateField,
  assign: AssignField,
  file: FileField,
  selector: SelectorField
};

export const CreateFormField = ({
  name,
  component,
  field
}: {
  name: string;
  component: any;
  field: any;
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue: "",
  });

  if (component) {
    return React.createElement(component, {
      onChange,
      value,
      field
    });
  } else {
    return null;
  }
};

export const CreateForm = ({ space }: Props) => {
  const { fieldsByKey } = useSpaceInfo(space);

  return (
    <VStack>
      {space.ui?.create.map((createItem) => {
        const field = fieldsByKey[createItem];
        if (!field) {
          return null;
        }

        if (field.type === "text") {
          return (
            <HStack w="100%" key={createItem}>
              <Text whiteSpace="nowrap">{field.label}</Text>
              <TextCreate field={field} />
            </HStack>
          );
        } else if (field.type === "textarea") {
          return (
            <VStack alignItems="flex-start" w="100%" key={createItem}>
              <Text whiteSpace="nowrap">{field.label}</Text>
              <Box w="100%">
                <TextAreaCreate field={field} />
              </Box>
            </VStack>
          );
        } else {
          return (
            <HStack w="100%" key={createItem}>
              <Text whiteSpace="nowrap">{field.label}</Text>
              <Box>
                <CreateFormField
                  field={field}
                  name={field.key}
                  component={(ComponentMap as any)[field.type]}
                />
              </Box>
            </HStack>
          );
        }
      })}
    </VStack>
  );
};
