export const isWechatBrowser = () => {
  if (
    navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1 ||
    typeof (navigator as any).wxuserAgent !== "undefined"
  ) {
    return true;
  } else {
    return false;
  }
};
