import { FieldProps } from "field";
import React, { useState } from "react";
import { useStore } from "store/RootStore";
import { ChecklistField } from "./ChecklistField";
import { FieldModeEnum } from "const/type";
import {
  Text,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Flex,
  Progress,
} from "@chakra-ui/react";
import { useDebounce } from "react-use";

interface Props extends FieldProps {}

export const Checklist = ({ item, field, mode }: Props) => {
  const { itemStore } = useStore();
  const value = item.customData?.[field.key] || [];
  const [checklistValue, setChecklistValue] = useState(value);

  useDebounce(
    () => {
      if (JSON.stringify(checklistValue) !== JSON.stringify(value)) {
        itemStore.saveItem({
          id: item.id,
          customData: {
            [field.key]: checklistValue,
          },
        });
      }
    },
    2000,
    [checklistValue]
  );

  if (mode === FieldModeEnum.TABLE) {
    return (
      <Popover isLazy>
        <PopoverTrigger>
          <Flex cursor="pointer" flexDir="row" alignItems="center">
            <Progress
              mr="10px"
              w="50px"
              value={
                (value.filter((item: any) => item.checked).length /
                  value.length) *
                100
              }
            />
            <Text>
              {value.filter((item: any) => item.checked).length}/{value.length}
            </Text>
          </Flex>
        </PopoverTrigger>
        <PopoverContent p="20px">
          <ChecklistField value={value} onChange={setChecklistValue} />
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Box w="100%">
      <Text>{field.label}</Text>
      <ChecklistField value={value} onChange={setChecklistValue} />
    </Box>
  );
};
